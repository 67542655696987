@charset "UTF-8";
/* CSS Document */

/* VARIABLEN ################################################### */
/* MAIN MENU */
$mm_background: transparent;
$mm_link-color: #ffffff;
$mm_link-hover-color: #a2cc6c;
$mm_link-parent-color: #ccff88;

/* SUB MENU */
$sm_background: #ffffff;
$sm_link-color: #445555;
$sm_link-hover-bg: #fafafa;
$sm_link-hover-color: #445555;
$sm_link-parent-bg: #eeeeee;
$sm_link-parent-hover-bg: #eaeaea;
$sm_link-parent-color: #445555;

/* FOOTER MENU */
$fm_background: transparent;
$fm_link-color: #ffffff;
$fm_link-current-color: #777777;

/* OFF CANVAS MENU */
$ocm-menu-width: 301px;
$ocm-menu-toggle-width: 51px;
$ocm-menu-toggle-height: 50px;

$ocm-toggle_bg: #6ab023;
$ocm_mm_border-right: 1px solid #559911;
$ocm_mm-bg: #d4d6d9;
$ocm_mm-title-bg: darkgreen;
$ocm_mm-title-color: #fff;
$ocm_mm-link-color: #fff;
$ocm_mm_link-with-sub-color:#fff;
$ocm_mm_link-parent-bg: #6ab023;
$ocm_mm_link-parent-hover-bg: #77bb22;
$ocm_mm-link-parent-color: #fff;
$ocm_mm-link-hover-bg: #1f7300;
$ocm_mm-link-hover-color: #fff;

$ocm_mm-border-bottom: 1px solid #559911;

$ocm_sm-background: #1f7300;
$ocm_sm-link-color: #fff;
$ocm_sm_link-hover-bg: #6ab023;
$ocm_sm_link-hover-color: #fff;
$ocm_sm_link-parent-bg: #6ab023;
$ocm_sm_link-parent-color: #fff;
$ocm_sm_link-parent-hover-bg: #76c427;
$ocm_sm_link-parent-hover-color: #fff;

$ocm_sm-ul-border-top: 0px solid green;
$ocm_sm-ul-border-bottom: 0px solid blue;
$ocm_sm-li-border-top: 0px solid green;
$ocm_sm-li-border-bottom: 1px solid #559911;
$ocm_sm-li-border-bottom-last: 2px solid #6ab023;

$ocm-fm-color: #fff;
$ocm-fm-hover-color: #fff;
/* VARIABLEN end ############################################### */


/* MENU ######################################################## */
/* HEADER NAV -------------------------------- */
#menu_container {
    display:inline-block;
    background:$mm_background;
    list-style-type: none;
    line-height: 145%;
    height:32px;
    margin:34px 0 24px 0;

    .nav-footer { display:none; }
}

/*.no_scroll { overflow-y: hidden; }*/

.toggle { display: none; }

.title {
    display: none;
}

.footermenu { display: none; }
.sharebuttons_oc { display:none; }

.sitenav {
    .rex-navi1 {
        list-style: none;
        padding:0 0 0 $sitepadding_left;
        margin: 0;
        text-transform: uppercase;

        li {
            display: inline-block;
            letter-spacing: 0.04em;
            padding:0 43px 0 0;

            @-moz-document url-prefix() {
                letter-spacing: 0.03em;
                padding:0 50px 0 0;
            }

            a { font-weight:bold; }
            @-moz-document url-prefix() { a { font-weight:normal; } }

            &.rex-normal {
                &:last-child {
                    padding: 0;
                }

                a {
                    color: $mm_link-color;
                    outline: none;
                    padding: 0 0 14px 0;
                }

                a:hover {
                    color: $mm_link-hover-color;
                }
            }

            &.rex-active {
                &:last-child {
                    padding: 0;
                }

                a {
                    color: $mm_link-parent-color;
                    outline: none;
                    border-bottom: 0px solid #ffb600;
                    padding: 0 0 14px 0;
                }
            }

            &.rex-current {
                &:last-child {
                    padding: 0;
                }

                a {
                    color: $mm_link-parent-color;
                    outline: none;
                    border-bottom: 0px solid #ffb600;
                    padding: 0 0 14px 0;
                }
            }

            &.rex-article-1 {
                a {
                    display: inline-block;
                    background: transparent url($path-images + 'website/bu_start.png') no-repeat;
                    text-indent: -9999px;
                    min-height:30px;
                    padding: 0 0 0 40px;
                    line-height:2.5em;

                    &:hover,
                    &.rex-current:hover { background: transparent url($path-images + 'website/bu_start_hover.png') no-repeat; }

                    &.rex-current {
                        background: transparent url($path-images + 'website/bu_start_active.png') no-repeat;
                    }
                }
            }

            &.rex-article-2,
            &.rex-article-3,
            &.rex-article-4 {
                a {
                    &:after {
                        display: inline-block;
                        font: normal normal normal 14px/1 FontAwesome;
                        font-size: inherit;
                        text-rendering: auto;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        color: $mm_link-color;
                        transform: translate(0, -1px); /* hier kann der Pfeil vertikal verschoben werden*/
                        padding: 0 0 0 5px;
                        content: "\f078";
                    }
                }

                &:hover {
                    a {
                        &:after {
                            color: $mm_link-parent-color;
                            content: "\f077";
                        }

                        &:link,
                        &:visited,
                        &:active,
                        &:focus,
                        &:hover,
                        &:link:after,
                        &:visited:after,
                        &:active:after,
                        &:focus:after,
                        &:hover:after { color: $mm_link-hover-color; }
                    }

                    ul li a:after { content: ""; }
                }

                &.rex-active {
                    a {
                        &:after {
                            color: $mm_link-parent-color;
                        }

                        &:link:after,
                        &:visited:after,
                        &:active:after,
                        &:focus:after,
                        &:hover:after {
                            color: $mm_link-parent-color;
                        }
                    }
                }
            }

            /* ROLLOVER Submenu */
            &:hover ul.rex-navi2 {
                position: absolute;
                display: block;
                background: $sm_background;
                /*
                margin-top:10px;
                @include rotate(5);
                */

                /*
                SYNTAX
                box-shadow: none|h-shadow v-shadow blur spread color inset|initial|inherit
                */
                box-shadow: 0 7px 6px -7px #373435;
                -webkit-box-shadow: 0 7px 6px -7px #373435;
                -moz-box-shadow: 0 7px 6px -7px #373435;
                z-index: 100;
            }

            .rex-navi2 {
                text-transform: none;
                display: none;
                width: 190px;
                padding: 0;
                margin: 6px 0 0 0; /* Abstand des Sub-Menüs von oben */
                border: 1px solid #cccccc;

                a {
                    font-weight:normal;
                    letter-spacing: normal;
                    text-decoration: none;
                    outline: none;
                }

                li {
                    display: block;
                    text-indent: 1px; /* Hier kann noch der Align des Submenuetextes festgelegt werden. */
                    border-top: 1px dotted #cccccc;
                    padding: 0;

                    &:first-child {
                        border-top: 0px solid #bbdd88;
                    }

                    a {
                        &:after { content: "" }
                        display: block;
                        padding: 7px 0 5px 10px;
                        border-bottom:0;

                        &:hover {
                            &:after { content: "" }
                        }
                    }

                    &.rex-normal {
                        a {
                            margin: 0; /* Abstand nach Submenuefelder - Zusammenhang padding eine Klasse hoeher */
                            /*text-transform: uppercase;*/
                            color: $sm_link-color !important;

                            &:hover {
                                &:after { content: "" }
                                background: $sm_link-hover-bg;
                                color: $sm_link-hover-color;
                            }
                        }
                    }

                    &.rex-current {
                        a {
                            background: $sm_link-parent-bg;
                            color: $sm_link-parent-color !important;

                            &:hover {
                                &:after { content: "" }
                                background: $sm_link-parent-hover-bg;
                            }

                        }
                    }

                    &.rex-active {
                        a {
                            background: $sm_link-parent-bg;
                            color: $sm_link-parent-color;

                            &:hover {
                                &:after { content: "" }
                                background: $sm_link-parent-hover-bg;
                            }

                        }
                    }
                }
            }
        }
    }
}


/* FIXED MENUE BEIM SCROLLEN mit Effekt */
.fix-body { /* this will adjust body top margin to prevent content jumping when nav gets fix  */
    margin-top: 0px;

    @-webkit-keyframes meinFadeIn {
        0%   { opacity: 0; }
        100% { opacity: 1; }
    }
    @-moz-keyframes meinFadeIn {
        0%   { opacity: 0; }
        100% { opacity: 1; }
    }
    @-o-keyframes meinFadeIn {
        0%   { opacity: 0; }
        100% { opacity: 1; }
    }
    @keyframes meinFadeIn {
        0%   { opacity: 0; }
        100% { opacity: 1; }
    }

    .fix-nav { /* this make our menu fixed top */
        position: fixed;
        z-index: 500;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0 20px 0 20px;

        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#004520+0,6ab023+100 */
        background: rgb(0,69,32); /* Old browsers */
        background: -moz-linear-gradient(left,  rgba(0,69,32,1) 0%, rgba(106,176,35,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(0,69,32,1) 0%,rgba(106,176,35,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba(0,69,32,1) 0%,rgba(106,176,35,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#004520', endColorstr='#6ab023',GradientType=1 ); /* IE6-9 */

        /* animation-iteration-count: number|infinite|initial|inherit; */
        -webkit-animation: meinFadeIn 0.5s 1; /* Safari 4+ */
        -moz-animation:    meinFadeIn 0.5s 1; /* Fx 5+ */
        -o-animation:      meinFadeIn 0.5s 1; /* Opera 12+ */
        animation:         meinFadeIn 0.5s 1; /* IE 10+, Fx 29+ */
        /* statt meinFade funktionieren auch: ease-in, bounce */

        .fixed-nav-logo { display:none; }

        nav {
            background:transparent url($path-images + 'website/logo-landflair_klein.png') center right no-repeat;
            max-width:1199px;
            height:60px;
            padding:15px 0 0 0;
            margin: 0 auto;

            .fixed-nav-logo {
                display:block;
                background:transparent;
                float:right;
                width:250px;
                height:50px;
                padding:0;
                margin:-10px 0 0 0;
            }
        }

        &:hover {  }
    }
}
/* FIXED MENUE BEIM SCROLLEN mit Effekt Ende */
/* HEADER NAV end ---------------------------- */


/* FOOTER NAV -------------------------------- */
.nav-footer {
    ul.nav-footer {
        background:$fm_background;
        list-style-type: none;
        padding:0;
        margin:0;

        a {
            color:$fm_link-color;

            &:hover { text-decoration:underline; }

            &.rex-current { color:$fm_link-current-color; }
        }

        li {
            display:inline;
            border-left:1px solid $fm_link-color;
            padding:0 10px 0 13px;

            &:first-child { padding:0 10px 0 0; border-left:0; }

            &:last-child { padding:0 0 0 13px; }
        }
    }
}
/* FOOTER NAV end ---------------------------- */



@media handheld, screen and (max-width: 900px) {

    #menu_container {
        position:absolute;

        .nav-footer { display:block; }
    }

    /* HIER MUSS DIE FIXED NAVI ELIMINIERT WERDEN. Dies geht leider nicht mit einem einfachen display:none; */
    .fix-body {
        .fix-nav {
            background:transparent;
            padding:0;

            -webkit-animation: meinFadeIn 0s 1; /* Safari 4+ */
            -moz-animation:    meinFadeIn 0s 1; /* Fx 5+ */
            -o-animation:      meinFadeIn 0s 1; /* Opera 12+ */
            animation:         meinFadeIn 0s 1; /* IE 10+, Fx 29+ */

            nav {
                background: transparent;
                padding:0;

                .fixed-nav-logo {
                    display:none;
                }
            }
        }
    }

    /* OPEN / CLOSE ------------------------------ */
    .toggle {
        position:relative;
        z-index: 130;

        display: block;

        span {
            position: fixed;
            display: block !important;
            background: $ocm-toggle_bg url($path-images + 'website/togglemenu_icon_white.png') no-repeat 14px center;

            top: 20px;
            left: 0;

            width: $ocm-menu-toggle-width;
            height: $ocm-menu-toggle-height;

            padding:20px 26px 20px 20px;
            margin: 0 auto;

            /* transition: 0.3s;   Die Transitions-Geschwindigkeit muss der von #offcanvas-menu entsprechen. */
            @include transition(all 0.3s ease-in-out);

            z-index: 110;

            &.toggle_leftmargin {
                left:$ocm-menu-width;
            }
        }
    }

    .open > .toggle {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .open > .toggle span { opacity: 1 !important; }

    #offcanvas-menu {
        position: fixed;
        z-index: 130;
        /*background: $ocm_mm-bg;*/

        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#004520+0,6ab023+100 */
        background: rgb(0,69,32); /* Old browsers */
        background: -moz-linear-gradient(left,  rgba(0,69,32,1) 0%, rgba(106,176,35,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left,  rgba(0,69,32,1) 0%,rgba(106,176,35,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right,  rgba(0,69,32,1) 0%,rgba(106,176,35,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#004520', endColorstr='#6ab023',GradientType=1 ); /* IE6-9 */

        top: 0;
        left: -$ocm-menu-width;
        width: $ocm-menu-width;
        height:100%;

        overflow-x: hidden;
        overflow-y: hidden; /* wahlweise: scroll */

        border-right:$ocm_mm_border-right;
        /*transition: 0.3s;*/
        @include transition(all 0.3s ease-in-out);

        /*
        SYNTAX
        box-shadow: none|h-shadow v-shadow blur spread color inset|initial|inherit
        */
        box-shadow: -4px 0 15px 0px #373435;
        -webkit-box-shadow: -4px 0 15px 0px #373435;
        -moz-box-shadow: -4px 0 15px 0px #373435;
    }

    .open > #offcanvas-menu {
        left: 0;
        /* SCROLLBAR Damit kein Scrollbalken beim öffnen des Menüs zu sehen ist. */
        overflow-y: hidden;
    }
    /* OPEN / CLOSE end -------------------------- */


    /* OFFCANVAS-MENU ---------------------------- */
    #offcanvas-menu {
        nav {
            overflow-y: auto;
            height:100%;
            /* SCROLLBAR Damit ein Scrollbar automatisch angezeigt wird, wenn der Sichtbereich zu kurz ist. */

            .title {
                display: block;

                width: 100%;
                height: 70px;

                font-size: inherit;
                font-weight: bold;
                letter-spacing: 0.03em;
                text-align:center;
                text-transform: uppercase;
                text-shadow: 0.5px 0.5px 1px #333;
                color: $ocm_mm-title-color;

                padding: 12px 0 0 0;
                border-bottom: 1px solid rgba(0, 0, 0, 0.045);
                /*background: $ocm_mm-title-bg url('') no-repeat top center;*/

                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#004520+0,6ab023+100 */
                background: rgb(0,69,32); /* Old browsers */
                background: -moz-linear-gradient(left,  rgba(0,69,32,1) 0%, rgba(106,176,35,1) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left,  rgba(0,69,32,1) 0%,rgba(106,176,35,1) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right,  rgba(0,69,32,1) 0%,rgba(106,176,35,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#004520', endColorstr='#6ab023',GradientType=1 ); /* IE6-9 */

                img {
                    display:inline-block;
                    max-width:173px;
                }

                /*
                SYNTAX
                box-shadow: none|h-shadow v-shadow blur spread color inset|initial|inherit

                box-shadow: 0px 0px 8px 0px #969799;
                -webkit-box-shadow: 0px 0px 8px 0px #969799;
                -moz-box-shadow: 0px 0px 8px 0px #969799;
                */
            }

            .footermenu {
                display: block;
                font-size: inherit;
                color: $ocm-fm-color;
                padding: 20px;
                margin: 35px 0 0 0;
            }

            .rex-navi1 {
                padding: 0;

                /* ################ MAINmenue ################ */
                li {
                    float: none;
                    display: inline-block;
                    width:100%;
                    border-top: 0px solid #666666;
                    border-bottom: $ocm_mm-border-bottom;
                    padding: 0px 0px 0px 0px;

                    :last-child {
                        border-top: 0px solid blue;
                        border-bottom: 0px solid red;
                    }

                    a {
                        width:100% !important;
                        color: $ocm_mm-link-color;
                        font-size: inherit;
                        display: inline-block;
                        padding: 20px 20px 20px 20px;

                        &:before,
                        &:after {
                            display: inline-block;
                            font: normal normal normal 14px/1 FontAwesome;
                            font-size: inherit;
                            text-rendering: auto;
                            -webkit-font-smoothing: antialiased;
                            -moz-osx-font-smoothing: grayscale;
                            transform: translate(0, 0);
                            padding:0 10px 0 0;
                        }
                    }

                    &:hover {
                        background-color: $ocm_mm-link-hover-bg;
                        color: $ocm_mm-link-hover-color;

                        .rex-current {
                            color: $ocm_mm_link-with-sub-color;
                            background:$ocm_mm-link-hover-bg;
                        }
                    }

                    .hover {
                        a:after {
                            content: "\f0d7";
                        }

                        .rex-current {
                            color: $ocm_mm_link-with-sub-color;
                            background-color: inherit;  /* inherit */
                        }
                    }

                    &.rex-active {
                        ul {
                            li {
                                a {
                                    padding-left: 40px;

                                    &:hover {
                                        background: $ocm_sm_link-hover-bg;
                                        color: $ocm_sm_link-hover-color;
                                    }

                                    &.rex-active {
                                        background-color: $ocm_mm_link-parent-bg;  /* inherit */

                                        &:hover {
                                            background-color: $ocm_mm_link-parent-hover-bg;  /* inherit */
                                        }
                                    }
                                }

                                &.rex-current {
                                    a {
                                        background: $ocm_sm_link-parent-bg;
                                        color: $ocm_sm_link-parent-color;
                                    }
                                }

                                &.rex-current a:hover {
                                    background: $ocm_sm_link-parent-hover-bg;
                                    color: $ocm_sm_link-parent-hover-color;
                                }
                            }
                        }
                    }

                    &.rex-current {
                        background: $ocm_mm_link-parent-bg;
                        color: $ocm_mm_link-with-sub-color;
                        width:100%;

                        a {
                            border-bottom: 0;
                            margin:0;
                            width:100% !important;

                            &.rex-current {
                                background: $ocm_mm_link-parent-bg;

                                &:hover {
                                    background: $ocm_mm_link-parent-hover-bg;  /* inherit */
                                }
                            }
                        }
                    }

                    &.rex-article-1 {
                        a {
                            background:transparent;
                            text-indent: 0px;
                            width:100%;
                            line-height:1em;

                            &:hover { background: transparent; }

                            &:before { content: "\f015"; padding:0 18px 0 0; }

                            &.rex-current { background: transparent; }
                        }
                    }

                    &.rex-article-2 {
                        a {
                            &:before { content: "\f0d0"; padding:0 18px 0 0; }
                            &:after {
                                float:right;
                                padding:3px 0 0 0;
                                content: "\f0da";
                                color: $ocm_sm_link-parent-color;
                            }
                        }

                        ul {
                            li {
                                a {
                                    &:before,
                                    &:after { display:none; }
                                }
                            }
                        }

                        /* Obere Zeile (HOVER) ausblenden, falls kein HOVER-Effekt gewünscht wird.
                        Dann auch weiter unten noch die ul einblenden.
                        menu-offcanvas.js muss auch angepasst werden. */
                        &:hover,
                        &:focus,
                        &.hover {
                            a {
                                &:after { content: "\f0d7"; }
                            }
                        }
                    }


                    &.rex-article-3 {
                        a {
                            &:before { content: "\f164"; padding:0 19px 0 0; }
                            &:after {
                                float:right;
                                padding:3px 0 0 0;
                                content: "\f0da";
                                color: $ocm_sm_link-parent-color;
                            }
                        }

                        ul {
                            li {
                                a {
                                    &:before,
                                    &:after { display:none; }
                                }
                            }
                        }

                        /* Obere Zeile (HOVER) ausblenden, falls kein HOVER-Effekt gewünscht wird.
                        Dann auch weiter unten noch die ul einblenden.
                        menu-offcanvas.js muss auch angepasst werden. */
                        &:hover,
                        &:focus,
                        &.hover {
                            a {
                                &:after { content: "\f0d7"; }
                            }
                        }
                    }


                    &.rex-article-4 {
                        a {
                            &:before { content: "\f05a"; padding:0 19px 0 0; }
                            &:after {
                                float:right;
                                padding:3px 0 0 0;
                                content: "\f0da";
                                color: $ocm_sm_link-parent-color;
                            }
                        }

                        ul {
                            li {
                                a {
                                    &:before,
                                    &:after { display:none; }
                                }
                            }
                        }

                        /* Obere Zeile (HOVER) ausblenden, falls kein HOVER-Effekt gewünscht wird.
                        Dann auch weiter unten noch die ul einblenden.
                        menu-offcanvas.js muss auch angepasst werden. */
                        &:hover,
                        &:focus,
                        &.hover {
                            a {
                                &:after { content: "\f0d7"; }
                            }
                        }
                    }


                    &.rex-article-5 {
                        a {
                            &:before { content: "\f1d8"; padding:0 16px 0 0; }
                        }
                    }


                    /* ################ SUBmenue ################ */
                    .rex-navi2 {
                        position: relative;
                        width:100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        margin: 0;
                        padding: 0;
                        /*
                        border-top: $ocm_sm-ul-border-top;
                        border-bottom: $ocm_sm-ul-border-bottom;
                        */
                        border:0;
                        background: $ocm_sm-background;

                        -moz-box-shadow:    inset  0  7px 7px -7px rgba(0,0,0,0.0),
                        inset  0 -7px 7px -7px rgba(0,0,0,0.0);
                        -webkit-box-shadow: inset  0  7px 7px -7px rgba(0,0,0,0.0),
                        inset  0 -7px 7px -7px rgba(0,0,0,0.0);
                        box-shadow:         inset  0  7px 7px -7px rgba(0,0,0,0.0),
                        inset  0 -7px 7px -7px rgba(0,0,0,0.0);

                        li {
                            width:100%;
                            border-top: $ocm_sm-li-border-top;
                            border-bottom: $ocm_sm-li-border-bottom;

                            &:first-child { border-top: $ocm_mm-border-bottom; }
                            &:last-child { border-bottom: $ocm_sm-li-border-bottom-last; }

                            a {
                                width:100%;
                                padding-left: 50px;
                                color: $ocm_sm-link-color !important;
                                font-size: inherit;
                            }

                            a:hover {
                                background:$ocm_sm_link-hover-bg;
                                color:$ocm_sm_link-hover-color;
                            }

                            &:first-child a {
                                /*
                                SYNTAX
                                box-shadow: none|h-shadow v-shadow blur spread color inset|initial|inherit
                                */
                                box-shadow:         inset 0 7px 7px -7px rgba(0,0,0,0.8);
                                -webkit-box-shadow: inset 0 7px 7px -7px rgba(0,0,0,0.8);
                                -moz-box-shadow:    inset 0 7px 7px -7px rgba(0,0,0,0.8);
                            }

                            .rex-current,
                            &:hover .rex-current {
                                color: $ocm_sm_link-parent-hover-color;
                                background-color: inherit;
                            }

                            &.hover .rex-current {
                                color: $ocm_sm_link-parent-hover-color;
                                background: rgba(0,0,0,0.1);
                            }
                        }
                    }

                    &.hover ul,
                    li.hover ul,
                    li li.hover ul,
                    li li li.hover ul,
                    li li li li.hover ul,
                    li li li li li.hover ul {
                        position: static !important;
                        display: block !important;
                    }
                }

                /* STYLES (Links/Hover/Active Sub>Sub Menu) -- */
                /* ul einblenden, wenn kein HOVER-Effekt gewünscht ist. menu-offcanvas.js muss auch angepasst werden. */
                /* */
                ul {
                    display: none;
                    left: -9999px;
                }
                /* STYLES (Links/Hover/Active Sub>Sub Menu) end */
            }

            /* ROLLOVER FARBE Hauptmenüpunkte */
            /* > bedeutet "den ersten"
            .rex-navi1 > li:hover > a {
                background-color: $ocm_mm-link-hover-bg;
                color: $ocm_mm-link-hover-color;
            }
            */
        }

        .nav-footer {
            margin: 35px 0 20px 0;
            li {
                a {
                    font-size: inherit;
                    color:$ocm-fm-color;
                    line-height:3em;
                }

                a:hover {
                    color:$ocm-fm-hover-color;
                    text-decoration:underline;
                }
            }
        }
    }



    .sharebuttons_oc {
        margin:0 0 0 40px;
        height:32px;
        display:inline-block;

        ul {
            list-style:none;
            height:32px;
            padding:0;

            &.share-buttons_oc {
                list-style: none;
            }

            &.share-buttons_oc li {
                display: inline;
                background:#fff;
                margin: 0 10px 0 0;

                -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.43);
                -moz-box-shadow:    5px 5px 10px 0px rgba(0, 0, 0, 0.43);
                box-shadow:         5px 5px 10px 0px rgba(0, 0, 0, 0.43);

                &:hover { opacity: 0.8; }
            }

            &.share-buttons_oc img {
                width: 32px;
                padding: 0 0 5px 0;
            }
        }
    }
    /* OFFCANVAS-MENU end ------------------------ */
}

@media handheld, screen and (max-width: 474px) {

    .open { #offcanvas-menu { width: $ocm-menu-width - $ocm-menu-toggle-width; } }
    .toggle span.toggle_leftmargin { left: $ocm-menu-width - $ocm-menu-toggle-width;  }

    /* FOOTER NAV -------------------------------- */
    footer {
        display:none;

        nav {
            .nav-footer {
                li {
                    display:block;
                    width:100%;
                    border:0;
                    margin:0 0 10px 0;
                }
            }
        }
    }
    /* FOOTER NAV end ---------------------------- */
}


@media handheld, screen and (max-width: 375px) {
    /* FOOTER NAV -------------------------------- */
    footer {
        display:none;

        nav {
            .nav-footer {
                li {
                    display:block;
                    width:100%;
                    border:0;
                }
            }
        }
    }
    /* FOOTER NAV end ---------------------------- */
}
/* MENU end #################################################### */