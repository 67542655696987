@charset "UTF-8";
/* CSS Document */


/* VARIABLEN ################################################### */
/* Sind unter scss -> config -> _vars.scss */
/* VARIABLEN end ############################################### */


/* WEB-ICONS ################################################### */
@import '../fonts/font-fontawesome';
@import '../fonts/font-glyphicons';
/* WEB-ICONS end ############################################### */


/* ################### ALLGEMEINE DEFINITIONEN ################### */
/* Reset */
*,
*:after,
*:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    *behavior: url(../boilerplate/boxsizing.htc);	/* */ /* für IE 5.5, 6 und 7*/
}

/* Clearfix */
.clearfix:before,
.clearfix:after {
    content: "";
    display: table;
}

.fancybox-image {
    pointer-events: none;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;

    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.clearfix:after { clear: both; }

html,
body {
    font-family: alegreya_sansregular, Verdana, sans-serif;
    font-size: 18px;	/* 16px (1em) */
    font-weight:normal;

    -webkit-font-kerning: auto;

    /* Smoothing */
    /*
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px;
    */

    text-align:left;
    vertical-align:text-top;
    text-indent: 0px;
    padding:0;
    margin:0;
    color: $font-color-general; /* Schriftfarbe allgemein */

    width: 100%;
    height: 100%;

    &.mainpage { overflow-x:hidden; }
}

img, .img_hundertprozent {
    width:100%;
}

h1, h2, h3, h4, h5, h6 { font-family: amatic_scregular, Verdana, sans-serif; }

h1 {
    width:100%;
    font-size:36px;
    font-weight:bold;
    line-height:1em;
    letter-spacing:0.02em;
    color:$color-general;
    padding:0 0 0 0;
    margin:0 0 27px 0;

    border-width: 27px;
    border-style: solid;
    border-top:0;
    border-left:0;
    border-right:0;
    -moz-border-image: url($path-images + 'website/border.png') 27 repeat;
    -webkit-border-image: url($path-images + 'website/border.png') 27 repeat;
    -o-border-image: url($path-images + 'website/border.png') 27 repeat;
    border-image: url($path-images + 'website/border.png') 27 fill repeat;
}

h2 {
    font-size:36px;
    font-weight:bold;
    letter-spacing:0.02em;
    color:$color-general;
    padding:0;
    margin:0 0 11px 0;
}

h3 {
    &.experteninterview {
        font-size:28px;
        font-weight:bold;
    }
}

p {
    margin:0 0 20px 0;
    /*line-height: 145%;*/
    line-height:1.4;
}

.last {
    margin-bottom:0;
}

a {
    img {border:none; outline:none;}
    &:link,
    &:visited,
    &:active,
    &:focus     {color:$color-general; font-weight:normal; text-decoration:none; outline:none;}
    &:hover     {color:$color-general-hover;}

}

/* highlight-tap entfernen - Touchscreen Graufläche bei Links wird dadurch entfernt */
a { -webkit-tap-highlight-color:transparent;} /* iOS */
a { -webkit-tap-highlight-color:rgba(0,0,0,0);} /* iOS, Android */
a { -webkit-tap-highlight-color:rgba(0,0,0,0);} /* 60% black */

.buz {
    display:inline-block;
    font-size:small;
    margin: 0;
}

sup { top:-0.3em; }

.bild-link {
    margin-bottom:20px;
}

.input_shadow {
    -webkit-box-shadow: 0px 0px 10px -3px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 0px 10px -3px rgba(0,0,0,0.1);
    box-shadow: 0px 0px 10px -3px rgba(0,0,0,0.1);
}

.squarelist {
     margin: 0px 0px 20px -25px;

     li {
         background: url($path-images + 'website/list_square.gif') no-repeat 0px 9px;
         list-style-type: none;
         margin: 0px 0px 5px 0px;
         padding-left: 18px;
     }
 }

.squarelist_colorbg {
    margin: 0px 0px 20px -25px;

    li {
        // background: url($path-images + 'website/list_square.gif') no-repeat 0px 8px;
        list-style-type: square; /* Quadratische Bullet-Points */
        margin: 0px 0px 5px 20px;
        padding-left: 2px;

        &::marker {
            color: #fff; /* Farbe der Bullet-Points */
        }
    }
}


// COOKIE-BANNER
#cookie-popup {
    background-color: rgba(0,0,0,0.8);
    /* background:#000; */
    display:inline-block;

    width: 300px;

    position: fixed;
    z-index: 9999;
    bottom: 70px;
    left: 20px;
    right: 0;

    font-family: "Verdana", Arial, sans-serif;
    font-size: 13px;
    text-align: center;
    color: #fff;
    line-height: 20px;

    padding: 20px;

    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);

    &.hidden {
        display: none;
    }

    a {
        margin-right:20px;
    }

    .hinweis {
        text-align:center;
        margin-bottom:15px;

        a.more {
            padding:0;
            margin:0;
            color: #60b300;

            &:hover { color: #427a00; }
        }
    }

    .cbButton {
        background:#60b300;
        text-align:center;
        text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.75);
        padding: 7px 30px 7px 30px;
        color:#fff;
        border:0;

        &:hover {
            background:#427a00;
        }
    }
}
/* ################# ALLGEMEINE DEFINITIONEN end ################# */


/* ############################# SITE ############################ */

/* LOADER ------------------------------------ */
#loadingDiv {
    background: url($path-images + 'website/loader.gif') 50% 50% no-repeat #fff;
    cursor: wait;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
}

.landflair_background {
    position:absolute;
    z-index:-10;

    background-image:url($path-images + 'website/bg_landflair.jpg');
    background-color:#004521;
    background-position:top center;
    background-size:100%;
    //-webkit-background-size:100%;
    -moz-background-size:100%;
    -o-background-size:100%;
    // background-attachement:fixed;
    background-repeat:no-repeat;

    width:100%;
    height:100%;
}

/* HEADER ------------------------------------ */
header {
    position:relative;
    /*background:$bg_color_header;*/

    width:100%;
    line-height: 0em;
    padding:0;
    margin:0;

    .site_width {
        max-width:$site_width - 30px;
        margin:0 auto;

        .top { height:90px; }
    }

    .logo_container {
        position:relative;
        z-index:90;
        float:right;
        background:$bg_color_logo url($path-images + 'website/logo-landflair.png') 0px 12px no-repeat;
        width: 243px;
        height: 90px;
        padding:0;
        margin:0;
    }

    .slider_container {
        clear:both;
        display:inline-block;
        position:relative;
        z-index:-1;
        background:transparent;
        width:100%;
        padding:0;
        margin:0;
    }

    .orientation_container {
        display:inline-block;
        position:relative;
        z-index:120;

        background:transparent;
        width:100%;
        height:38px;
        border:0;
        line-height: 1.45em;
        padding: 0 $sitepadding_right 0 $sitepadding_left;
        margin:0;

        .row {
            .breadcrumb_eoe {
                /* position:relative;
                z-index:1000;
                Fallback for web browsers that doesn't support RGBa
                background: rgb(255, 255, 255);
                background: rgba(255, 255, 255, 0.9); */
                padding-top: 8px;
                margin:0;

                height:38px;
                color:$font-color-general;
                /*text-shadow: 0px 0px 10px #fff, 0px 0px 7px #fff, 0px 0px 4px #fff, 0px 0px 1px #fff;*/

                &:before {
                    display: inline-block;
                    font: normal normal normal 14px/1 FontAwesome;
                    font-size: inherit;
                    text-rendering: auto;

                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;/**/

                    -ms-transform: translate(0, 0); /* IE 9 */
                    -webkit-transform: translate(0, 0); /* Safari */
                    transform: translate(0, 0); /* hier kann der Pfeil vertikal verschoben werden*/

                    padding: 0 10px 0 0;
                    content: "\f054";
                }

                a {
                    outline:none;
                    text-decoration:underline;

                    &:hover { text-decoration:none; }
                }

                ul, li {
                    display: inline;
                    font-size: 1em;
                    padding: 0;
                    margin: 0; }

                ul.rex-breadcrumb li {
                    margin-left: 0px;
                    padding: 0 10px 0 15px;
                    background: url($path-images + 'website/raquo.gif') no-repeat 0px 8px;
                    list-style: none;

                    &:first-child {
                        background: none;
                        padding: 0 10px 0 0px;
                    }

                    /* Hier werden die Main-Menu Begriffe auf Großbuchstaben gestellt */
                    &.rex-lvl1 { text-transform: uppercase; }

                    &:last-child a {
                        text-decoration:none;
                        outline:none;
                        color:$font-color-general;
                        cursor: default;
                    }
                }
            }
        }
    }

    /* Nur CHROME */
    @media screen and (-webkit-min-device-pixel-ratio:0) {
        .orientation_container {
            font-weight:bold;
            a { font-weight:bold; }
        }
    }

    /* Nur FIREFOX
    @-moz-document url-prefix() { .orientation_container { font-weight:normal; } } */
}
/* HEADER end -------------------------------- */


.rightbuttons {
    position:fixed;
    z-index:130;
    right:0;
    bottom:120px;
    width:32px;
    height:305px;
    margin:0;
    padding:0;

    overflow:hidden;

    // https://github.com/daneden/animate.css
    @extend .animated;
    @extend .fadeIn;
    animation-duration: 2s;
    animation-delay: 1s;

    .mediadaten {
        a {
            display:block;
            background: url($path-images + 'website/bu_mediadaten.png') no-repeat 0px 0px;
            width:32px;
            height:132px;
            margin:0;
            padding:0;
            font-size:0;

            filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=5, OffY=5, Color='#000')";
            filter: url(#drop-shadow);
            -webkit-filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.43));
            filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.43));

            &:hover { background: url($path-images + 'website/bu_mediadaten_hover.png') no-repeat 0px 0px; }
        }
    }

    .sharebuttons {
        margin:15px 0 0 0;

        ul {
            list-style:none;
            width:32px;

            &.share-buttons{
                list-style: none;
                padding: 0;
                width:32px;
            }

            &.share-buttons li {
                display: inline;
                background:#fff;

                -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.43);
                -moz-box-shadow:    5px 5px 10px 0px rgba(0, 0, 0, 0.43);
                box-shadow:         5px 5px 10px 0px rgba(0, 0, 0, 0.43);

                &:hover { opacity: 0.8; }
            }
            /*
            &.share-buttons .sr-only {
                position: absolute;
                clip: rect(1px 1px 1px 1px);
                clip: rect(1px, 1px, 1px, 1px);
                padding: 0;
                border: 0;
                height: 1px;
                width: 1px;
                overflow: hidden;
            }
            */
            &.share-buttons img {
                width: 32px;
                padding: 0 0 5px 0;
            }
        }
    }
}


.site_standard {
    overflow:hidden;
    max-width: $site_width - 30px;
    margin: 0 auto;
    vertical-align:middle;

    text-align:left;

    .included { padding: 20px; }
}


.background_site {
    position:absolute;
    z-index:-1;

    background-image:url($path-images + 'website/bg_lf-content.jpg');
    background-color:#004521;
    background-position:top center;

    -webkit-background-size:100% auto;
    -moz-background-size:100% auto;
    -o-background-size:100% auto;
    background-size:100% auto;

    background-attachement:contain;
    background-repeat:repeat;

    -webkit-box-shadow: 0px -9px 29px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px -9px 29px 0px rgba(0,0,0,0.75);
    box-shadow: 0px -9px 29px 0px rgba(0,0,0,0.75);

    width: 100%;
    display:block;
    margin: -42px 0 0 0;

    text-align:center;

    padding-bottom:100% !important;

    /* Die Risskante oben am Lattenzaun. */
    .kante {
        background: transparent url($path-images + 'website/bg_lf-kante.png') bottom center no-repeat;

        -webkit-background-size:100% auto;
        -moz-background-size:100% auto;
        -o-background-size:100% auto;
        background-size:100% auto;

        background-attachement:contain;

        width: 100%;
        height:20px;

        -moz-transform:translateY(-19px); /* Firefox 3.6 Firefox 4 */
        -webkit-transform:translateY(-19px); /* Safari */
        -o-transform:translateY(-19px); /* Opera */
        -ms-transform:translateY(-19px); /* IE9 */
        transform:translateY(-19px); /* W3C */

        /* Firefox
        @-moz-document url-prefix() { margin: -42px 0 0 0; } */

        &.kante_template_content {
            -moz-transform:translateY(-42px); /* Firefox 3.6 Firefox 4 */
            -webkit-transform:translateY(-42px); /* Safari */
            -o-transform:translateY(-42px); /* Opera */
            -ms-transform:translateY(-42px); /* IE9 */
            transform:translateY(-42px); /* W3C */
        }
    }
}

.site {
    max-width: $site_width;
    padding: 0;
    margin: 0 auto;
}

.site_default {
    padding: 0 35px 0 35px;
}

/* CONTENT ----------------------------------- */
.content_container_fluid {
    display:inline-block;
    width:100%;
    height:100%;
    text-align:center;
    margin: 28px 0 0 0;

    .content_container { padding: 0 ($sitepadding_right + 15px) 0 ($sitepadding_left + 15px); }
}

.content_container {
    position:relative;
    z-index:1;
    display:inline-block;
    background: $bg_color_content;
    width: 100%;
    min-height:400px;
    padding: 0 $sitepadding_right 0 $sitepadding_left;
    margin: 30px 0 0 0;

    text-align:left;

    &.c_standard {
        overflow:hidden;
        max-width: $site_width - 30px;
        padding-top:0px;
        margin: 0 auto;
        /*
        &.inner_content_first { padding: 50px $sitepadding_right 0 $sitepadding_left; }
        &.inner_content { padding: 0 $sitepadding_right 0 $sitepadding_left; }

        &:last-child,
        &.inner_content:last-child {
            margin: 0 auto 150px auto;
        }*/
    }

    p.headline_aktuelles {
        font-size: 56px;
        font-weight:bold;
        letter-spacing:0.02em;
        color: #c6c7c8;
        margin:0 0 15px 0;
    }

    div.row {
        [class^='row'],
        [class*='row'] { margin-bottom:0; }
    }

    .uhinweis,
    .ul_datenschutz {
        margin: 13px 0px 13px -25px;
        li {
            background: url($path-images + 'website/list_square.gif') no-repeat 0px 8px;
            list-style-type: none;
            margin: 0px 0px 5px 0px;
            padding-left: 18px;
        }
    }

    h4.copyrights.Headline {
        font-family: alegreya_sansregular, Verdana, sans-serif;
        font-weight:bold;
        margin-bottom:10px;
    }

    ul.copyrights {
        margin: 0px 0px 17px -25px;

        li {
            background:unset;
            list-style-type:none;
        }
    }


    /* ### VISITENKARTEN ####################################################### */
    .visitenkarte {
        padding: 20px;
        width: 100%;
        height: 235px;
        margin: 0 0 25px 0;
    }

    .vborder { border: 1px solid #eee; }

    .bg_img_00 {
        background:#fff url($path-images + 'visitenkarten/bg_vk_00.jpg');
        background-size:cover;
        min-height:200px;
        padding:20px;
    }

    .bg_img_01 {
        background:#fff url($path-images + 'visitenkarten/bg_vk_01.jpg');
        background-size:cover;
        min-height:200px;
        padding:20px;
    }

    .bg_img_02 {
        background:#fff url($path-images + 'visitenkarten/bg_vk_02.jpg');
        background-size:cover;
        min-height:200px;
        padding:20px;
    }

    .bg_img_03 {
        background:#fff url($path-images + 'visitenkarten/bg_vk_03.jpg');
        background-size:cover;
        min-height:200px;
        padding:20px;
    }
    /* ### VISITENKARTEN end ################################################## */


    /* ### CONTENT-BOX ######################################################## */
    /*
    .box {
        padding:15px;
    }

    .address {
        background:#ffffff;
        border: 1px solid #445555;
        border-radius: 15px;
        padding:15px;
    }
    */

    .content_box {
        background:#fff;
        width:100%;
        background-size:cover;
        border-radius:15px;
        padding:0px;
        margin: 25px 0 15px 0;

        .text { padding:15px; }

        img {
            -webkit-border-top-left-radius: 15px;
            -webkit-border-top-right-radius: 15px;
            -moz-border-radius-topleft: 15px;
            -moz-border-radius-topright: 15px;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
        }
    }
    /* ### CONTENT-BOX end #################################################### */


    /* ### EINTRAG AKTUELLES ################################################### */
    .aktuelles_eintrag {
        border-bottom: 0px solid #9c9ea0;
        text-align:center;
        padding: 0;
        margin: 0 0 10px 0;

        img {
            width:70%;
            margin: 0 0 15px 0;
            /*
            -webkit-box-shadow: 5px 5px 16px 0px rgba(0,0,0,0.35);
            -moz-box-shadow: 5px 5px 16px 0px rgba(0,0,0,0.35);
            box-shadow: 5px 5px 16px 0px rgba(0,0,0,0.35);
            */
            filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=5, OffY=5, Color='#000')";
            filter: url(#drop-shadow);
            -webkit-filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.35));
            filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.35));

            &.aktuelles_noshadow { filter: unset !important; -webkit-filter: unset !important; }
        }

        p {
            padding:0 0 15px 0;
            margin:0;

            a {
                font-family: amatic_scregular, Verdana, sans-serif;
                font-size:32px;
                font-weight:normal;

                &:before { content: "» "; }
            }

            &:first-child { a:before { content: ""; } }
        }
    }
    /* ### EINTRAG AKTUELLES end ############################################### */


    /* ### INFO-KASTEN ######################################################### */
    .table_ik {
        display: table;
        background:#dadadc;
        height:100%;
        margin:5px 0 40px 0;

        .cell_ik {
            vertical-align: top;
            display: table-cell;
            height:100%;

            &:first-child {
                padding:20px 30px 14px 30px;
            }

            &:last-child {
                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fbfbfb+0,e1e1e1+100 */
                background: #fbfbfb; /* Old browsers */
                background: -moz-linear-gradient(left,  #fbfbfb 0%, #e1e1e1 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(left,  #fbfbfb 0%,#e1e1e1 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to right,  #fbfbfb 0%,#e1e1e1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fbfbfb', endColorstr='#e1e1e1',GradientType=1 ); /* IE6-9 */

                width:20%;
            }
        }

        .container_ik {
            height: 100%;
            font-weight:bold;
            text-align:center;
            font-size:120px;
            color:$color-general;
            line-height:1em;
            padding:17% 0px 0px 0px;
        }
    }
    /* ### INFO-KASTEN end ##################################################### */


    /* ### FOTOWETTBEWERB ###################################################### */
    .fotowettbewerb {
        background-color:transparent;
        background-image:url($path-images + '01_start/bg_teaser_fotowettbewerb.png');
        background-repeat:no-repeat;
        background-size:contain;
        width:100%;
        min-height:348px;
        padding:60px 400px 35px 35px;
        margin-top:0;
    }

    @media screen and (min-width: 1021px) and (max-width: 1120px) {
        .fotowettbewerb { padding:50px 350px 35px 35px; min-height:300px; }
    }

    @media screen and (min-width: 851px) and (max-width: 1020px) {
        .fotowettbewerb { padding:50px 275px 35px 35px; min-height:280px; }
    }

    @media screen and (min-width: 767px) and (max-width: 850px) {
        .fotowettbewerb { padding:35px 225px 35px 35px; min-height:200px; }
    }

    @media screen and (min-width: 0px) and (max-width: 766px) {
        .fotowettbewerb {
            position:relative;
            background-color:transparent;
            background-image:url($path-images + 'website/bg_smallteaser_kachel.png');
            background-repeat:repeat-y;
            background-size:100%;
            width:100%;
            min-height:180px;
            padding:35px;
            margin-top:0px;
            margin-bottom:35px;
            display:inline-block;

            &::before {
                content:"";
                background-image:url($path-images + 'website/bg_smallteaser_before.png');
                background-repeat:no-repeat;
                position: absolute;
                left: -10px;
                top: -7px;
                width:100%;
                height:13px;
                display:inline-block;
            }

            &::after {
                content:"";
                background-image:url($path-images + 'website/bg_smallteaser_after.png');
                background-repeat:no-repeat;
                position: absolute;
                left: -10px;
                bottom: -20px;
                width:100%;
                height:25px;
                display:inline-block;
            }
        }
    }
    /* ### FOTOWETTBEWERB end ################################################## */


    /* ### THEMAVORSCHLAGEN und GEWINNERGALERIE ################################ */
    .themavorschlagen,
    .gewinnergalerie {
        position:relative;
        background-color:transparent;
        background-image:url($path-images + 'website/bg_smallteaser_kachel.png');
        background-repeat:repeat-y;
        background-size:100%;
        width:100%;
        min-height:245px;
        padding:35px;
        margin-top:0px;
        margin-bottom:35px;
        display:inline-block;

        &::before {
            content:"";
            background-image:url($path-images + 'website/bg_smallteaser_before.png');
            background-repeat:no-repeat;
            position: absolute;
            left: -10px;
            top: -7px;
            width:100%;
            height:13px;
            display:inline-block;
        }

        &::after {
            content:"";
            background-image:url($path-images + 'website/bg_smallteaser_after.png');
            background-repeat:no-repeat;
            position: absolute;
            left: -10px;
            bottom: -20px;
            width:100%;
            height:25px;
            display:inline-block;
        }
    }


    @media screen and (min-width: 0px) and (max-width: 766px) {
        .themavorschlagen,
        .gewinnergalerie { width:100%; min-height:180px; background-size:100%; }
    }
    /* ### THEMAVORSCHLAGEN und GEWINNERGALERIE end ############################ */


    /* ### SMALLTEASER 1 sp #################################################### */
    .smallteaser {
        position:relative;
        background-color:transparent;
        background-image:url($path-images + 'website/bg_smallteaser_kachel.png');
        background-repeat:repeat-y;
        background-size:100%;
        width:100%;
        padding:15px 35px 10px 35px;
        margin-top:0px;
        margin-bottom:35px;
        display:inline-block;

        &.einsendeschluss {
            font-size: 24px;
            color:$color-general;
            margin:5px 0 25px 0;

            p { line-height: normal !important; }
        }

        &::before {
            content:"";
            background-image:url($path-images + 'website/bg_smallteaser_before.png');
            background-repeat:no-repeat;
            position: absolute;
            left: -10px;
            top: -7px;
            width:100%;
            height:13px;
            display:inline-block;
        }

        &::after {
            content:"";
            background-image:url($path-images + 'website/bg_smallteaser_after.png');
            background-repeat:no-repeat;
            position: absolute;
            left: -10px;
            bottom: -20px;
            width:100%;
            height:25px;
            display:inline-block;
        }
    }


    @media screen and (min-width: 0px) and (max-width: 766px) {
        .smallteaser { width:100%; min-height:unset; background-size:100%; }
    }
    /* ### SMALLTEASER 1 sp end ############################################### */


    /* ### DATENSCHUTERKLÄRUNG ################################################# */
    #datenschutzerklaerung {
        h3, h4 {
            font-family: alegreya_sansregular, Verdana, sans-serif;
        }
    }
    /* ### DATENSCHUTERKLÄRUNG end ############################################# */


    /* ### BUTTON #############################################################
    %button-basics {
        position:relative;
        display: inline-block;
        font-size:1em;
        letter-spacing: 0.05em;
        color: #ffffff;
        text-decoration:none;
        border-radius: 5px;
        padding: 7px 5%;
        margin: 0;
        outline:none;
    }

    button {
        @extend %button-basics;
        @extend %button-grey;
    } */
    /* ### BUTTON end ######################################################### */
}
/* CONTENT end ------------------------------- */

.marginbottom { clear:both; display:inline-block; width:100%; margin-bottom:35px; }


/* FOOTER ------------------------------------ */
footer {
    position:fixed;
    bottom:0px;
    background:$bg_color_footer;
    font-size: 16px;	/* 16px (1em) */
    color: $footer_text_color;
    width:100%;
    margin: 0;
    opacity:0.75;
    z-index:110;

    .footer_width {
        max-width:$site_width - 50px;
        margin: 0 auto;
        padding:0 $sitepadding_right 0 $sitepadding_left;
    }

    .footer_left {
        float:left;
        display:inline-block;
        background:$bg_color_footer_left;
        padding: 13px 0 13px 0;
        margin: 0;

        a { color:#fff; outline:none; &:hover { text-decoration:underline; } }
    }

    .footer_right {
        float:right;
        display:inline-block;
        background:$bg_color_footer_right;
        text-align:right;
        padding: 13px 0 13px 0;
        margin: 0;
    }
}
/* FOOTER end -------------------------------- */
/* ########################### SITE end ########################## */



@media screen and (min-width: 0px) and (max-width: 1220px) {
    header {
        #menu_container { padding: 0 20px 0 20px; }

        .logo_container { margin: 0 20px 0 0; }

        .orientation_container { padding: 0 20px 0 20px; }
    }

    .site_standard {
        overflow:hidden;
        &.inner_content_first { padding: 50px ($sitepadding_right + 20px) 0 ($sitepadding_left + 20px); }
        &.inner_content { padding: 0 ($sitepadding_right + 20px) 0 ($sitepadding_left + 20px); }
    }

    .background_site {
        .site {
            padding:0 5px 0 5px;
        }

        .site_default {
            padding: 0 20px 0 20px;
        }

        .content_container {
            .schnellehilfe {
                margin:0 0 35px 0;
            }
        }
    }

    footer {
        .footer_width { padding: 0 35px 0 35px; }
    }
}


@media screen and (min-width: 0px) and (max-width: 759px) {
    header {
        .breadcrumb {
            width:100%;
        }

        .orientation_container {
            padding:0 ($sitepadding_right - 10) 0 ($sitepadding_left - 10);
        }
    }

    .content_container {
        padding:20px ($sitepadding_right - 10) 20px ($sitepadding_left - 10);

        p.headline_aktuelles { margin-top:0px; }
    }
}


@media screen and (min-width: 396px) and (max-width: 900px) {
    footer {
        display:none;

        width:100%;

        .footer_left {
            float:none;
            width:100%;
            text-align:center;
        }

        .footer_right {
            float:none;
            width:100%;
            text-align:center;
            padding: 13px 0 0 0;
        }
    }
}


@media screen and (min-width: 0px) and (max-width: 630px) {
    header {
        .logo_container {
            background: $bg_color_logo url($path-images + 'website/logo-landflair_klein.png') right 22px no-repeat;
        }

        .slider_container {
            &:after {
                background:none;
            }
        }
    }
}


@media screen and (min-width: 0px) and (max-width: 470px) {
    body {
        p.headline_aktuelles {
            padding-top:40px;
        }

        .background_site {
            .content_container {
                .table_ik {
                    width: 100%;

                    .cell_ik {
                        h2 { font-size:24px; padding-top:10px; }

                        &:first-child {
                            padding: 5px 20px 5px 20px;
                        }

                        &:last-child {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 0px) and (max-width: 475px) {
    body {
        header {
            .orientation_container {
                .row {
                    .breadcrumb_eoe {
                        display:none;
                    }
                }
            }
        }
    }

    .content_container {
        margin:0;
    }

    footer {
        display:none;

        width:100%;

        .footer_left {
            float:none;
            width:100%;
            text-align:center;
        }

        .footer_right {
            float:none;
            width:100%;
            padding:13px 0 0 0;
            text-align:center;
        }
    }
}


@media screen and (min-width: 0px) and (max-width: 895px) {
    .rightbuttons { display:none; }
}



/* HERBST

.labradoodle {
    position:absolute;
    z-index:0;

    background:transparent;
    top:200px;
    right:10px;
    display:inline-block; //display:inline-block;
    width:400px;
    height:auto;

    overflow:hidden; /* WICHTIG, da sonst der Content rechts zu weit hinausragt. */
/*
    img {
        -webkit-filter: drop-shadow(5px 5px 5px #222);
        filter:         drop-shadow(5px 5px 5px #222);
        filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=7, OffY=7, Color='#000')";
        filter: url(#drop-shadow);
        -webkit-filter: drop-shadow(7px 7px 7px rgba(0,0,0,0.5));
        filter: drop-shadow(7px 7px 7px rgba(0,0,0,0.5));
    }
}

@media screen and (min-width: 0px) and (max-width: 940px) {
    .labradoodle { display:none; }
}

@media screen and (min-width: 941px) and (max-width: 1030px) {
    .labradoodle { top:100px; }
}

@media screen and (min-width: 1031px) and (max-width: 1115px) {
    .labradoodle { top:130px; }
}

@media screen and (min-width: 1116px) and (max-width: 1200px) {
    .labradoodle { top:165px; }
}*/

/* WINTER */

.labradoodle {
    position:absolute;
    z-index:0;

    background:transparent;
    top:185px;
    right:0px;
    display:inline-block;
    width:400px;
    height:auto;

    overflow:hidden; /* WICHTIG, da sonst der Content rechts zu weit hinausragt. */

    img {
        -webkit-filter: drop-shadow(5px 5px 5px #222);
        filter:         drop-shadow(5px 5px 5px #222);
        filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=7, OffY=7, Color='#000')";
        filter: url(#drop-shadow);
        -webkit-filter: drop-shadow(7px 7px 7px rgba(0,0,0,0.35));
        filter: drop-shadow(7px 7px 7px rgba(0,0,0,0.35));
    }
}

@media screen and (min-width: 0px) and (max-width: 740px) {
    .labradoodle { display:none; }
}

@media screen and (min-width: 741px) and (max-width: 840px) {
    .labradoodle { display:none; }
}

@media screen and (min-width: 841px) and (max-width: 940px) {
    .labradoodle { top:85px; }
}

@media screen and (min-width: 941px) and (max-width: 1030px) {
    .labradoodle { top:118px; }
}

@media screen and (min-width: 1031px) and (max-width: 1115px) {
    .labradoodle { top:148px; }
}




/* HIDDEN ------------------------------------ */
.hidden {
    display: none;
    width: 100%;
    height: 0px !important;
    font-size: 0px !important;
    color: transparent !important;
    border: 0 !important;

    h1, h2, h3, h4, h5, h6, p, span, a {
        width: 100%;
        font-size: 0px !important;
        color: transparent !important;
        border: 0 !important;
    }
}



.printlogo { display:none; }