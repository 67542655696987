/* ### GLYPHICONS ### */

.bs-glyphicons {
    margin: 20px 0 0 0;

    ul.bs-glyphicons-list {
        clear: both;
        display: inline-block;
        padding: 0;
        margin: 0 0 35px 0;

        li {
            background: #f9f9f9;
            list-style-type: none;
            border: 1px solid #fff;
            float: left;
            width:12.5%;
            font-size: 12px;
            height: 80px;
            line-height: 1.4;
            text-align: center;
            font-size: 12px;
            padding: 10px;
            margin: 0 0 0 0;

            span {
                width: 100%;
                text-align: center;
                display: inline-block;
                float: left;

                &:first-child {
                    font-size: 24px;
                    padding-bottom: 5px;
                }
            }
        }
    }
}

span.glyphicon {
    font-family: 'Glyphicons Halflings';
}

.glycolor li span:first-child { color:darkblue; }


@media screen and (min-width: 0px) and (max-width: 469px) {
    .bs-glyphicons {
        ul.bs-glyphicons-list {
            li {
                width:50%;
            }
        }
    }
}


@media screen and (min-width: 470px) and (max-width: 759px) {
    .bs-glyphicons {
        ul.bs-glyphicons-list {
            li {
                width:25%;
            }
        }
    }
}


@media screen and (min-width: 760px) and (max-width: 1220px) {
    .bs-glyphicons {
        ul.bs-glyphicons-list {
            li {
                width:20%;
            }
        }
    }
}