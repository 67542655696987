@charset "UTF-8";
/* CSS Document */

/* VARIABLEN ################################################### */
@import '../config/vars';
/* VARIABLEN end ############################################### */

#accordion-container {
    clear: both;
    width: 100%;
    font-size: inherit;
    background: transparent;
    border: 0px solid #cccccc;
    padding: 0;
    margin: 0;

    .accordion-section {
        border-top: 1px solid #eeeeee;
        padding: 15px 0 13px 0;

        &:first-child {
            border-top: 0px solid #cccccc;
        }

        h4 {
            font-size:1em;
            font-weight:bold;

            &:hover {
                cursor: pointer;
            }
        }

        h5 {
            font-family: alegreya_sansregular, Verdana, sans-serif;
            font-weight:normal;
            font-size:1em;
            color:#1f7300;
            min-height:18px;
            background-color:yellow;

            &:hover {
                color:#6ab023;
                cursor: pointer;
            }
        }

        h4, h5 {
            &.accordion-header {
                background: transparent;
                border: 0px solid #cccccc;
                cursor: pointer;
                padding: 0;
                margin: 0;

                vertical-align:middle;

                -moz-border-radius: 5px;
                -webkit-border-radius: 5px;
                border-radius: 5px;

                // display: inline-block;
                // width: 100%;

                &.active-header {
                    background: url($path-images + 'website/header-active.gif') transparent;
                    background-repeat: no-repeat;
                    background-position: right 50%;
                }

                &.active-header:hover {
                    background: url($path-images + 'website/header-active_hover.gif') transparent;
                    background-repeat: no-repeat;
                    background-position: right 50%;
                }

                &.inactive-header {
                    background: url($path-images + 'website/header-inactive.gif') transparent;
                    background-repeat: no-repeat;
                    background-position: right 50%;
                }

                &.inactive-header:hover {
                    background: url($path-images + 'website/header-inactive_hover.gif') transparent;
                    background-repeat: no-repeat;
                    background-position: right 50%;
                }
            }
        }

        .accordion-content {
            display: none;
            padding: 0;
            background: transparent;
            border: 0px solid #cccccc;
            border-top: 0;

            p { margin: 15px 0 5px 0; }

            // &.open-content { display: inline !important; }

            ul { margin: 13px 0px 13px -25px; }
        }
    }
}

.acc_tipps_garten,
.acc_tipps_heimtier,
.acc_tipps_reitsport,
.acc_tipps_hausundheimwerker {
    .accordion-section {
        h5 {
            background:transparent !important;
            width:100%;
            height:150px;
            font-size:48px !important;
            font-weight:bold !important;
            color:#fff !important;
            padding:10px 0 0 20px !important;

            -moz-border-radius: 15px !important;
            -webkit-border-radius: 15px !important;
            border-radius: 15px !important;

            text-shadow: 1px 1px 10px rgba(0, 0, 0, 1);

            -webkit-box-shadow: 4px 4px 10px 0px rgba(50, 50, 50, 0.35);
            -moz-box-shadow:    4px 4px 10px 0px rgba(50, 50, 50, 0.35);
            box-shadow:         4px 4px 10px 0px rgba(50, 50, 50, 0.35);
        }

        ul {
            margin: 23px 0 13px -25px !important;

            li {
                list-style-type: none;
                margin: 0px 0px 5px 0px;
                padding-left: 18px;

                &:before,
                &:after {
                    display: inline-block;
                    font: normal normal normal 14px/1 FontAwesome;
                    font-size: inherit;
                    text-rendering: auto;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    transform: translate(0, 0);
                    padding:0 10px 0 0;
                }
            }
        }
    }
}

.acc_tipps_garten {
    .accordion-section {
        h5 {
            background: transparent url($path-images + '03_tipps/gutzuwissen/acc_garten.jpg') !important;
            background-repeat: no-repeat;
            background-position: right 50% !important;
        }

        ul {
            margin: 0 0 18px 0 !important;
            li {
                background: transparent !important;

                &:before {
                    content: "\f18c";
                    margin-left: -35px;
                    padding: 0 18px 0 0;
                    color: #227503;
                }
            }
        }
    }
}

.acc_tipps_heimtier {
    .accordion-section {
        h5 {
            background: transparent url($path-images + '03_tipps/gutzuwissen/acc_heimtier.jpg') !important;
            background-repeat: no-repeat;
            background-position: right 50% !important;
        }

        ul {
            margin: 0 0 18px 0 !important;
            li {
                background:transparent !important;

                &:before { content: "\f1b0"; margin-left:-35px; padding:0 18px 0 0; color:#227503; }
            }
        }
    }
}

.acc_tipps_reitsport {
    .accordion-section {
        h5 {
            background: transparent url($path-images + '03_tipps/gutzuwissen/acc_reitsport.jpg') !important;
            background-repeat: no-repeat;
            background-position: right 50% !important;

            text-shadow: 1px 1px 10px rgba(2, 101, 145, 1);
        }

        ul {
            margin-left:-18px !important;
            li {
                background: url($path-images + 'website/list_horse.png') no-repeat 0px 4px;
                list-style-type: none;
                margin: 0px 0px 5px 0px;
                padding-left: 35px !important;
            }
        }
    }
}

.acc_tipps_hausundheimwerker {
    .accordion-section {
        h5 {
            background: transparent url($path-images + '03_tipps/gutzuwissen/acc_hausundheimwerker.jpg') !important;
            background-repeat: no-repeat;
            background-position: right 50% !important;
        }

        ul {
            margin-left:-18px !important;
            li {
                background: url($path-images + 'website/list_hausundheimwerker.png') no-repeat 0px 4px;
                list-style-type: none;
                margin: 0px 0px 5px 0px;
                padding-left: 35px !important;
            }
        }
        /*
        ul {
            margin-left:0px !important;
            li { &:before { content: "\f0ad"; margin-left:-35px; padding:0 18px 0 0; color:#227503; } }
        }*/
    }
}

.gwz_head {
    font-weight:bold;
    margin: 15px 0 8px 0 !important;
}