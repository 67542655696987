/* body{
	position:relative;

	font-family:Arial, Sans-Serif;
	font-weight: 200;
	-webkit-font-kerning: auto;
	-webkit-font-smoothing: antialiased;
}

SLIDER STYLES */
	
.landing_slider-wrapper {
	background:#fff;
	overflow:hidden;
	width:100%;
	max-height:770px;
	margin:0 auto;
	border:0px solid #000;
	/*box-shadow:0 3px 5px #666;*/

	.slider {
		background:#fff;
		width:100%;
		/*height:300px; when responsive, comment this out */
		top:0;
		margin:0 auto;
		/* ELEMENT STYLES */

		.slide {
			p {
				position:absolute;
				top:-200px;
				color:#fff;
				font-weight:bold;
				line-height:100%;
				white-space:nowrap;
				text-transform:uppercase;
				z-index:100000;

				&.claim {
					font-size:24px;
					padding: 1% 2.5% 1% 2.5%;
				}

				&.teaser {
					font-size:14px;
					padding: 0.8% 3% 0.6% 3%;
				}

				&.small {
					width:250px;
					text-align:center;
					padding-left:0;
					padding-right:0;
				}

				&.light-green	{ background:#95C542; }
				&.green			{ background:#7CB761; }
				&.orange		{ background:#EF7D00; }
				&.turky			{ background:#348E8A; }
				&.white			{ background:#fff; color:#333 }
			}

			/* Nur Firefox */
			@-moz-document url-prefix() {
				p {
					font-weight: normal;

					&.claim {
						padding: 1% 2.5% 0.8% 2.5%;
					}

					&.teaser {
						font-size: 14px;
						padding: 0.6% 3% 0.6% 3%;
					}
				}
			}
		}
	}
	/*
    @media only screen and (min-width: 1000px){
        .slider {
            width:1000px;
        }
    }

    @media only screen and (min-width: 1440px){
        .slider {
            width:1199px;
        }
    }
    */
}