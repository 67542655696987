/* VARIABLEN ################################################### */
/* FORM */
$form-width:100%;
$form-field-border-color: #bbb;
$label-width: 120px;
$input-text-width: 435px;
$input-text-width_s: 93px;
$input-text-width_l: 335px;
$input-text-distance: 3px;
$input-border: 1px;
$input-bottom-distance: 10px;

$input-text-field_s: $input-text-width_s + $input-text-distance + $label-width + $input-border*4;
$input-text-field_l: $input-text-width_l + $input-text-distance + $label-width + $input-border*4;

$input-text-height: 31px;
$input-textarea-height: 150px;
$input-text-padding: 7px 6px 6px 8px;

$input-captcha-width: 183px;
/* VARIABLEN end ############################################### */


/* KONTAKTFORMULAR */
.yform {
    margin: 10px 0 20px 0;

    form {
        padding: 0 0 15px 0;

        .alert {
            list-style-type:none;
            margin: 0 0 25px 0;

            li {
                background: transparent url($path-images + 'website/list_square_red.gif') no-repeat scroll 0 8px;
                color: #cd071e;
            }
        }

        #form_kontakt {
            width:$form-width;
            padding: 0;
            margin: 0;
            background:transparent;

            input { @extend .input_shadow; }

            div {
                &.form-group { margin: 0 0 $input-bottom-distance 0; }
            }

            .street_nr,
            .zip_city {
                margin: 0;

                .form-group { margin: 0 0 ($input-bottom-distance - 2px) 0; }

                /* Nur Firefox */
                @-moz-document url-prefix() { .form-group { margin: 0 0 ($input-bottom-distance - 3px) 0; } }
            }

            em {
                font-weight: bold;
                font-style: normal;
                color: #cc0011;
                margin: 0 0 0 2px;
            }

            /* Für alle id, die mit yform-formular- beginnen (Schreibweise geht auch mit class) */
            [id^='yform-formular-'],
            [id*='yform-formular-'] {
                border-radius: 5px;
            }

            label {
                clear:both;
                float:left;
                background:transparent;
                display: inline-block;
                width: $label-width;
                font-size: 16px;	/* 16px (1em) */
                line-height: 1.8em;
                vertical-align: top;
            }

            #yform-formular-gender {
                label { line-height:2em; }
                select {
                    width:35%;
                    height: $input-text-height;
                    border: 1px solid $form-field-border-color;
                    padding: 2px 0px 2px 8px;
                    font-family: signikalight, Verdana, sans-serif;
                    font-size: 14px;	/* 16px (1em) */
                    font-weight:normal;
                    outline:none;

                    option { outline:none; }
                }
            }

            #yform-formular-consultation {
                margin: 0 0 $input-bottom-distance 0;

                input {
                    float:left;
                    margin-right:5px;
                }

                label {
                    margin-left: $label-width;
                    margin-bottom:10px;
                    width:80%;
                    line-height:1.4em;
                }

                /* Nur Firefox */
                @-moz-document url-prefix() { label { line-height:1.3em; } }

            }

            #yform-formular-company,
            #yform-formular-person,
            #yform-formular-phone,
            #yform-formular-emailaddress {
                input {
                    width: $input-text-width;
                    height: $input-text-height;
                    border: 1px solid $form-field-border-color;
                    padding: $input-text-padding;
                }
            }

            #yform-formular-street,
            #yform-formular-city {
                input {
                    width: $input-text-width_l;
                    height: $input-text-height;
                    padding: $input-text-padding;
                    border: 1px solid $form-field-border-color;
                }
            }

            #yform-formular-nr,
            #yform-formular-zip {
                input {
                    width: $input-text-width_s;
                    height: $input-text-height;
                    padding: $input-text-padding;
                    border: 1px solid $form-field-border-color;
                }
            }

            #yform-formular-message {
                textarea {
                    width: $input-text-width;
                    height: $input-textarea-height;
                    border: 1px solid $form-field-border-color;
                    padding: $input-text-padding;
                    margin: 0 0 $input-bottom-distance 0;

                    @extend .input_shadow;
                }
            }

            #yform-formular-street { float:left; width:$input-text-field_l; }
            #yform-formular-zip { float:left; width:$input-text-field_s; }

            #yform-formular-nr label,
            #yform-formular-city label { display:none; }

            /* ### BUTTON ############################################################ */
            %button-basics {
                position:relative;
                display: inline-block;
                font-size:1em;
                letter-spacing: 0.05em;
                color: #ffffff;
                text-decoration:none;
                border-radius: 5px;
                padding: 7px 5%;
                margin: 10px 0 $input-bottom-distance $label-width !important;
                outline:none;
            }

            button {
                @extend %button-basics;
                @extend %button-landflair;
            }

            :root form.kontakt fieldset li { margin: 0; }
        }

        .small { margin: 0 0 0 $label-width; width:50%}


        .form-captcha {
            span.input-group-addon {
                display:inline-block;
                margin-right: 15px;
                padding:2px;
                background:#888;
                float:left;
                border: none;
                border-radius: 0;
                width: auto;
            }

            // Captcha
            #yform-formular-field-15 {
                width:$input-captcha-width;
                // width: calc(100% - 150px);
                padding: $input-text-padding;
                margin:0;
                display: inline-block;
                border-color: $form-field-border-color;
            }

            .input-group-btn {
                display: inline-block;
                padding-left:15px;
            }

            a.btn {
                background: none;
                border: 0;
                font-size: 0;
                padding: 0;
                margin: 0;
                outline: none;

                &::after {
                    content: ' Neu laden';
                    font-size: 18px;
                    padding-left: 5px;
                    color: #455;
                }

                &::before {
                    padding: 2px;
                    -webkit-font-smoothing: antialiased;
                    font: normal normal normal 14px/1 FontAwesome;
                    content: "\f021";
                    font-size: 18px;
                    display: inline-block;
                    -webkit-transition: all 0.3s 0s ease-in-out;
                    -moz-transition: all 0.3s 0s ease-in-out;
                    -o-transition: all 0.3s 0s ease-in-out;
                    transition: all 0.3s 0s ease-in-out;
                    color: #7d7d7d;
                }

                &:hover {
                    &::before {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}

h3.kf_bestaetigung {
    font-family: alegreya_sansregular, Verdana, sans-serif;
    font-weight:bold;
    color:$color-general;
}


@media screen and (min-width: 0px) and (max-width: 364px) {
    $verkuerzung:170px;
    .yform {
        // background: lightpink;
        form {
            #form_kontakt {
                #yform-formular-gender,
                #yform-formular-group,
                #yform-formular-division {
                    label { margin:6px 0 12px 0; }
                    select {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                div.street_nr, div.zip_city {
                    display: inline-block;
                    width: 100%;
                    height: 68px;
                }

                #yform-formular-company,
                #yform-formular-person,
                #yform-formular-phone,
                #yform-formular-emailaddress {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-street,
                #yform-formular-city {
                    input {
                        width: $input-text-width_l - $verkuerzung;
                    }
                }

                #yform-formular-nr,
                #yform-formular-city {
                    input {
                        position: relative;
                        margin-top: 33px;
                        bottom: 0;
                    }

                    /* Nur Firefox */
                    @-moz-document url-prefix() { input {  margin-top: 34px; } }
                }

                #yform-formular-consultation {
                    label {
                        margin-left: 0;
                    }

                }

                #yform-formular-message {
                    textarea {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-street {
                    float: left;
                    width: $input-text-field_l - $label-width - $input-border*4 - $verkuerzung + 3px;
                }

                #yform-formular-zip {
                    float: left;
                    width: $input-text-field_s - $label-width - $input-border*4 + 3px;
                }

                a.btn {
                    &::after {
                        content: '';
                    }
                }

                .form-captcha label { width:100% }

                #yform-formular-field-15 {
                    width:$input-captcha-width - 90px;
                    float: left;
                }

                %button-basics {
                    margin: 0;
                }

                button#yform-formular-field-16-absenden { margin: 10px 0 10px 0 !important; }
            }

            .small { margin: 10px 0 0 0; width:100%}
        }
    }
}



@media screen and (min-width: 365px) and (max-width: 459px) {
    $verkuerzung:140px;
    .yform {
        // background:lightgreen;
        form {
            #form_kontakt {
                div.street_nr, div.zip_city {
                    display: inline-block;
                    width: 100%;
                    height: 68px;
                }

                #yform-formular-gender,
                #yform-formular-group,
                #yform-formular-division {
                    label { margin:6px 0 12px 0; }
                    select {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-company,
                #yform-formular-person,
                #yform-formular-phone,
                #yform-formular-emailaddress {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-street,
                #yform-formular-city {
                    input {
                        width: $input-text-width_l - $verkuerzung;
                    }
                }

                #yform-formular-nr,
                #yform-formular-city {
                    input {
                        position: relative;
                        margin-top: 33px;
                        bottom: 0;
                    }

                    /* Nur Firefox */
                    @-moz-document url-prefix() { input {  margin-top: 34px; } }
                }

                #yform-formular-consultation {
                    label {
                        margin-left: 0;
                    }

                }

                #yform-formular-message {
                    textarea {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-street {
                    float: left;
                    width: $input-text-field_l - $label-width - $input-border*4 - $verkuerzung + 3px;
                }

                #yform-formular-zip {
                    float: left;
                    width: $input-text-field_s - $label-width - $input-border*4 + 3px;
                }

                a.btn {
                    &::after {
                        content: '';
                    }
                }

                .form-captcha label { width:100% }

                #yform-formular-field-15 {
                    width:$input-captcha-width - 60px;
                    float: left;
                }

                %button-basics {
                    margin: 0;
                }

                button#yform-formular-field-16-absenden { margin: 10px 0 10px 0 !important; }
            }

            .small { margin: 10px 0 0 0; width:100%}
        }
    }
}



@media screen and (min-width: 460px) and (max-width: 519px) {
    $verkuerzung: 150px;
    .yform {
        // background:lightyellow;
        form {
            #form_kontakt {
                #yform-formular-company,
                #yform-formular-person,
                #yform-formular-phone,
                #yform-formular-emailaddress {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-street,
                #yform-formular-city {
                    input {
                        width: $input-text-width_l - $verkuerzung;
                    }
                }

                #yform-formular-message {
                    textarea {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-street {
                    float: left;
                    width: $input-text-field_l - $verkuerzung;
                }

                #yform-formular-field-15 {
                    width:$input-captcha-width - 37px;
                }

                .input-group-btn {
                    padding: 10px 0px 10px 138px;
                }
            }
        }
    }
}



@media screen and (min-width: 520px) and (max-width: 619px) {
    $verkuerzung: 90px;
    .yform {
        // background:salmon;
        form {
            #form_kontakt {
                div.street_nr, div.zip_city {
                    display: inline-block;
                    width: 100%;
                    height: 41px;
                    margin-bottom: $input-bottom-distance - 3px;
                }

                /* Nur Firefox */
                @-moz-document url-prefix() { div.street_nr, div.zip_city { height: 35px; } }

                #yform-formular-company,
                #yform-formular-person,
                #yform-formular-phone,
                #yform-formular-emailaddress {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-street,
                #yform-formular-city {
                    input {
                        width: $input-text-width_l - $verkuerzung;
                    }
                }

                #yform-formular-message {
                    textarea {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-street {
                    float: left;
                    width: $input-text-field_l - $verkuerzung;
                }

                #yform-formular-field-15 {
                    width:$input-captcha-width + 23px;
                }

                .input-group-btn {
                    padding: 10px 0px 10px 138px;
                }
            }
        }
    }
}



@media screen and (min-width: 620px) and (max-width: 767px) {
    $verkuerzung: 0px;
    .yform {
        // background:pink;
        form {
            #form_kontakt {
                div.street_nr, div.zip_city {
                    display: inline-block;
                    width: 100%;
                    height: 41px;
                    margin-bottom: $input-bottom-distance - 3px;
                }

                /* Nur Firefox */
                @-moz-document url-prefix() { div.street_nr, div.zip_city { height: 35px; } }

                #yform-formular-company,
                #yform-formular-person,
                #yform-formular-phone,
                #yform-formular-emailaddress {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-street,
                #yform-formular-city {
                    input {
                        width: $input-text-width_l - $verkuerzung;
                    }
                }

                #yform-formular-message {
                    textarea {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-street {
                    float: left;
                    width: $input-text-field_l - $verkuerzung;
                }
            }
        }
    }
}



@media screen and (min-width: 768px) and (max-width: 839px) {
    $verkuerzung:160px;
    .yform {
        // background:lightblue;
        form {
            #form_kontakt {
                #yform-formular-gender,
                #yform-formular-group,
                #yform-formular-division {
                    select {
                        width:50%;
                    }
                }

                #yform-formular-company,
                #yform-formular-person,
                #yform-formular-phone,
                #yform-formular-emailaddress {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-street,
                #yform-formular-city {
                    input {
                        width: $input-text-width_l - $verkuerzung;
                    }
                }

                #yform-formular-message {
                    textarea {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-street {
                    float: left;
                    width: $input-text-field_l - $verkuerzung;
                }

                #yform-formular-field-15 {
                    width:$input-captcha-width - 47px;
                }

                .input-group-btn {
                    padding: 10px 0px 10px 138px;
                }
            }
        }
    }
}


/**/
@media screen and (min-width: 840px) and (max-width: 994px) {
    $verkuerzung:110px;
    .yform {
        // background:lightgreen;
        form {
            #form_kontakt {
                #yform-formular-gender,
                #yform-formular-group,
                #yform-formular-division {
                    select {
                        width:50%;
                    }
                }

                #yform-formular-company,
                #yform-formular-person,
                #yform-formular-phone,
                #yform-formular-emailaddress {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-street,
                #yform-formular-city {
                    input {
                        width: $input-text-width_l - $verkuerzung;
                    }
                }

                #yform-formular-message {
                    textarea {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-street {
                    float: left;
                    width: $input-text-field_l - $verkuerzung;
                }

                #yform-formular-field-15 {
                    width:$input-captcha-width - $verkuerzung;
                }
            }
        }
    }
}

@media screen and (min-width: 995px) and (max-width: 1019px) {
    $verkuerzung:130px;
    .yform {
        // background:orange;
        form {
            #form_kontakt {
                #yform-formular-gender,
                #yform-formular-group,
                #yform-formular-division {
                    select {
                        width:40%;
                    }
                }

                #yform-formular-company,
                #yform-formular-person,
                #yform-formular-phone,
                #yform-formular-emailaddress {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-street,
                #yform-formular-city {
                    input {
                        width: $input-text-width_l - $verkuerzung;
                    }
                }

                #yform-formular-message {
                    textarea {
                        width: $input-text-width - $verkuerzung;
                    }
                }


                #yform-formular-street {
                    float: left;
                    width: $input-text-field_l - $verkuerzung;
                }

                #yform-formular-field-15 {
                    width:$input-captcha-width - $verkuerzung;
                }
            }
        }
    }
}

@media screen and (min-width: 1020px) and (max-width: 1200px) {
    $verkuerzung: 90px;
    .yform {
        // background:red;
        form {
            #form_kontakt {
                #yform-formular-company,
                #yform-formular-person,
                #yform-formular-phone,
                #yform-formular-emailaddress {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-street,
                #yform-formular-city {
                    input {
                        width: $input-text-width_l - $verkuerzung;
                    }
                }

                #yform-formular-message {
                    textarea {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-street {
                    float: left;
                    width: $input-text-field_l - $verkuerzung;
                }

                #yform-formular-field-15 {
                    width:$input-captcha-width - $verkuerzung;
                }
            }
        }
    }
}
