

$fontPath: unquote('../fonts/Fontawesome/');

@font-face {
	font-family: 'FontAwesome';
	src: url($fontPath + 'fontawesome-webfont.eot');
	src: url($fontPath + 'fontawesome-webfont.eot?#iefix') format('embedded-opentype'),
	url($fontPath + 'fontawesome-webfont.woff2') format('woff2'),
	url($fontPath + 'fontawesome-webfont.woff') format('woff'),
	url($fontPath + 'fontawesome-webfont.ttf') format('truetype'),
	url($fontPath + 'fontawesome-webfont.svg#fontawesome-webfont') format('svg');
	font-weight: normal;
	font-style: normal;
}

