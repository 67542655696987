@mixin breakpoint($point,$min:false) {
	@if $point == x-large {
		@media (max-width: 1280px) { @content; }
	}
	@else if $point == large {
		@media (max-width: 1200px) { @content; }
	}
	@else if $point == medium {
		@media (max-width: 1024px) { @content; }
	}
	@else if $point == normal {
		@media (max-width: 960px) { @content; }
	}
	@else if $point == small {
		@media (max-width: 768px)  { @content; }
	}
	@else if $point == x-small {
		@media (max-width: 640px)  { @content; }
	}
	@else if $point == iPad {
		@media only screen and (device-width: 768px) { @content; }
	}
	@else if $point == retina {
		@media (-webkit-min-device-pixel-ratio: 2), (min-device-pixel-ratio: 2) { @content; }
	}
	@else if $min == true {
		@media (min-width: $point) { @content; }
	}
	@else {
		@media (max-width: $point)  { @content; }
	}
}