.gewinner-galerie {
    clear:both;
    display:inline-block;
    width:100%;

    .gewinner-galerie-row {
        clear:both;

        .gewinner-galerie-col {
            position:relative;
            display:inline-block;
            float:left;

            background-image:url($path-images + '02_machmit/gwg_bg_kachel.jpg');
            background-color:#445555;
            background-position:top center;
            background-size:cover;
            -webkit-background-size:cover;
            -moz-background-size:cover;
            -o-background-size:cover;
            background-attachement:fixed;
            background-repeat:no-repeat;

            width:24%;
            height:380px;
            margin-right:1.3333333%;
            margin-bottom:35px;

            a {
                img {border:none; outline:none;}
                &:link,
                &:visited,
                &:active,
                &:focus     {color:#fff; font-weight:normal; text-decoration:none; outline:none;}
                &:hover     {color:#aaa; text-decoration:underline;}

            }

            &:nth-child(4n+0) { margin-right:0;}

            .dummy {
                padding-top: 100%; /* forces 1:1 aspect ratio */
            }

            .gewinner-galerie-img-cell {
                position: absolute;
                display:inline-block;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;

                text-align:center;
                vertical-align:middle;

                padding:0px 20px 50px 20px !important;
                margin-bottom:10px;

                .centerer {
                    display: inline-block;
                    vertical-align: middle;
                    height: 100%;
                }

                /* IMAGE ZOOM EFFECT */
                /* Hierfür habe ich einen img-wrapper DIV in das Modul geschrieben.
                Den margin-bottom:10px; in der .fancybox img.content unter _fancybox.scss
                habe ich dafür dort auf 0 eingestellt und hier in .img-wrapper ergänzt. */
                .gewinner-galerie-img-wrapper {
                    display: inline-block;
                    vertical-align: middle;
                    overflow: hidden;

                    a { overflow:hidden; }

                    img {
                        max-height:287px;
                        object-fit: contain;
                        -webkit-transition: all .4s ease;
                        -moz-transition: all .4s ease;
                        -ms-transition: all .4s ease;
                        -o-transition: all .4s ease;
                        transition: all .4s ease;

                        vertical-align: middle;

                        &:hover {
                            -webkit-transform:scale(1.5); /* Safari and Chrome */
                            -moz-transform:scale(1.5); /* Firefox */
                            -ms-transform:scale(1.5); /* IE 9 */
                            -o-transform:scale(1.5); /* Opera */
                            transform:scale(1.5);
                        }
                    }
                }
            }


            .gewinner-galerie-text {
                position:absolute;
                display:inline-block;
                background:transparent;
                color:#fff;
                bottom:-25px;
                width:100%;
                text-align:center;
                padding:20px 20px 30px 20px !important;

                &:link,
                &:visited,
                &:active,
                &:focus     {color:#fff; font-weight:normal; text-decoration:none; outline:none;}
                &:hover     {color:#ddd;}
            }
        }
    }
}

@media screen and (min-width: 0) and (max-width: 969px) {
    .gewinner-galerie {
        .gewinner-galerie-row {
            .gewinner-galerie-col{
                width:49%;
                margin-right:2%;

                &:nth-child(2n) { margin-right:0; }
                &:nth-child(2n+1) { clear:both; }
            }
        }
    }
}

@media screen and (min-width: 0) and (max-width: 480px) {
    .gewinner-galerie {
        .gewinner-galerie-row {
            .gewinner-galerie-col {
                width:100%;
                margin-right:0%;
            }
        }
    }
}