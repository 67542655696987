/* SCROLL UP --------------------------------- */
/* Symbol "Pfeil nach oben", unten rechts, als Scroll-Alternative. */
.scrollup {
    position:fixed;
    display:none;
    background-color: #ffffff;
    right:40px;
    bottom:60px;
    width:44px;
    height:44px;

    border-radius:25px;
    border: 3px solid #bbbbbb;

    opacity: 0.5;
    text-align:center;
    vertical-align: middle;
    z-index: 999;

    i {
        opacity: 0.3;

        &:before {
            display: inline-block;
            font: normal normal normal 24px/1 FontAwesome;
            color:#000000;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            transform: translate(0, 0);
            /*content: "\f01b";*/
            content: "\f062";
            padding:6px;
            margin:0;
        }
    }

    &:hover {
        opacity: 0.5;
        border-color: #898989;

        i { opacity: 0.5; }
    }
}

@media screen and (min-width: 0px) and (max-width: 900px) {
    .scrollup { bottom:25px; }
}
/*
@media handheld, screen and (max-width: 474px) {
    .scrollup { bottom:115px; }
}

@media screen and (min-width: 0px) and (max-width: 430px) {
    .scrollup { bottom:145px; }
}*/
/* SCROLL UP end ----------------------------- */