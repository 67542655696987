@media print {
    .landflair_background,
    header,
    .mediadaten,
    .labradoodle,
    footer {
        display:none !important;
    }

    .printlogo {
        display:inline-block  !important;
        position:absolute;
        top:50px;
        right:0;
    }

    .content_container { margin-top:100px; }

    h1 {
        margin: 35px 0 20px 0;
        color:#000;
        font-family: Arial, "Helvetica Neue", Helvetica, Tahoma, sans-serif;
    }

    /* WICHTIG: Damit die image URL beim Ausdruck nicht mit angezeigt wird. */
    .img-wrapper a[href]:after {
        content: " ";
    }
}