/* LANDINGPAGE ------------------------------- */
.clear {
    display: none;
    clear: both;
}

#landingpage {
    position: absolute;

    background-image:url($path-images + 'landingpage/bg_landflair_landingpage.jpg');
    background-color:#004521;
    background-position:top center;
    background-size:cover;
    -webkit-background-size:cover;
    -moz-background-size:cover;
    -o-background-size:cover;
    background-attachement:fixed;
    background-repeat:no-repeat;

    /* @include fadeIn(3s); */
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;

    .container {
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;

        a {
            img {
                border: none;
                outline: none;
            }
            &:link,
            &:visited,
            &:active,
            &:focus {
                color: #fff;
                font-weight: normal;
                text-decoration: none;
                outline: none;
            }
            &:hover {
                color: #ccff88;
            }
        }

        p {
            margin: 0 0 5px 0;
        }

        /* highlight-tap entfernen - Touchscreen Graufläche bei Links wird dadurch entfernt */
        a {
            -webkit-tap-highlight-color: transparent;
        }
        /* iOS */
        a {
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
        /* iOS, Android */
        a {
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
        /* 60% black */

        header {
            position: relative;
            height: 90px;
            line-height: 0em;
            padding: 0;
            margin: 0;

            .site_width {
                max-width: 1199px;
                margin: 0 auto;

                .menulink {
                    position:absolute;
                    z-index:1;
                    font-size:28px;
                    top:31px;
                    left:35px;
                    color:#fff;

                    &:hover { color: $color-general-hover; }
                }

                a {
                    .logo_container {
                        clear: both;
                        background: $bg_color_logo url($path-images + 'website/logo-landflair.png') right 12px no-repeat;
                        width: 250px;
                        height: 90px;
                        padding: 0;
                        margin: 0;
                    }
                }
            }
        }

        .slideshow {
            position: relative;
            width: 100%;
            max-height:770px;

            .ueberbau {
                position: absolute;
                z-index: 100;

                width: 100%;
                height: 100%;
                padding: 0;

                .lf {
                    float: left;
                    width: 25%;
                    height:100%;
                    text-align: center;
                    padding: 0;
                    margin: 0;
                    overflow: hidden;

                    &.lf_content {
                        padding:2%;

                        .lf_image {
                            width:100%;
                            margin: 0 0 10% 0;

                            img {
                                width:100%;
                                padding:0;
                                margin:0;
                                max-width:360px;

                                -webkit-box-shadow: 10px 10px 15px -10px rgba(0,0,0,0.75);
                                -moz-box-shadow: 10px 10px 15px -10px rgba(0,0,0,0.75);
                                box-shadow: 10px 10px 15px -10px rgba(0,0,0,0.75);
                            }
                        }

                        .lf_text {
                            width:100%;

                            em,
                            a em {
                                font-family: amatic_scregular, Verdana, sans-serif;
                                font-size: 30px;
                                font-weight: bold;
                                font-style:normal;
                                line-height:1em;
                                letter-spacing: 0.05em;
                                color: #1f7300;
                                border-bottom: 0;
                                padding: 0;
                                margin-top:0;
                                margin-bottom:0;


                                &:hover { color:#6ab023; }
                            }

                            p {
                                color: #000;
                                margin: 0 0 5px 0;
                            }
                        }
                    }

                    &:nth-child(1) { background-color: rgba(255, 255, 255, 0); }
                    &:nth-child(2) { background-color: rgba(255, 255, 255, 0); }
                    &:nth-child(3) {
                        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+85&0+0,1+85 */
                        background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 85%); /* FF3.6-15 */
                        background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 85%); /* Chrome10-25,Safari5.1-6 */
                        background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 85%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
                    }
                    &:nth-child(4) { background-color: rgba(255, 255, 255, 0); }
                }
            }
        }

        .lf_small { display:none; }

        nav {
            width: 100%;
            height: 100%;

            background-image: url($path-images + 'landingpage/bg_nav.jpg');
            background-size: cover;
            background-repeat: no-repeat;
            background-color:#004521;

            padding:0;
            margin:0;

            clear:both;

            div.bg_nav {
                float: left;
                width: 25%;
                height: 100%;
                text-align: center;
                padding: 0;
                margin: 0;

                &:nth-child(1) {
                    background-color: rgba(0, 38, 18, 0.6);
                    /* @extend .animated; @extend .fadeIn; @extend .infinite; */
                    &:hover { background-color: rgba(122, 204, 41, 0.6); }
                }
                &:nth-child(2) {
                    background-color: rgba(0, 38, 18, 0.4);
                    &:hover { background-color: rgba(122, 204, 41, 0.6); }
                }
                &:nth-child(3) {
                    background-color: rgba(0, 38, 18, 0.2);
                    &:hover { background-color: rgba(122, 204, 41, 0.6); }
                }
                &:nth-child(4) {
                    background-color: rgba(0, 38, 18, 0);
                    &:hover { background-color: rgba(122, 204, 41, 0.6); }
                }

                /*
                &:nth-child(1) {
                    background-image: url($path-images + 'landingpage/bg_nav_01.jpg');
                    background-size: cover;
                    background-repeat: no-repeat;
                }
                &:nth-child(2) {
                    background-image: url($path-images + 'landingpage/bg_nav_02.jpg');
                    background-size: cover;
                    background-repeat: no-repeat;
                }
                &:nth-child(3) {
                    background-image: url($path-images + 'landingpage/bg_nav_03.jpg');
                    background-size: cover;
                    background-repeat: no-repeat;
                }
                &:nth-child(4) {
                    background-image: url($path-images + 'landingpage/bg_nav_04.jpg');
                    background-size: cover;
                    background-repeat: no-repeat;
                }
                */

                div.inner_nav {
                    width:100%;
                    height:100%;
                    padding: 18% 0 18% 0;
                    margin:0;
                    @extend .animated; @extend .zoomIn;

                    .largelinks,
                    a .largelinks {
                        font-family: amatic_scregular, Verdana, sans-serif;
                        font-size: 52px;
                        font-weight: bold;
                        letter-spacing: 0.05em;
                        line-height:1em;
                        color: #ccff88;
                        border-bottom: 0;
                        padding: 0;
                        margin: 0 0 17px 0;
                    }

                    p {
                        clear: both;
                        color: #fff;
                        line-height:1.7em;
                    }
                }
            }
        }
    }

    footer {
        .footer_left {
            a {
                color: #fff;
                outline: none;
                &:hover { text-decoration:underline; }
            }
        }
    }
}


.hidden_links {
    background-color:#004521;
    display:none;
    width:100%;
    text-align:center;
    font-size:0px;
    color:red;
    margin: 35px auto;
    clear:both;

    h1 { border-bottom:0; }

    a {
        color:transparent;
    }
}


@media screen and (min-width: 0px) and (max-width: 1200px) {
    #landingpage {
        .container {
            header { padding:0 20px 0 20px; }

            .slideshow {
                .ueberbau {
                    .lf {
                        &.lf_content {
                            .lf_image { margin: 0 0 9% 0; }
                        }
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 0px) and (max-width: 980px) {
    #landingpage {
        .container {
            header { padding:0 20px 0 20px; }

            .slideshow {
                .ueberbau {
                    .lf {
                        &.lf_content {
                            .lf_image { margin:0 0 5% 0; }

                            .lf_text {
                                em,
                                a em {
                                    font-size: 30px;
                                    font-weight: bold;
                                    letter-spacing: 0.05em;
                                    color: #1f7300;
                                    border-bottom: 0;
                                    margin-top:0;
                                    margin-bottom:0;

                                    &:hover { color:#6ab023; }
                                }

                                p {
                                    color: #000;
                                    margin: 0 0 3% 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 0px) and (max-width: 920px) {
    #landingpage {
        .container {
            header { padding:0 20px 0 20px; }

            .slideshow {
                .ueberbau {
                    .lf {
                        &.lf_content {
                            padding:4% 2.5% 2.5% 2.5%;

                            .lf_image {
                                float:left;
                                width:50%;
                                padding:0;
                            }

                            .lf_text {
                                float:right;
                                width:50%;
                                padding:0 0 0 5%;
                                margin:30% 0 0 0;

                                em,
                                a em {
                                    font-size: 30px;
                                    font-weight: bold;
                                    letter-spacing: 0.05em;
                                    color: #1f7300;
                                    text-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
                                    border-bottom: 0;
                                    margin-top:0;
                                    margin-bottom:0;

                                    &:hover { color:#6ab023; }
                                }

                                p {
                                    color: #000;
                                    text-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
                                    margin: 0 0 3% 0;
                                }
                            }
                        }

                        &:nth-child(1) { width:25%; }
                        &:nth-child(2) { width:25%; }
                        &:nth-child(3) {
                            width:50%;

                            /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+85&0+0,1+85 */
                            background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 85%); /* FF3.6-15 */
                            background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 85%); /* Chrome10-25,Safari5.1-6 */
                            background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 85%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
                        }
                        &:nth-child(4) { clear:both; display:none; }
                    }
                }
            }
        }
    }
}



@media screen and (min-width: 0px) and (max-width: 680px) {
    #landingpage {
        .container {
            nav {
                div.bg_nav {
                    width: 50%;
                    height:300px;

                    &:nth-child(3),
                    &:nth-child(4) {
                        height:100%;
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 0px) and (max-width: 640px) {
    #landingpage {
        .container {
            .slideshow {
                .ueberbau {
                    .lf {
                        &.lf_content {
                            .lf_text {
                                margin:24% 0 0 0 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 0px) and (max-width: 560px) {
    #landingpage {
        background-image:url($path-images + 'landingpage/bg_landflair_small.jpg');

        .container {

            header {
                .site_width {
                    .logo_container {
                        background: $bg_color_logo url($path-images + 'website/logo-landflair.png') center 12px no-repeat;
                    }
                }
            }

            .slideshow {
                .ueberbau { display:none; }
            }

            .lf_small {
                display:inline-block;
                width:100%;
                text-align: center;
                padding:35px;
                margin:0;

                /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+50,ffffff+100&1+0,0.5+50,1+100 */
                background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0.5) 50%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

                .lf_image {
                    float:left;
                    width:50%;

                    -webkit-box-shadow: 10px 10px 15px -10px rgba(0,0,0,0.75);
                    -moz-box-shadow: 10px 10px 15px -10px rgba(0,0,0,0.75);
                    box-shadow: 10px 10px 15px -10px rgba(0,0,0,0.75);

                    img { margin:0; }
                }

                .lf_text {
                    float:left;
                    width:50%;
                    padding:0 0 0 25px;
                    margin: 50% 0 0 0;

                    em,
                    a em {
                        font-family: amatic_scregular, Verdana, sans-serif;
                        font-size: 30px;
                        font-weight: bold;
                        font-style:normal;
                        line-height:1em;
                        letter-spacing: 0.05em;
                        color: #1f7300;
                        border-bottom: 0;
                        padding: 0;
                        margin-top:0;
                        margin-bottom:0;

                        &:hover { color:#6ab023; }
                    }

                    p {
                        color: #000;
                        margin: 0 0 5% 0;
                    }
                }
            }

            nav { margin-top:-6px; }
        }
    }
}


@media screen and (min-width: 0px) and (max-width: 480px) {
    #landingpage {
        .container {
            header {
                padding:0 20px 0 20px;

                .site_width {
                    .menulink { display:none; }

                    a {
                        .logo_container {
                            background: $bg_color_logo url($path-images + 'website/logo-landflair.png') center 12px no-repeat;
                            width: 100%;
                        }
                    }
                }
            }

            .lf_small {
                padding:25px;

                .lf_text {
                    text-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
                    margin: 30% 0 0 0;
                }
            }
        }
    }
}


@media screen and (min-width: 0px) and (max-width: 380px) {
    #landingpage {
        .container {
            .lf_small {
                padding:25px;

                .lf_text {
                    margin: 20% 0 0 0;
                }
            }

            nav {
                div.bg_nav {
                    width: 100%;

                    &:nth-child(1) { height:100px; }
                    &:nth-child(2) { height:260px; }
                    &:nth-child(3) { height:260px; }
                    &:nth-child(4) { height:370px; }

                    div.inner_nav {
                        padding: 25px 0 0 0;
                        font-size:26px;
                    }
                }
            }
        }
    }
}