.basteltipps-galerie {
    clear:both;
    display:inline-block;
    width:100%;

    .basteltipps-galerie-row {
        .basteltipps-galerie-col {
            display:inline-block;
            float:left;

            // background-image:url($path-images + '02_machmit/gwg_bg_kachel.jpg');
            background-color:transparent;
            background-position:top center;
            background-size:cover;
            -webkit-background-size:cover;
            -moz-background-size:cover;
            -o-background-size:cover;
            background-attachement:fixed;
            background-repeat:no-repeat;

            width:24%;
            margin-right:1.3333333%;
            margin-bottom:5px;

            .basteltipps-galerie-img-wrapper {
                display:inline-block;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;
                border-radius: 15px;
                overflow:hidden;

                -webkit-box-shadow: 4px 4px 10px 0px rgba(50, 50, 50, 0.35);
                -moz-box-shadow:    4px 4px 10px 0px rgba(50, 50, 50, 0.35);
                box-shadow:         4px 4px 10px 0px rgba(50, 50, 50, 0.35);

                a {
                    outline:none;
                    /*
                    img {
                        -webkit-transition: all .4s ease;
                        -moz-transition: all .4s ease;
                        -ms-transition: all .4s ease;
                        -o-transition: all .4s ease;
                        transition: all .4s ease;

                        vertical-align: middle;

                        &:hover {
                            -webkit-transform:scale(1.5); /* Safari and Chrome *//*
                            -moz-transform:scale(1.5); /* Firefox *//*
                            -ms-transform:scale(1.5); /* IE 9 *//*
                            -o-transform:scale(1.5); /* Opera *//*
                            transform:scale(1.5);
                        }
                    }
                    */

                    img {
                        -webkit-transition: all .4s ease;
                        -moz-transition: all .4s ease;
                        -ms-transition: all .4s ease;
                        -o-transition: all .4s ease;
                        transition: all .4s ease;

                        &:hover { opacity:0.7; }
                    }
                }
            }

            &:nth-child(4n+0) { margin-right:0;}

            .basteltipps-galerie-text {
                display:inline-block;
                background:transparent;
                width:100%;
                min-height:90px;
                text-align:left;
                padding:10px 0 0 0;

                &:hover {
                    @extend .animated;
                    @extend .jello;
                }

                .b-PDF {
                    float:left;
                    width:26px;
                    min-height:50px;
                    margin:0 10px 0 0;
                    background:url($path-images + 'website/icon-pdf.png') no-repeat;
                }

                .b-YouTube {
                    float:left;
                    width:26px;
                    min-height:50px;
                    margin:0 10px 0 0;
                    background:url($path-images + 'website/icon-youtube.png') no-repeat;
                }

                .b-text {
                    a {
                        p {
                            font-family: amatic_scregular, Verdana, sans-serif;
                            font-size:30px;
                            font-weight:bold;
                            letter-spacing:0.02em;
                            color:#1f7300;
                            line-height:33px;
                            margin:5px 0 0 0;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 0) and (max-width: 969px) {
    .basteltipps-galerie {
        .basteltipps-galerie-row {
            .basteltipps-galerie-col{
                width:49%;
                margin-right:2%;

                &:nth-child(2n) { margin-right:0; }
                &:nth-child(2n+1) { clear:both; }
            }
        }
    }
}

@media screen and (min-width: 0) and (max-width: 480px) {
    .basteltipps-galerie {
        .basteltipps-galerie-row {
            .basteltipps-galerie-col {
                width:100%;
                margin-right:0%;
            }
        }
    }
}