$table_border_color: #000000;
$table_1st_cell_color: #ffda7f;


#preistabelle_2sp {
    background:#ffffff;
    width:100%;
    border:1px solid black;
    margin:10px auto;

    line-height:0;
    /*
    -webkit-border-radius:20px 20px 20px 20px;
    -moz-border-radius:20px 20px 20px 20px;
    -ms-border-radius:20px 20px 20px 20px;
    -o-border-radius:20px 20px 20px 20px;
    border-radius:20px 20px 20px 20px;
    */
    overflow:hidden;

    div {
        padding:0;
        margin:0;
    }

    .row {
        border-bottom:1px solid $table_border_color;
        clear:both;

        &:nth-last-child(1) {
            border-bottom:0px solid $table_border_color;
            clear:both;
        }

        .cell {
            float:left;
            border-right:1px solid $table_border_color;
            padding:15px;

            line-height: 23px;

            &:nth-child(1) {
                width:75%;
                background: $table_1st_cell_color;
            }

            &:nth-child(2) {
                width:25%;
                border-right:0;
            }

        }

        .clear {clear:both;}
    }
}



@media all and (max-width:830px) {
    #preistabelle_2sp {
        margin:20px auto 0px;

        .row {
            .cell {
                float:none;
                border-right:0px;
                border-bottom:1px solid $table_border_color;

                &:nth-child(1) {
                    background: $table_1st_cell_color;
                    width:100%;
                }

                &:nth-child(2) {
                    width:100%;
                    border-right:0px;
                    border-bottom:0px;
                }
            }
        }
    }
}