@function em($px, $base: $baseSize) {
	@return ($px / $base) * 1em;
}

/*
	Helper-Funktion um Einheiten von den Einheiten zu entfernen.
	Ausgabe in PX
*/
@function strip-unit($number) {
	@if unit($number) == rem {
		@return $number / ($number * 0 + 1) * $rem-ratio;
	} @else if unit($number) == em {
		@return $number / ($number * 0 + 1) * $em-ratio;
	} @else {
		// Default: px Unit
		@return $number / ($number * 0 + 1);
	}
}

@function breakpoint-convert($number) {
	@return (strip-unit($number) / $em-ratio) * 1em;
}