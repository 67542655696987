/**/
$fontPath: unquote('../fonts/AmaticSC/');


@font-face {
    font-family: 'amatic_scbold';
    src: url($fontPath + 'amaticsc-bold-webfont.eot');
    src: url($fontPath + 'amaticsc-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url($fontPath + 'amaticsc-bold-webfont.woff2') format('woff2'),
    url($fontPath + 'amaticsc-bold-webfont.woff') format('woff'),
    url($fontPath + 'amaticsc-bold-webfont.ttf') format('truetype'),
    url($fontPath + 'amaticsc-bold-webfont.svg#amatic_scbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'amatic_scregular';
    src: url($fontPath + 'amaticsc-regular-webfont.eot');
    src: url($fontPath + 'amaticsc-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url($fontPath + 'amaticsc-regular-webfont.woff2') format('woff2'),
    url($fontPath + 'amaticsc-regular-webfont.woff') format('woff'),
    url($fontPath + 'amaticsc-regular-webfont.ttf') format('truetype'),
    url($fontPath + 'amaticsc-regular-webfont.svg#amatic_scregular') format('svg');
    font-weight: normal;
    font-style: normal;
}