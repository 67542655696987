/* ### BOO-GRID ############################################################ */
.boo-grid { margin-bottom:30px; }

.row.special {
    padding: 0 15px 0 15px;
    [class^='col-xs-'],
    [class*='col-xs-'] {
        padding: 0 15px 0 15px;
        background: lightsalmon;
        border: 1px solid white;
        height: 50px;
        margin-bottom:10px;
    }
}

@media screen and (min-width: 750px) and (max-width: 969px) {
    .row.special {
        padding: 0 15px 0 15px;
        [class^='col-sm-'],
        [class*='col-sm-'] {
            background: lightgreen;
            border: 1px solid white;
            height: 50px;
            margin-bottom:10px;
        }
    }
}

@media screen and (min-width: 970px) and (max-width: 1169px) {
    .row.special {
        padding: 0 15px 0 15px;
        [class^='col-md-'],
        [class*='col-md-'] {
            background: lightgoldenrodyellow;
            border: 1px solid white;
            height: 50px;
            margin-bottom:10px;
        }
    }
}

@media screen and (min-width: 1170px) {
    .row.special {
        padding: 0 15px 0 15px;
        [class^='col-lg-'],
        [class*='col-lg-'] {
            background: lightskyblue;
            border: 1px solid white;
            height: 50px;
            margin-bottom:10px;
        }
    }
}



/* ### NAV-TABS ############################################################ */
#boo-nav-tabs {
    &.nav,
    &.nav-tabs {
        margin-left: 0;
        margin-bottom: 0;
        li {
            background: url('');
            list-style-type: none;
            margin: 0 0 -1px 0;

            a {
                color: inherit;
            }
        }
    }

    &.nav-tabs > li.active > a, .nav-tabs > li.active > a:hover,
    &.nav-tabs > li.active > a:focus {
        -moz-border-bottom-colors: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        background-color: #fff;
        border-color: #ddd #ddd transparent;
        border-image: none;
        border-style: solid;
        border-width: 1px;
        color: #555;
        cursor: default;
    }
}

.tab-content {
    background:#fff;
    padding: 10px;
    margin: 0 0 35px 0;
    border: 1px solid #ddd;
    border-top:0;
}



/* ### ACCORDION ########################################################### */
#accordion {
    display: inline-block;
    width:100%;
    margin: 0 !important;

    h4 { font-family: alegreya_sansregular, Verdana, sans-serif; a { font-weight:bold !important; } }

    .panel-heading { margin:0; }

    .panel-body { border-top-color: transparent !important; }
}



/* ### CAROUSEL ############################################################ */
.carousel-example-generic {
    display:inline-block;
    width: 100%;
    height: 100%;
}

.carousel-inner,
.carousel,
.item,
.container,
.fill {
    /* max-height:350px; */
    width: 100%;
    height: 100%;

    img {
        width: 100%;
    }
}

.carousel-control {
    font-size:48px;
    padding-top:25%;
    bottom:4px !important;

    .fa {
        color: #fff;
    }
}

.carousel {
    margin: 0;
}



/* ### MODAL ############################################################### */
.modal-example {
    display: block;
    width: 100%;
    margin: 0 0 50px 0;
}



/* ### BUTTONS & TOOLTIPS ################################################## */
.btn { margin-bottom:10px; }

.btn-success {
    color:#fff !important;
    outline:none;
}

.buttonsandtooltips {
    display: block;
    width: 100%;
    margin: 0 0 50px 0;

    /*
    button[data-toggle="tooltip"] {
        &:before { background:red; }
    }
    */

    /* Tooltip */
    .btn-default + .tooltip > .tooltip-inner {
        background-color: #73AD21;
        color: #FFFFFF;
        border: 1px solid #73AD21;
        padding: 10px;
        font-size: 16px;
    }

    /* Tooltip on top */
    .btn-default + .tooltip.top > .tooltip-arrow {
        border-top: 5px solid #73AD21;
        margin-bottom:5px;
    }

    /* Tooltip on bottom */
    .btn-default + .tooltip.bottom > .tooltip-arrow {
        border-bottom: 5px solid #73AD21;
    }

    /* Tooltip on left */
    .btn-default + .tooltip.left > .tooltip-arrow {
        border-left: 5px solid #73AD21;
    }

    /* Tooltip on right */
    .btn-default + .tooltip.right > .tooltip-arrow {
        border-right: 5px solid #73AD21;
    }
    /*
    .tooltip {
        position: absolute;
        z-index: 1070;
        display: block;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: normal;
        line-height: 1.42857143;
        text-align: left;
        text-align: start;
        text-decoration: none;
        text-shadow: none;
        text-transform: none;
        letter-spacing: normal;
        word-break: normal;
        word-spacing: normal;
        word-wrap: normal;
        white-space: normal;
        filter: alpha(opacity=0);
        opacity: 0;

        line-break: auto;
    }
    .tooltip.in {
        filter: alpha(opacity=90);
        opacity: .9;
    }
    .tooltip.top {
        padding: 5px 0;
        margin-top: -3px;
    }
    .tooltip.right {
        padding: 0 5px;
        margin-left: 3px;
    }
    .tooltip.bottom {
        padding: 5px 0;
        margin-top: 3px;
    }
    .tooltip.left {
        padding: 0 5px;
        margin-left: -3px;
    }
    .tooltip-inner {
        max-width: 200px;
        padding: 3px 8px;
        color: #fff;
        text-align: center;
        background-color: #000;
        border-radius: 4px;
    }
    .tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }
    .tooltip.top .tooltip-arrow {
        bottom: 0;
        left: 50%;
        margin-left: -10px;
        border-width: 10px 10px 0;
        border-top-color: #000;
    }
    .tooltip.top-left .tooltip-arrow {
        right: 5px;
        bottom: 0;
        margin-bottom: -5px;
        border-width: 5px 5px 0;
        border-top-color: #000;
    }
    .tooltip.top-right .tooltip-arrow {
        bottom: 0;
        left: 5px;
        margin-bottom: -5px;
        border-width: 5px 5px 0;
        border-top-color: #000;
    }
    .tooltip.right .tooltip-arrow {
        top: 50%;
        left: 0;
        margin-top: -5px;
        border-width: 5px 5px 5px 0;
        border-right-color: #000;
    }
    .tooltip.left .tooltip-arrow {
        top: 50%;
        right: 0;
        margin-top: -5px;
        border-width: 5px 0 5px 5px;
        border-left-color: #000;
    }
    .tooltip.bottom .tooltip-arrow {
        top: 0;
        left: 50%;
        margin-left: -5px;
        border-width: 0 5px 5px;
        border-bottom-color: #000;
    }
    .tooltip.bottom-left .tooltip-arrow {
        top: 0;
        right: 5px;
        margin-top: -5px;
        border-width: 0 5px 5px;
        border-bottom-color: #000;
    }
    .tooltip.bottom-right .tooltip-arrow {
        top: 0;
        left: 5px;
        margin-top: -5px;
        border-width: 0 5px 5px;
        border-bottom-color: #000;
    }
    */
}
