.lf-batch,
.lf-batch-main {
    display: inline-block;
    position: absolute;
    z-index: 100;
    background: #000 url($path-images + 'website/bg_batch.jpg') 50% 50% no-repeat;background-color: rgba(0,0,0,0);
    @include rotate(-5deg);
    @include border-radius(10px);
    @include box-shadow(7px 7px 20px rgba(0, 0, 0, 1));
    padding: 20px;

    &::after {
        content:'';
        position: absolute;
        z-index: 1;

        background-image: url($path-images + 'website/batch_grill.png');
        background-attachment: initial;
        background-repeat: no-repeat;
        background-size: contain;

        @include rotate(5deg);
        top: 80px;
        right: -135px;
        width: 230px;
        height: 230px;

        filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
        filter: url(#drop-shadow);
        -webkit-filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.8));
        filter: drop-shadow(5px 5px 5px rgba(0,0,0,0.8));
    }

    .batchhead {
        width: 100%;
        height: 65px;
        font-family: amatic_scregular, Verdana, sans-serif;
        color: #ccff88;
        font-size: 2.8em;
        font-weight: bold;
        @include text-shadow(2px 2px 6px rgba(0, 0, 0, 1));
        line-height: 1.3em;
    }

    /*
    .batchtext {
        width: 100%;
        font-size: 1.7em;
        color: #fff;
        line-height: 1.3em;
        @include text-shadow(2px 2px 6px rgba(0, 0, 0, 1));

        .batchprice {
            font-size: 1.8em;
            color: #fff;
        }
    }
    */
    .batchtext {
        width: 75%;
        font-size: 1.3em;
        color: #fff;
        line-height: 1.3em;
        @include text-shadow(2px 2px 6px rgba(0, 0, 0, 1));

        .bold {
            font-weight: bold;
        }

        .batchprice {
            font-size: 1.8em;
            color: #fff;
        }
    }

    a#link {
        @extend %button-basics;
        @extend %button-landflair;
        position: absolute;
        left: 20px;
        bottom: 25px;
        padding: 7px 5%;
        margin: 0 !important;
        @include box-shadow(7px 7px 20px rgba(0, 0, 0, 1));
    }
}

$disTop: 16vw;

.lf-batch {
    top: 40vh;
    left: 3%;
    width: 400px;
    height: 260px;

    @media screen and (min-width: 1381px) and (max-width: 1820px) {
        top: $disTop;
    }
    @media screen and (min-width: 1201px) and (max-width: 1380px) {
        top: $disTop;
    }
    @media screen and (min-width: 992px) and (max-width: 1200px) {
        top: $disTop;
        left: 2%;
    }
    @media screen and (min-width: 641px) and (max-width: 991px) {
        top: 0;
        left: 0;
        width: 100%;
        position:relative;
        background: #000 url($path-images + 'website/bg_batch_1000px.jpg') 50% 50% no-repeat;
        @include rotate(0deg);
        @include border-radius(0px);
        @include box-shadow(7px 7px 20px rgba(0, 0, 0, 0));

        &::after {
            background-image: url($path-images + 'website/batch_grill.png');
            @include rotate(0);
            top: 18px;
            left: 380px;
        }

        .batchtext {
            width: 320px
        }
    }
    @media screen and (min-width: 561px) and (max-width: 640px) {
        top: 0;
        left: 0;
        width: 100%;
        position:relative;
        background: #000 url($path-images + 'website/bg_batch_1000px.jpg') 50% 50% no-repeat;
        @include rotate(0deg);
        @include border-radius(0px);
        @include box-shadow(7px 7px 20px rgba(0, 0, 0, 0));

        &::after {
            background-image: url($path-images + 'website/batch_grill.png');
            @include rotate(0);
            top: 75px;
            left: 320px;
            width: 170px;
            height: 170px;
        }

        .batchtext {
            width: 280px
        }
    }
    @media screen and (min-width: 0px) and (max-width: 560px) {
        top: -5px;
        left: 0;
        width: 100%;
        position:relative;
        background: #000 url($path-images + 'website/bg_batch_1000px.jpg') 50% 50% no-repeat;
        @include rotate(0deg);
        @include border-radius(0px);
        @include box-shadow(7px 7px 20px rgba(0, 0, 0, 0));

        &::after {
            display:none;

            background-image: url($path-images + 'website/batch_grill.png');
            @include rotate(0);
            top: 92px;
            left: 340px;
            width: 140px;
            height: 140px;
        }

        .batchtext {
            width: 100%;
            font-size: 1.3em;

            .batchprice {
                font-size: 1.8em;
                color: #fff;
            }
        }

        a#link {
            bottom: 40px;
        }
    }
}


$disLeftStart: 65vw;

.lf-batch-main {
    top: 13vh;
    left: $disLeftStart;
    width: 400px;
    height: 260px;

    @media screen and (min-width: 1381px) and (max-width: 1820px) {
        left: $disLeftStart - 13;
    }
    @media screen and (min-width: 1201px) and (max-width: 1380px) {
        left: $disLeftStart - 13;
    }
    @media screen and (min-width: 992px) and (max-width: 1200px) {
        top: 13.7vw;
        left: $disLeftStart - 21;
    }
    @media screen and (min-width: 931px) and (max-width: 991px) {
        top: 13.7vw;
        left: $disLeftStart - 24;
    }
    @media screen and (min-width: 641px) and (max-width: 930px) {
        top: 0;
        left: 0;
        width: 100%;
        position:relative;
        background: #000 url($path-images + 'website/bg_batch_1000px.jpg') 50% 50% no-repeat;
        @include rotate(0deg);
        @include border-radius(0px);
        @include box-shadow(7px 7px 20px rgba(0, 0, 0, 0));

        &::after {
            background-image: url($path-images + 'website/batch_grill.png');
            @include rotate(0);
            top: 18px;
            left: 380px;
        }

        .batchtext {
            width: 320px
        }
    }
    @media screen and (min-width: 561px) and (max-width: 640px) {
        top: 0;
        left: 0;
        width: 100%;
        position:relative;
        background: #000 url($path-images + 'website/bg_batch_1000px.jpg') 50% 50% no-repeat;
        @include rotate(0deg);
        @include border-radius(0px);
        @include box-shadow(7px 7px 20px rgba(0, 0, 0, 0));

        &::after {
            background-image: url($path-images + 'website/batch_grill.png');
            @include rotate(0);
            top: 75px;
            left: 320px;
            width: 170px;
            height: 170px;
        }

        .batchtext {
            width: 280px
        }
    }
    @media screen and (min-width: 0px) and (max-width: 560px) {
        top: -5px;
        left: 0;
        width: 100%;
        position:relative;
        background: #000 url($path-images + 'website/bg_batch_1000px.jpg') 50% 50% no-repeat;
        @include rotate(0deg);
        @include border-radius(0px);
        @include box-shadow(7px 7px 20px rgba(0, 0, 0, 0));

        &::after {
            display:none;

            background-image: url($path-images + 'website/batch_grill.png');
            @include rotate(0);
            top: 92px;
            left: 340px;
            width: 140px;
            height: 140px;
        }

        .batchtext {
            width: 100%;
            font-size: 1.3em;

            .batchprice {
                font-size: 1.8em;
                color: #fff;
            }
        }

        a#link {
            bottom: 40px;
        }
    }
}