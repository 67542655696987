#unitegallery,
#unitecarousel {
    display:none;
    margin-top:30px;
}

.unitegallery-image { display:none; }


.ug-lightbox-compact .ug-lightbox-button-close{
    width:45px;
    height:41px;
    background-image:url($unitegallery-path + 'images/lightbox-icon-close-compact2.png');
    background-position: -15px 15px;
}

.ug-lightbox-compact .ug-lightbox-button-close.ug-button-hover{
    background-position: -15px -25px;
}