/*
	@Created on : 20.05.2015, 16:01:28
	@Author     : Erwin Oertel

	text-shadow photoshop B: 40%
	dunkler Button: Tonwertkorrektur Tiefton 30
*/

%button-basics {
    position:relative;
    display: inline-block;
    font-size:1em;
    letter-spacing: 0.05em;
    color: #ffffff;
    text-decoration:none;
    padding: 7px 5%;
    margin-top: 0%;

    -webkit-border-radius: 5px;
    -moz-border-radius:    5px;
    border-radius:         5px;
}


/* ### GREEN ############################################################ */
.button_green {
    @extend %button-basics;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEwMCAxMDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPjxsaW5lYXJHcmFkaWVudCBpZD0iaGF0MCIgZ3JhZGllbnRVbml0cz0ib2JqZWN0Qm91bmRpbmdCb3giIHgxPSI1MCUiIHkxPSIxMDAlIiB4Mj0iNTAlIiB5Mj0iLTEuNDIxMDg1NDcxNTIwMmUtMTQlIj4KPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwODQ1NyIgc3RvcC1vcGFjaXR5PSIxIi8+CjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzAwYmI3YyIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgIDwvbGluZWFyR3JhZGllbnQ+Cgo8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgZmlsbD0idXJsKCNoYXQwKSIgLz4KPC9zdmc+);
    background: #bbdd88; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: -moz-linear-gradient(top,  #bbdd88 0%, #66aa22 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#bbdd88), color-stop(100%,#66aa22)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #bbdd88 0%,#66aa22 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #bbdd88 0%,#66aa22 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #bbdd88 0%,#66aa22 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #bbdd88 0%,#66aa22 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bbdd88', endColorstr='#66aa22',GradientType=0 ); /* IE6-9 */

    border: 1px solid #66aa22;

    /* text-shadow: <offset-x> <offset-y> <blur-radius> <color>; */
    text-shadow: 0px 0px 3px #2e4d0f;
}

.button_green:hover {
    background: #acdc7f; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2FjZGM3ZiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM0OWEyMWEiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #acdc7f 0%, #49a21a 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#acdc7f), color-stop(100%,#49a21a)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #acdc7f 0%,#49a21a 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #acdc7f 0%,#49a21a 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #acdc7f 0%,#49a21a 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #acdc7f 0%,#49a21a 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#acdc7f', endColorstr='#49a21a',GradientType=0 ); /* IE6-8 */

    border: 1px solid #49a21a;
}


/* ### BLUE ############################################################# */
.button_blue {
    @extend %button-basics;
    background: #7db9e8; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzdkYjllOCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMxZTU3OTkiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #7db9e8 0%, #1e5799 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#7db9e8), color-stop(100%,#1e5799)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #7db9e8 0%,#1e5799 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #7db9e8 0%,#1e5799 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #7db9e8 0%,#1e5799 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #7db9e8 0%,#1e5799 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7db9e8', endColorstr='#1e5799',GradientType=0 ); /* IE6-8 */

    border: 1px solid #1e5799;

    /* text-shadow: <offset-x> <offset-y> <blur-radius> <color>; */
    text-shadow: 0px 0px 3px #0f2c4d;
}

.button_blue:hover {
    background: #6dace0; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzZkYWNlMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwOTNjODciIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #6dace0 0%, #093c87 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#6dace0), color-stop(100%,#093c87)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #6dace0 0%,#093c87 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #6dace0 0%,#093c87 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #6dace0 0%,#093c87 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #6dace0 0%,#093c87 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6dace0', endColorstr='#093c87',GradientType=0 ); /* IE6-8 */

    border: 1px solid #093c87;
}


/* ### RED ############################################################## */
.button_red {
    @extend %button-basics;
    background: #de9b8d; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2RlOWI4ZCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNhZDVjMzYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #de9b8d 0%, #ad5c36 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#de9b8d), color-stop(100%,#ad5c36)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #de9b8d 0%,#ad5c36 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #de9b8d 0%,#ad5c36 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #de9b8d 0%,#ad5c36 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #de9b8d 0%,#ad5c36 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#de9b8d', endColorstr='#ad5c36',GradientType=0 ); /* IE6-8 */

    border: 1px solid #ad5c36;

    /* text-shadow: <offset-x> <offset-y> <blur-radius> <color>; */
    text-shadow: 0px 0px 3px #663620;
}

.button_red:hover {
    background: #db8b7d; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2RiOGI3ZCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNhMzQ3MWIiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #db8b7d 0%, #a3471b 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#db8b7d), color-stop(100%,#a3471b)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #db8b7d 0%,#a3471b 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #db8b7d 0%,#a3471b 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #db8b7d 0%,#a3471b 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #db8b7d 0%,#a3471b 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#db8b7d', endColorstr='#a3471b',GradientType=0 ); /* IE6-8 */

    border: 1px solid #a3471b;
}


/* ### ORANGE ########################################################### */
%button-orange {
    @extend %button-basics;
    background: #ffee99; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZWU5OSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZjk5MDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #ffee99 0%, #ff9900 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#ffee99), color-stop(100%,#ff9900)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #ffee99 0%,#ff9900 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #ffee99 0%,#ff9900 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #ffee99 0%,#ff9900 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #ffee99 0%,#ff9900 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffee99', endColorstr='#ff9900',GradientType=0 ); /* IE6-8 */

    border: 1px solid #ffd072;

    /* text-shadow: <offset-x> <offset-y> <blur-radius> <color>; */
    text-shadow: 2px 2px 3px #bb8800;

    &:hover {
        background: #f6e6b4; /* Old browsers */
        /* IE9 SVG, needs conditional override of 'filter' to 'none' */
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y2ZTZiNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUyJSIgc3RvcC1jb2xvcj0iI2ZmYWEwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
        background: -moz-linear-gradient(top,  #f6e6b4 0%, #ffaa00 52%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#f6e6b4), color-stop(52%,#ffaa00)); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top,  #f6e6b4 0%,#ffaa00 52%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top,  #f6e6b4 0%,#ffaa00 52%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top,  #f6e6b4 0%,#ffaa00 52%); /* IE10+ */
        background: linear-gradient(to bottom,  #f6e6b4 0%,#ffaa00 52%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6e6b4', endColorstr='#ffaa00',GradientType=0 ); /* IE6-8 */

        border: 1px solid #ffd072;
    }
}


/* ### GREY ############################################################# */
%button-grey {
    @extend %button-basics;
    background: #bcbdbf; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZWU5OSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZjk5MDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,  #bcbdbf 0%, #636466 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#bcbdbf), color-stop(100%,#636466)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #bcbdbf 0%,#636466 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #bcbdbf 0%,#636466 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #bcbdbf 0%,#636466 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #bcbdbf 0%,#636466 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bcbdbf', endColorstr='#636466',GradientType=0 ); /* IE6-8 */

    border: 1px solid #636466;

    /* text-shadow: <offset-x> <offset-y> <blur-radius> <color>; */
    text-shadow: 2px 2px 3px #445555;

    &:hover {
        background: #949596; /* Old browsers */
        /* IE9 SVG, needs conditional override of 'filter' to 'none' */
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2Y2ZTZiNCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjUyJSIgc3RvcC1jb2xvcj0iI2ZmYWEwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
        background: -moz-linear-gradient(top,  #949596 0%, #3E3E3F 52%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#949596), color-stop(52%,#3E3E3F)); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(top,  #949596 0%,#3E3E3F 52%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(top,  #949596 0%,#3E3E3F 52%); /* Opera 11.10+ */
        background: -ms-linear-gradient(top,  #949596 0%,#3E3E3F 52%); /* IE10+ */
        background: linear-gradient(to bottom,  #949596 0%,#3E3E3F 52%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#949596', endColorstr='#3E3E3F',GradientType=0 ); /* IE6-8 */

        border: 1px solid #3E3E3F;
    }
}


/* ### LANDFLAIR ######################################################## */
%button-landflair {
    @extend %button-basics;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#6ab023+0,004520+100 */
    background: #6ab023; /* Old browsers */
    background: -moz-linear-gradient(top,  #6ab023 0%, #004520 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  #6ab023 0%,#004520 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  #6ab023 0%,#004520 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6ab023', endColorstr='#004520',GradientType=0 ); /* IE6-9 */

    border: 1px solid #004520;

    /* text-shadow: <offset-x> <offset-y> <blur-radius> <color>; */
    text-shadow: 2px 2px 3px #445555;

    &:hover {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#5a961e+0,002b14+100 */
        background: #5a961e; /* Old browsers */
        background: -moz-linear-gradient(top,  #5a961e 0%, #002b14 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #5a961e 0%,#002b14 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #5a961e 0%,#002b14 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5a961e', endColorstr='#002b14',GradientType=0 ); /* IE6-9 */

        border: 1px solid #002b14;
    }
}



