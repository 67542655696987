i.fa { color: darkred; font-family: FontAwesome; }

.fontawesome-example {
    margin:0 0 50px 0;

    i.fa { width:30px; font-size: 24px; }
}


/* ### FONTAWESOME ### */
div.row.fontawesome_tab {
    display:table;
    clear:both;
    width:100%;
    height:105px;
    padding:0 0 0 0;
    margin:0 0 0 0;

    div {
        display:table-cell;
        text-align:center;
        float:left;
        padding:5px;
        margin:0 0 0 0;
        font-size:13px;

        &.clearfix { display:none; }

        .frame {
            display:inline-block;
            background:#eee;
            width:100%;
            min-height:125px;
            padding-top:15px;

            i,
            span {
                width:100%;
                float:left;
                margin:1px;
            }

            i {
                font-size:32px;
                margin-bottom:5px;
            }
        }
    }
}