#parallax-container-solo,
#parallax-container-content {
    display:inline-block;
    width: 100%;
    min-height: 100px;
    margin: -7px 0 0 0;

    &:first-child { margin: 0; }

    &.eoe-content {
        padding: 7% 0 7% 0;
        // height:1800px !important;
    }

    .site_standard {
        position:relative;
        z-index:1;
        background: rgba(255,255,255,0.8);
        padding:25px;
    }
}





@media screen and (min-width: 0px) and (max-width: 1220px) {
    #parallax-container-solo,
    #parallax-container-content {
        height: unset;
        min-height:600px;
        padding: 5% 10px 10% 10px;
        margin: -1px 0 0 0;
    }
}

