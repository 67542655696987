@import '../config/vars';

/* ### DREI TEASERKÄSTEN ################################################### */
.dreiteaserkaesten {
    .centered { text-align:center; }

    i {
        margin:25px 0 20px 0;
        font-size:28px;

        &.fa { color:$color-general; }
    }

    h4 {
        font-family: alegreya_sansregular, Verdana, sans-serif;
        font-size:24px;
        font-weight:bold;
        letter-spacing:0.02em;
        color:#445555;
        padding:0;
        margin:0 0 11px 0;
    }

    .btn {
        -moz-user-select: none;
        background-image: none;
        border: 0px solid transparent;
        border-radius: 4px;
        cursor: pointer;
        display: inline-block;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.42857;
        margin-bottom: 0;
        padding: 6px 12px;
        text-align: center;
        vertical-align: bottom;
        white-space: nowrap;
    }

    .btn-theme {
        background-color: #384452;
        border-color: #384452;
        color: #fff;
        margin-top: 15px;
        outline:none;

        &:hover,
        &:focus {
            background: #1f7300;
            text-decoration: none;
            color: #fff;
            border-color: #1f7300;
            transition: background-color 0.4s linear 0s, color 0.4s linear 0s;
        }
    }
}
/* ### DREI TEASERKÄSTEN end ############################################### */