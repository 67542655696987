
.gwz_kasten {
    display:inline-block;
    background:#b19081;
    width:100%;
    text-align:left;
    color:#fff;
    padding:20px;
    margin: 0;

    ol {
        margin: 0 0 0 -25px;

        li {
            margin: 0 0 15px 0;
        }
    }
}