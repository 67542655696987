/*
	@Created on : 07.07.2014, 11:03:27
	@Author     : Vedat Özdemir

	@Description: Helper Funktionen und SASS Mixins
*/

/*
INHALT
======

ANIMATION
BORDER
BOX-SHADOWS
BOXSIZING
BUTTONS
COLOR-COLLECTION
FONT-SIZE
LINE-HEIGHT
NORMALIZE
OPACITY
PADDING and MARGIN
POSITION
ROTATE
TEXT-ALIGN
TOP, RIGHT, BOTTOM, LEFT
TRANSITION
WIDTH and HEIGHT
VERTICAL-ALIGN
*/


/* ### BOXSIZING ###########################################################
@mixin box-sizing($width: 50%, $border-width: 0.1rem, $border-color: $grey-06) {
    border-color: $border-color;
    border-style: solid;
    border-width: $border-width;

    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;

    -moz-background-clip: padding-box !important;
    -webkit-background-clip: padding-box !important;
    background-clip: padding-box !important;

    width: $width;

    &,
    .no-boxsizing & {
        *behavior: url(boxsizing.htc);
    }
}
*/

/* ### NORMALIZE ########################################################### */
/*
	Allgemeine Definitionen zum normalisieren von REM Einheiten mit Fallback.
	Input in px, rem und ohne Einheit möglich (es werden dann px angenommen)!
*/
@mixin normalize($property, $sizeValue1, $sizeValue2: null, $sizeValue3: null, $sizeValue4: null, $important: null) {
    @if $sizeValue4 != null {

        #{$property}: strip-unit($sizeValue1) + px strip-unit($sizeValue2) + px strip-unit($sizeValue3) + px strip-unit($sizeValue4) + px $important;
        #{$property}: strip-unit($sizeValue1/$rem-ratio) + rem strip-unit($sizeValue2/$rem-ratio) + rem strip-unit($sizeValue3/$rem-ratio) + rem strip-unit($sizeValue4/$rem-ratio) + rem $important;

    } @else if $sizeValue3 != null {

        @if $sizeValue2 == auto {
            #{$property}: strip-unit($sizeValue1) + px auto strip-unit($sizeValue3) + px $important;
            #{$property}: strip-unit($sizeValue1/$rem-ratio) + rem auto strip-unit($sizeValue3/$rem-ratio) + rem $important;
        } @else {
            #{$property}: strip-unit($sizeValue1) + px strip-unit($sizeValue2) + px strip-unit($sizeValue3) + px $important;
            #{$property}: strip-unit($sizeValue1/$rem-ratio) + rem strip-unit($sizeValue2/$rem-ratio) + rem strip-unit($sizeValue3/$rem-ratio) + rem $important;
        }

    } @else if $sizeValue2 != null {

        @if $sizeValue2 == auto {
            #{$property}: strip-unit($sizeValue1) + px auto $important;
            #{$property}: strip-unit($sizeValue1/$rem-ratio) + rem auto $important;
        } @else {
            #{$property}: strip-unit($sizeValue1) + px strip-unit($sizeValue2) + px $important;
            #{$property}: strip-unit($sizeValue1/$rem-ratio) + rem strip-unit($sizeValue2/$rem-ratio) + rem $important;
        }

    } @else {

        #{$property}: strip-unit($sizeValue1) + px $important;
        #{$property}: strip-unit($sizeValue1/$rem-ratio) + rem $important;
    }
}

@mixin normalizeImportant($property, $sizeValue1, $sizeValue2: null, $sizeValue3: null, $sizeValue4: null) {
    @include normalize($property, $sizeValue1, $sizeValue2, $sizeValue3, $sizeValue4, !important);
}


/* ### BACKGROUND-SIZE ##################################################### */
/*
	@Values: length (PX), percentage, cover, contain, initial
*/
@mixin background-size($val: cover, $repeat: no-repeat, $attachment: inherit, $imgsrc: none) {
    background-repeat: $repeat;
    background-size: $val;
    background-attachment: $attachment;
    -webkit-background-size: $val;
    -moz-background-size: $val;
    -o-background-size: $val;
    filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='#{$imgsrc}', sizingMethod='scale');          /* To make IE work */
    -ms-filter: "progid:DXImageTransform.Microsoft.AlphaImageLoader(src='#{$imgsrc}', sizingMethod='scale')";    /* To make IE work */
}


/* ### POSITION ############################################################ */
@mixin pos($pos: r) {
    $str: relative;

    @if $pos == 'r' {
        $str: relative;
    } @else if $pos == 'a' {
        $str: absolute;
    } @else if $pos == 'f' {
        $str: fixed;
    }

    position: $str;
}


/* ### TOP, RIGHT, BOTTOM, LEFT ############################################ */
/*
	Shorthands für absolute Positionierung
*/
@mixin top($value)      { @include normalize(top, $value); }
@mixin right($value)    { @include normalize(right, $value); }
@mixin bottom($value)   { @include normalize(bottom, $value); }
@mixin left($value)     { @include normalize(left, $value); }


/* ### WIDTH and HEIGHT #################################################### */
/*
	Height Definitionen für REM Einheiten mit Fallback.
	Input in px oder rem, Default ist px!
*/
@mixin height($sizeValue)    { @include normalize(height, $sizeValue); }
@mixin min-height($sizeValue){ @include normalize(min-height, $sizeValue); }
@mixin max-height($sizeValue){ @include normalize(max-height, $sizeValue); }

/*
	Width Definitionen für REM Einheiten mit Fallback.
	Input in px oder rem, Default ist px!
*/
@mixin width($sizeValue)    { @include normalize(width, $sizeValue); }
@mixin min-width($sizeValue){ @include normalize(min-width, $sizeValue); }
@mixin max-width($sizeValue){ @include normalize(max-width, $sizeValue); }


/* ### OPACITY ############################################################# */
@mixin opacity($val) {
    $op: $val / 100;
    opacity: $op;
    filter: alpha(opacity=$val);
}

@mixin opacityRGBA($r:255, $g:255, $b:255, $a:0.75) {
    background: rgb($r, $g, $g);
    background: rgba($r, $g, $b, $a);
}


/* ### FONT-SIZE ########################################################### */
/*
	Font-Size Definitionen für REM Einheiten mit Fallback.
	Input in px oder rem, Default ist px!
*/
@mixin font-size($sizeValue: 16){ @include normalize(font-size, $sizeValue); }


/* ### TEXT-SCHADOW ######################################################## */
/* unten rechts blur farbe (als Hexadezimalwert oder rgb oder rgba -> für Schatten-Opacity (a = alpha-Kanal) */
@mixin text-shadow($shdw: 1px 1px $grey-01) {
    @if $shdw != 'none' {
        text-shadow: $shdw;
    } @else {
        text-shadow: none;
    }
}


/* ### TEXT-ALIGN ########################################################## */
@mixin tAlign($str: l) {
    $align: left;

    @if $str == 'l' {
        $align: left;
    } @else if $str == 'r' {
        $align: right;
    } @else if $str == 'c' {
        $align: center;
    } @else if $str == 'j' {
        $align: justify;
    }

    text-align: $align;
}


/* ### LINE-HEIGHT ######################################################### */
@mixin line-height($value) {
    @include normalize(line-height, $value);
}


/* ### VERTICAL-ALIGN ###################################################### */
@mixin vertical-align {
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}


/* ### BORDER ############################################################## */
@mixin border($width: 1, $style: solid, $color: $grey-04, $border: 'bottom') {
    @if $border == 'all' {
        @include normalize(border-width, $width);
        border-color: $color;
        border-style: $style;
    } @else {
        @include normalize(border-#{$border}-width, $width);
        border-#{$border}-color: $color;
        border-#{$border}-style: $style;
    }
}

@mixin border-radius($radius: 4px) {
    -webkit-border-radius: $radius;
    -khtml-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
    border-radius: $radius;
}

@mixin percent-border-radius($radius: 50%) {
    -webkit-border-radius: $radius;
    -khtml-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
    border-radius: $radius;
}

@mixin rem-border-radius($sizeValue1: 0, $sizeValue2: null, $sizeValue3: null, $sizeValue4: null) {
    @include normalize(-webkit-border-radius, $sizeValue1, $sizeValue2, $sizeValue3, $sizeValue4);
    @include normalize(-khtml-border-radius, $sizeValue1, $sizeValue2, $sizeValue3, $sizeValue4);
    @include normalize(-moz-border-radius, $sizeValue1, $sizeValue2, $sizeValue3, $sizeValue4);
    @include normalize(-ms-border-radius, $sizeValue1, $sizeValue2, $sizeValue3, $sizeValue4);
    @include normalize(-o-border-radius, $sizeValue1, $sizeValue2, $sizeValue3, $sizeValue4);
    @include normalize(border-radius, $sizeValue1, $sizeValue2, $sizeValue3, $sizeValue4);
}


/* ### PADDING and MARGIN ################################################## */
/*
	Padding Definitionen für REM Einheiten mit Fallback.
	Input in px!
	0 bis 4 Argumente können übergeben werden.
*/
@mixin padding($sizeValue1:0, $sizeValue2: null, $sizeValue3: null, $sizeValue4: null){
    @include normalize(padding, $sizeValue1, $sizeValue2, $sizeValue3, $sizeValue4);
}

/*
	Margin Definitionen für REM Einheiten mit Fallback.
	Input in px!
	0 bis 4 Argumente können übergeben werden.
*/
@mixin margin($sizeValue1:0, $sizeValue2: null, $sizeValue3: null, $sizeValue4: null){
    @include normalize(margin, $sizeValue1, $sizeValue2, $sizeValue3, $sizeValue4);
}


/* ### ANIMATION ########################################################### */
/*
	@see: modules/animate
	$name: bounce, flyout, flyin, fadeInUp, pulse, swing, wobble, shake, zoomIn, tada, anim-rotate,
*/
@mixin animation($name, $timing: 0.5s) {
    -webkit-animation: $name $timing;
    -moz-animation: $name $timing;
    -ms-animation: $name $timing;
    -o-animation: $name $timing;
    animation: $name $timing;
}

@mixin animation-name($str) {
    -webkit-animation-name: $str;
    -moz-animation-name: $str;
    -ms-animation-name: $str;
    -o-animation-name: $str;
    animation-name: $str;
}


/* ### TRANSITION ########################################################## */
@mixin transition($transition: all 0.2s linear) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}


/* ### ROTATE ############################################################## */
@mixin rotate($val: 45, $skew: 0) {
    -webkit-transform: skew($skew+deg) rotate($val+deg);
    -moz-transform: skew($skew+deg) rotate($val+deg);
    -o-transform: skew($skew+deg) rotate($val+deg);
    -ms-transform: skew($skew+deg) rotate($val+deg);
    transform: skew($skew+deg) rotate($val+deg);
}


/* ### COLOR-COLLECTION #################################################### */
/*
	Klassen erzeugen für die Farbräume als .color-... und .bgColor-....
	@variables: $collection(array), $group(string)

	@return: .color-group-x

	@decription: group: Gruppenname in config/colors definiert 'grey', 'blue', 'green', ...
				 x: steht für die Nummerierung 01, 02, ... 10, 11, 12, ...
*/
@mixin colorCollection($collection, $group) {
    @for $i from 1 to length($collection) {
        $name: $i;
        @if ($i < 10) { $name: '0'+$i };

        .color-#{$group}-#{$name} { color: nth($collection, $i+1); }
        .bgColor-#{$group}-#{$name} { background-color: nth($collection, $i+1); }
    }
}


/* ### BOX-SHADOW ########################################################## */

/*
	Box-Shadow
	Standard aus _vars.scss
*/
@mixin box-shadow($shdw: $shadow-default, $shdw2: '', $shdw3: '') {
    @if $shdw == ''  {
        $shdw: $shadow-default;
    }
    @if $shdw2 != '' {
        $shdw: $shdw, $shdw2;
    }
    @if $shdw3 != '' {
        $shdw: $shdw, $shdw2, $shdw3;
    }

    -webkit-box-shadow: $shdw;
    -moz-box-shadow: $shdw;
    box-shadow: $shdw;
}


@mixin boxShadow($effect: 1, $bg-image: #fff, $bg-color: #fff, $shdw: '') {

    @extend .p-rel;
    // Höhe: 200px Breite: 315px -> Visitenkarte 55mm x 85mm
    @include min-height(200);
    @include min-width(100%);

    background: $bg-image;
    /* background: url('../images/startseite/1.jpg'); */

    &:before,
    &:after {
        @extend .p-abs;
        z-index: -1;
    }

    /* Effect 1: Schatten unten
    ------------------------------------------------------ */
    @if $effect == 1 {
        @include margin(0,0,15,0);
                        /*  */
        @if $shdw == '' { $shdw: 0 10px 6px -6px #888; }
        @include box-shadow($shdw);
    }

        /* Effect 2: Schatten unten (links / rechts)
        ------------------------------------------------------ */
    @else if $effect == 2 {
        @include margin(0,0,10,0);
        @if $shdw == '' { $shdw: 0 15px 10px #888; }

        &:before,
        &:after {
            @include bottom(15);
            @include box-shadow($shdw);
            background-color: $bg-color;
            content: "";
            top: 80%;
            width: 50%;
        }

        &:before {
            @include left(10);
            @include rotate(-3);
        }

        &:after {
            @include right(10);
            @include rotate(3);
        }
    }

        /* Effect 3: Schatten unten links
        ------------------------------------------------------ */
    @else if $effect == 3 {
        @include margin(0,0,15,0);
        @if $shdw == '' { $shdw: 0 15px 10px #888; }

        &:before {
            @include bottom(15);
            @include box-shadow($shdw);
            @include left(10);
            @include rotate(-3);
            background: $bg-color;
            content: "";
            top: 80%;
            width: 50%;
        }
    }

        /* Effect 4: Schatten unten rechts
        ------------------------------------------------------ */
    @else if $effect == 4 {
        @include margin(0,0,15,0);
        @if $shdw == '' { $shdw: 0 15px 10px #888; }

        &:after {
            @include bottom(15);
            @include box-shadow($shdw);
            @include right(10);
            @include rotate(3);
            background: $bg-color;
            content: "";
            top: 80%;
            width: 50%;
        }
    }

        /* Effect 5: Schatten innen, unten gewölbt
        ------------------------------------------------------ */
    @else if $effect == 5 {
        @include box-shadow(0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset);
        @include margin(0,0,15,0);
        @if $shdw == '' { $shdw: 0 0 20px rgba(0,0,0,0.8); }

        &:before,
        &:after {
            @include box-shadow($shdw);
            @include right(20);
            @include border-radius(unquote('100px/10px'));
            background-color: $bg-color;
            bottom: 0;
            content: "";
            top: 50%;
        }

        &:before {
            @include left(20);
        }

        &:after {
            @include right(10);
            @include rotate(3);
        }
    }

        /* Effect 6: Schatten innen, oben/unten gewölbt
        ------------------------------------------------------ */
    @else if $effect == 6 {
        @include box-shadow(0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset);
        @include margin(0,0,15,0);
        @if $shdw == '' { $shdw: 0 0 20px rgba(0,0,0,0.5); }

        &:before,
        &:after {
            @include box-shadow($shdw);
            @include border-radius(unquote('100px/10px'));
            @include left(10);
            @include right(10);
            background-color: $bg-color;
            bottom: 0;
            content: "";
            top: 0;
        }

        &:after {
            @include right(10);
            @include rotate(3, 8);
            left: auto;
        }
    }

        /* Effect 7: Schatten innen, links/recht gewölbt
        ------------------------------------------------------ */
    @else if $effect == 7 {
        @include box-shadow(0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset);
        @include margin(5,0,5,0);
        @if $shdw == '' { $shdw: 0 0 20px rgba(0,0,0,0.5); }

        &:before,
        &:after {
            @include bottom(10);
            @include border-radius(unquote('100px/10px'));
            @include box-shadow($shdw);
            @include top(10);
            left: 3px;
            right: 3px;
            background-color: $bg-color;
            content: "";
        }

        &:after {
            @include right(10);
            @include rotate(3, 8);
            left: auto;
        }
    }

    /* Effect 8: Schatten unten, rechts
    ------------------------------------------------------ */
    @else if $effect == 8 {
        @include margin(0,0,15,0);
        /* Versatz nach rechts, Versatz nach unten, Blur, Größe, Farbe */
        @if $shdw == '' { $shdw: 4px 4px 10px 2px #dfdfdf; }
        @include box-shadow($shdw);
    }
}

.boxShadow1 { @include boxShadow(1); }
.boxShadow2 { @include boxShadow(2); }
.boxShadow3 { @include boxShadow(3); }
.boxShadow4 { @include boxShadow(4); }
.boxShadow5 { @include boxShadow(5); }
.boxShadow6 { @include boxShadow(6); }
.boxShadow7 { @include boxShadow(7); }
.boxShadow8 { @include boxShadow(8); }


/* ### BUTTONS ############################################################# */
/*
	Button mit Verlauf
	@see: modules/_buttons.scss

@mixin btn-style($light-color: $grey-04, $dark-color: $grey-06, $border-color: $grey-07, $font-color: $color-01) {
    background: $light-color;
    border-color: $border-color;
    color: $font-color;
    @include box-shadow(inset 0 -33px 17px -17px $dark-color);
    @include font-size($default-font-size);
    @include padding(0, 20px);
    @include text-shadow(0 -1px 0 rgba(0, 0, 0, 0.4));

    &:hover,
    &:focus,
    &.ui-button:focus,
    &.ui-button:hover {
        background: shade($light-color, 5%);
        @include box-shadow(inset 0 -1px 0 rgba(0, 0, 0, 0.1), inset 0 1px 0 rgba(255, 255, 255, 0.3), inset 0 -33px 17px -17px shade($dark-color, 6%));
    }

    &:active,
    &.is-active,
    &.ui-button:active,
    &.ui-button.is-active {
        @include border(1,solid,rgba(0, 0, 0, 1),bottom);
        @include border(1,solid,rgba(0, 0, 0, 1),top);
        @include box-shadow(inset 0 1px 0 rgba(0, 0, 0, 0.05), inset 0 -1px 0 rgba(255, 255, 255, 0.2), inset 0 -33px 17px -17px shade($light-color, 5%));
        background: $dark-color;
    }

    &.is-inactive {
        color: lighten($light-color, 20%);
        cursor: default;
    }

    .no-rgba & {
        border: 1px solid shade($light-color, 10%);
    }
}*/