/* VARIABLEN ################################################### */
/* Pfade */
$path-root: "/";
$path-images: '../images/';
$total-width: 100%;

$site_width:1229px;

$color-general: #1f7300; /* Links, Headlines, Specials */
$color-general-hover: #6ab023;
$font-color-general: #445555;

$sitepadding_left: 0px;
$sitepadding_right: 0px;

$bg_color_logo: transparent;
$bg_color_orientation: transparent;
$bg_color_content: transparent;
$bg_color_footer: #000;
$bg_color_footer_left: transparent;
$bg_color_footer_right: transparent;
$footer_text_color: #fff;





/* Für Einheit-Umrechnung */
$rem-ratio: 16;     // Schriftgröße des <html>-tags in px
$em-ratio: 16;      // Standard Browser Schriftgröße


/* Höhen und Breiten für Layout-Elemente */
$box-info-min-width: 40rem;


/* Textgrößen */
$baseSize: 14px;
$default-font-size: 16;
$default-spacing: 20px;
$default-small-spacing: 20px/2;
$default-xsmall-spacing: 7px;
$default-border-radius: 4px;


/* Standart-Shadows */
$shadow-default: 0 3px 0 0 rgba(0,0,0,0.2),
0 -2px 0 0 rgba(0,0,0,0.2),
3px 0 0 0 rgba(0,0,0,0.2),
-2px 0 0 0 rgba(0,0,0,0.2);

$shadow-01: 0 3px 5px -1px rgba(0,0,0,0.5);
$shadow-02: 0 3px 8px rgba(0, 0, 0, 0.45);
/* VARIABLEN end ############################################### */