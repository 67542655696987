/**/
$fontPath: unquote('../fonts/Glyphicons/');

@font-face {
    font-family: 'Glyphicons Halflings';
    src: url($fontPath + 'glyphicons-halflings-regular.eot');
    src: url($fontPath + 'glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
    url($fontPath + 'glyphicons-halflings-regular.woff2') format('woff2'),
    url($fontPath + 'glyphicons-halflings-regular.woff') format('woff'),
    url($fontPath + 'glyphicons-halflings-regular.ttf') format('truetype'),
    url($fontPath + 'glyphicons-halflings-regular.svg#glyphicons-halflings-regular') format('svg');
    font-weight: normal;
    font-style: normal;
}