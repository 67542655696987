/* VARIABLEN ################################################### */
/* FORM */
$form-width:100%;
$form-field-border-color: #bbb;
$label-width: 135px;
$input-text-width: 420px;
$input-text-width_s: 93px;
$input-text-width_l: 320px;
$input-text-distance: 3px;
$input-border: 1px;
$input-bottom-distance: 10px;

$input-text-field_s: $input-text-width_s + $input-text-distance + $label-width + $input-border*4;
$input-text-field_l: $input-text-width_l + $input-text-distance + $label-width + $input-border*4;

$input-text-height: 31px;
$input-textarea-height: 150px;
$input-text-padding: 7px 6px 6px 8px;

$input-captcha-width: 167px;
/* VARIABLEN end ############################################### */


/* THEMENVORSCHLAEGE */
.yform {
    margin: 10px 0 20px 0;

    form {
        padding: 0 0 15px 0;

        .form_warning {
            list-style-type:none;
            margin: 0 0 25px 0;
            li {
                background: rgba(0, 0, 0, 0) url($path-images + '../images/website/list_square_red.gif') no-repeat scroll 0 8px;
                color: #cd071e;
            }
        }

        #form_themenvorschlaege {
            width:$form-width;
            padding: 0;
            margin: 0;
            background:transparent;

            input { @extend .input_shadow; }

            div {
                &.form-group { margin: 0 0 $input-bottom-distance 0; }
            }

            em {
                font-weight: bold;
                font-style: normal;
                color: #cc0011;
                margin: 0 0 0 2px;
            }

            .themen_intro { margin: 0 0 20px 0; }

            /* Für alle id, die mit yform-formularular- beginnen (Schreibweise geht auch mit class) */
            [id^='yform-formular-'],
            [id*='yform-formular-'] {
                border-radius: 5px;
            }

            label {
                float:left;
                background:transparent;
                display: inline-block;
                width: $label-width;
                line-height: 1.8em;
                vertical-align: top;
            }

            #yform-formular-name,
            #yform-formular-email,
            #yform-formular-raiffeisenmarkt,
            #yform-formular-thema1,
            #yform-formular-thema2,
            #yform-formular-thema3 {
                input {
                    width: $input-text-width;
                    height: $input-text-height;
                    border: 1px solid $form-field-border-color;
                    padding: $input-text-padding;
                }
            }

            #yform-formular-raiffeisenmarkt {
                label { width: 100%; }
                input { margin: 0 0 20px $label-width; }
            }

            .themen { margin: 0 0 20px 0; }

            #yform-formular-thema3 { margin: 0 0 20px 0; }

            /* ### CAPTCHA ########################################################### */
            .form-captcha {
                span.input-group-addon {
                    display:inline-block;
                    margin-right: 15px;
                    padding:2px;
                    background:#888;
                    float:left;
                    border: none;
                    border-radius: 0;
                    width: auto;
                }

                // Captcha
                #yform-formular-field-9 {
                    width:$input-captcha-width;
                    // width: calc(100% - 150px);
                    padding: $input-text-padding;
                    margin:0;
                    display: inline-block;
                    border-color: $form-field-border-color;
                }

                .input-group-btn {
                    display: inline-block;
                    padding-left:15px;
                }

                a.btn {
                    background: none;
                    border: 0;
                    font-size: 0;
                    padding: 0;
                    margin: 0;
                    outline: none;

                    &::after {
                        content: ' Neu laden';
                        font-size: 18px;
                        padding-left: 5px;
                        color: #455;
                    }

                    &::before {
                        padding: 2px;
                        -webkit-font-smoothing: antialiased;
                        font: normal normal normal 14px/1 FontAwesome;
                        content: "\f021";
                        font-size: 18px;
                        display: inline-block;
                        -webkit-transition: all 0.3s 0s ease-in-out;
                        -moz-transition: all 0.3s 0s ease-in-out;
                        -o-transition: all 0.3s 0s ease-in-out;
                        transition: all 0.3s 0s ease-in-out;
                        color: #7d7d7d;
                    }

                    &:hover {
                        &::before {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            /* ### BUTTON ############################################################ */
            %button-basics {
                position:relative;
                display: inline-block;
                font-size:1em;
                letter-spacing: 0.05em;
                color: #ffffff;
                text-decoration:none;
                border-radius: 5px;
                padding: 7px 5%;
                margin: 10px 0 $input-bottom-distance $label-width;
                outline:none;
            }

            button {
                @extend %button-basics;
                @extend %button-landflair;
                // padding: 7px 5%;
                // margin: 0 0 10px 0 !important;
            }

            :root form.kontakt fieldset li { margin: 0; }
        }

        .small { margin: 0 0 0 0; width:50%}
    }
}


@media screen and (min-width: 0px) and (max-width: 364px) {
    $verkuerzung:190px;
    .yform {
        // background: orange;
        form {
            #form_themenvorschlaege {
                #yform-formular-name,
                #yform-formular-email,
                #yform-formular-raiffeisenmarkt,
                #yform-formular-thema1,
                #yform-formular-thema2,
                #yform-formular-thema3 {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-raiffeisenmarkt {
                    input { margin: 0 0 15px 0; }
                }
            }

            .form-captcha {
                label {
                    width:100%;
                }

                .input-group-addon {
                    margin-right:11px !important;
                }

                #yform-formular-field-10 {
                    width: $input-captcha-width - 72px !important;
                }

                a.btn {
                    &::after {
                        content: '';
                    }
                }
            }
        }
    }
}



@media screen and (min-width: 365px) and (max-width: 459px) {
    $verkuerzung:140px;
    .yform {
        // background: yellow;
        form {
            #form_themenvorschlaege {
                #yform-formular-name,
                #yform-formular-email,
                #yform-formular-raiffeisenmarkt,
                #yform-formular-thema1,
                #yform-formular-thema2,
                #yform-formular-thema3 {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-raiffeisenmarkt {
                    input { margin: 0 0 15px 0; }
                }
            }

            .form-captcha {
                label {
                    width:100%;
                }

                .input-group-addon {
                    margin-right:11px !important;
                }

                #yform-formular-field-10 {
                    width: $input-captcha-width - 21px !important;
                }

                a.btn {
                    &::after {
                        content: '';
                    }
                }
            }
        }
    }
}



@media screen and (min-width: 460px) and (max-width: 519px) {
    $verkuerzung: 150px;
    .yform {
        // background: red;
        form {
            #form_themenvorschlaege {
                #yform-formular-name,
                #yform-formular-email,
                #yform-formular-raiffeisenmarkt,
                #yform-formular-thema1,
                #yform-formular-thema2,
                #yform-formular-thema3 {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }
            }

            .form-captcha {
                label {
                    width:100%;
                }

                .input-group-addon {
                    margin-right:11px !important;
                }

                #yform-formular-field-9 {
                    width: $input-captcha-width - 11px !important;
                }

                a.btn {
                    &::after {
                        // content: '';
                    }
                }
            }
        }
    }
}



@media screen and (min-width: 520px) and (max-width: 619px) {
    $verkuerzung: 83px;
    .yform {
        // background: lightgreen;
        form {
            #form_themenvorschlaege {
                #yform-formular-name,
                #yform-formular-email,
                #yform-formular-raiffeisenmarkt,
                #yform-formular-thema1,
                #yform-formular-thema2,
                #yform-formular-thema3 {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }
            }

            .form-captcha {
                label {
                    // width:100%;
                }

                .input-group-addon {
                    margin-right:11px !important;
                }

                #yform-formular-field-10 {
                    width: $input-captcha-width - 5px !important;
                }

                a.btn {
                    &::after {
                        content: '';
                    }
                }
            }
        }
    }
}



@media screen and (min-width: 620px) and (max-width: 767px) {
    $verkuerzung: 0px;
    .yform {
        // background: lightpink;
        form {
            #form_themenvorschlaege {
                #yform-formular-name,
                #yform-formular-email,
                #yform-formular-raiffeisenmarkt,
                #yform-formular-thema1,
                #yform-formular-thema2,
                #yform-formular-thema3 {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }
            }
        }
    }
}



@media screen and (min-width: 768px) and (max-width: 919px) {
    $verkuerzung:220px;
    .yform {
        // background: lightblue;
        form {
            #form_themenvorschlaege {
                #yform-formular-name,
                #yform-formular-email,
                #yform-formular-raiffeisenmarkt,
                #yform-formular-thema1,
                #yform-formular-thema2,
                #yform-formular-thema3 {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                .form-captcha {
                    label {
                        width:100%;
                    }

                    .input-group-addon {
                        margin-right:11px !important;
                    }

                    #yform-formular-field-10 {
                        width: $input-captcha-width !important;
                    }

                    a.btn {
                        &::after {
                            content: '';
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 920px) and (max-width: 1019px) {
    $verkuerzung:140px;
    .yform {
        // background: salmon;
        form {
            #form_themenvorschlaege {
                #yform-formular-name,
                #yform-formular-email,
                #yform-formular-raiffeisenmarkt,
                #yform-formular-thema1,
                #yform-formular-thema2,
                #yform-formular-thema3 {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }
            }

            .form-captcha {
                label {
                    width:100%;
                }

                .input-group-addon {
                    margin-right:11px !important;
                }

                #yform-formular-field-10 {
                    width: $input-captcha-width - 11px !important;
                }
            }
        }
    }
}

@media screen and (min-width: 1020px) and (max-width: 1200px) {
    $verkuerzung: 80px;
    .yform {
        // background: lightyellow;
        form {
            #form_themenvorschlaege {
                #yform-formular-name,
                #yform-formular-email,
                #yform-formular-raiffeisenmarkt,
                #yform-formular-thema1,
                #yform-formular-thema2,
                #yform-formular-thema3 {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }
            }

            .form-captcha {
                #yform-formular-field-10 {
                    width: $input-captcha-width - $verkuerzung;
                }
            }
        }
    }
}
