/* SEARCH_IT --------------------------------- */
$color-searchit: darkorange;

/* Eingabe */
.searchbutton {
    position:fixed;
    z-index:130;
    right:0;
    bottom:440px;
    height:32px;
    background:transparent;

    -webkit-box-shadow: 5px 5px 10px 0px rgba(0, 0, 0, 0.43);
    -moz-box-shadow:    5px 5px 10px 0px rgba(0, 0, 0, 0.43);
    box-shadow:         5px 5px 10px 0px rgba(0, 0, 0, 0.43);

    overflow:hidden;

    /* https://github.com/daneden/animate.css */
    @extend .animated;
    @extend .fadeIn;
    animation-duration: 2s;
    animation-delay: 1s;

    .search-container {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#5fb10a+0,004319+100 */
        background: #5fb10a; /* Old browsers */
        background: -moz-linear-gradient(top,  #5fb10a 0%, #004319 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #5fb10a 0%,#004319 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #5fb10a 0%,#004319 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5fb10a', endColorstr='#004319',GradientType=0 ); /* IE6-9 */

        overflow: hidden;
        float: right;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        box-shadow: 0 0 5px #6A5D4F;
        -moz-transition: all 0.35s;
        -webkit-transition: all 0.35s;

        &:hover,
        &.si-open {
            width: 242px;
            border-radius: 2px;

            .input-search {
                display: inline-block;
                width: 205px;
                padding: 3px 10px 3px 10px;
            }
        }
    }

    .input-search {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        float: left;
        width: 0px;
        height: 22px;
        margin: 5px 0 0 5px;
        margin-right: -50px;
        background: #fff;
        color: #445555;
        font-size: 12px;
        font-weight: normal;
        padding: 0px;
        border: 0;
        border-radius: 2px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2) inset;
        /*text-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);*/
        -moz-transition: all 0.35s;
        -webkit-transition: all 0.35s;

        &:focus {
            outline: none;
            box-shadow: 0 -1px 1px rgba(255, 255, 255, 0.25), 0 1px 5px rgba(0, 0, 0, 0.15);
        }
    }

    .panel-button {
        float: right;
        width: 32px;
        height: 32px;

        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#5fb10a+0,004319+100 */
        background: #5fb10a; /* Old browsers */
        background: -moz-linear-gradient(top,  #5fb10a 0%, #004319 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top,  #5fb10a 0%,#004319 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom,  #5fb10a 0%,#004319 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5fb10a', endColorstr='#004319',GradientType=0 ); /* IE6-9 */

        text-align: center;
        border: 0 !important;
        border-radius: 4px;
        box-shadow: 0 -1px 1px rgba(255, 255, 255, 0.25), 0 1px 1px rgba(0, 0, 0, 0.25);
        text-shadow: 0 -2px 1px rgba(190, 105, 1, 0.3);
        /*text-shadow: none !important;;*/

        i {
            font-size: 24px;
            margin-top: 3px;
            margin-left:-2px;
            color: #fff;
            line-height:1em;

            &:active {
                border: 0 !important;
                /*text-shadow: 0 0 0;*/
            }
        }
    }
}

/* SEARCH-SITE */
.search-site-container {
    width:100%;
    height:37px;

    input { @extend .input_shadow; }

    .search-site-input {
        width:70%;
        height:37px;
        color: #445555;
        font-weight: normal;
        border-radius: 5px;
        border: 1px solid $form-field-border-color;
        padding: 0px 0px 0px 10px;
        outline:none;
        margin: 0 5px 0 0;
    }

    %button-basics {
        position:relative;
        display: inline-block;
        font-size:1em;
        letter-spacing: 0.05em;
        color: #ffffff;
        text-decoration:none;
        border-radius: 5px;
        padding: 1px 7px 3px 7px;
        margin: 0;
        outline:none;
    }

    .search-site-button {
        @extend %button-basics;
        @extend %button-landflair;

        i {
            font-size: 24px;
            margin-top: 3px;
            margin-right:-1px;
            color: #fff;
            line-height:1em;

            &:active {
                border: 0 !important;
                /*text-shadow: 0 0 0;*/
            }
        }
    }
}


/* Ausgabe */
// section
.search_it-modul {

    // ul
    .search_it-results {
        list-style: none;
        padding: 0;
        margin: 0;

        // li
        .search_it-result {
            list-style: none;
            background:transparent;
            padding: 0;
            margin: 0 0 35px 0;

            .search_it-title,
            .search_it-title a {
                font-weight: bold;
                color: #1f7300;
                padding:0;
                margin:0 0 5px 0;
            }

            .search_it-url {
                color: rgba(0,0,0,0.4);
                padding:0;
                margin:0 0 5px 0;
            }

            .search_it-teaser {
                margin:0 0 5px 0;

                .search_it-keyword {
                    // color:red;
                }
            }

            .search_it-link {}

            /* ### BUTTON ############################################################ */
            %button-basics {
                position:relative;
                display: inline-block;
                font-size:1em;
                letter-spacing: 0.05em;
                color: #ffffff;
                text-decoration:none;
                border-radius: 5px;
                padding: 7px 20px 7px 20px;
                margin: 10px 0 0 0;
                outline:none;
            }

            a.slink {
                @extend %button-basics;
                @extend %button-landflair;
            }
        }
    }

    .search_it-missing_type,
    .search_it-zero {
        background: rgba(180,0,0,0.05);
        padding: 1rem;
        border: 1px solid rgba(255,0,0,0.7);
        margin: 1rem 0;
        color: rgba(255,0,0,0.7);
    }

    .search_it-request,
    .search_it-type {
        font-weight: bold;
    }

    .search_it-demotitle {
        font-weight: bold;
        color: red;
        margin-bottom: 15px;
    }
}

.search_it-span {
    font-weight:bold;
    // background:#ffffdd;
}
/* SEARCH_IT end ----------------------------- */



@media screen and (min-width: 0px) and (max-width: 895px) {
    .searchbutton { display:none; }
}