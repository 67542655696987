/*
	@Created on : 07.07.2014, 11:12:50
	@Author     : Vedat Özdemir

	@Description: Farbdefinitionen
				  Die Farben werden in den einzelnen Farbräumen definiert und dann den neutralen "color-"-Klassen zugewiesen.
				  Sortierung ist immer von Hell nach Dunkel.
				  Empfehlung: In Dezimalsystem-Abständen bleiben (10+x, 20+x usw...)
*/


/*
	Website Farbpalette
*/
$ws-01: #00aeef;
$ws-02: #009ee0;
$ws-03: #b1d996;
$ws-04: #63b32e;
$ws-05: #6ab023;
$ws-06: #445555;
$ws-07: #435058;
$ws-08: #435058;
$ws-09: #435058;
$ws-10: #435058;
$ws-11: #004d22;
$ws-12: #004494;
$ws-13: #f39400;
$ws-14: #D7009D;
$ws-15: #b6007c;
$ws-16: #b6072a;


// color-Klassen (.color-ta-x / .bgColor-ta-x) erzeugen. Bei Bedarf die Liste erweitern
$layoutColorCollection: ('', $ws-01, $ws-02, $ws-03, $ws-04, $ws-05, $ws-06, $ws-07, $ws-08, $ws-09, $ws-10,
$ws-11, $ws-12, $ws-13, $ws-14, $ws-15, $ws-16);
@include colorCollection($layoutColorCollection, 'ws');


/*
	S/W
*/
$color-01: #fff;
$color-02: #000;

// color-Klassen (.color-bw-x / .bgColor-bw-x) erzeugen.
$bw-collection: ('', $color-01, $color-02);
@include colorCollection($bw-collection, 'bw');


/*
	Grau
*/
$grey-01: #f5f5f5;
$grey-02: #ebebeb;
$grey-03: #e1e1e1;
$grey-04: #d7d7d7;
$grey-05: #cdcdcd;
$grey-06: #c3c3c3;
$grey-07: #b9b9b9;
$grey-08: #afafaf;
$grey-09: #a5a5a5;
$grey-10: #9b9b9b;
$grey-11: #919191;
$grey-12: #878787;
$grey-13: #7d7d7d;
$grey-14: #737373;
$grey-15: #696969;
$grey-16: #5f5f5f;
$grey-17: #555555;
$grey-18: #4b4b4b;
$grey-19: #414141;
$grey-20: #373737;
$grey-21: #2d2d2d;
$grey-22: #232323;
$grey-23: #191919;
$grey-24: #0f0f0f;
$grey-25: #050505;

// color-Klassen (.color-grey-x / .bgColor-grey-x) erzeugen. Bei Bedarf die Liste erweitern
$grey-collection: ('',  $grey-01, $grey-02, $grey-03, $grey-04, $grey-05, $grey-06, $grey-07, $grey-08, $grey-09, $grey-10,
						$grey-11, $grey-12, $grey-13, $grey-14, $grey-15, $grey-16, $grey-17, $grey-18, $grey-19, $grey-20,
						$grey-21, $grey-22, $grey-23, $grey-24, $grey-25);
@include colorCollection($grey-collection, 'grey');


/*
	Blau
*/
$blue-01: #F0F8ff;
$blue-02: #cce3f3;
$blue-03: #b0e0e6;
$blue-04: #afd3ec;
$blue-05: #b0c4de;
$blue-06: #87ceeb;
$blue-07: #64aadc;
$blue-08: #6495ed;
$blue-09: #4682b4;
$blue-10: #1e90ff;
$blue-11: #00bffF;
$blue-12: #4169e1;
$blue-13: #387caf;
$blue-14: #255e88;
$blue-15: #226EA6;
$blue-16: #1f6ca5;
$blue-17: #144D76;
$blue-18: #d9edf7;
$blue-19: #bce8f1;
$blue-20: #31708f;

// color-Klassen (.color-blue-x / .bgColor-blue-x) erzeugen. Bei Bedarf die Liste erweitern
$blue-collection: ('',  $blue-01, $blue-02, $blue-03, $blue-04, $blue-05, $blue-06, $blue-07, $blue-08, $blue-09, $blue-10,
						$blue-11, $blue-12, $blue-13, $blue-14, $blue-15, $blue-16, $blue-17, $blue-18, $blue-19, $blue-20);
@include colorCollection($blue-collection, 'blue');


/*
	Orange
*/
$orange-01: #ffbe00;
$orange-02: #ffb400;
$orange-03: #ffaa00;
$orange-04: #ffa000;
$orange-05: #ff9600;
$orange-06: #ff8c00;
$orange-07: #ff8200;
$orange-08: #ff7800;
$orange-09: #ff6e00;
$orange-10: #ff6400;
$orange-11: #ff5a00;
$orange-12: #ff5000;
$orange-13: #ff4600;
$orange-14: #ff3c00;
$orange-15: #f39400;

// color-Klassen (.color-orange-x / .bgColor-orange-x) erzeugen. Bei Bedarf die Liste erweitern
$orange-collection: ('',$orange-01, $orange-02, $orange-03, $orange-04, $orange-05, $orange-06, $orange-07, $orange-08, $orange-09, $orange-10,
						$orange-11, $orange-12, $orange-13, $orange-14, $orange-15);
@include colorCollection($orange-collection, 'orange');


/*
	Gelb
*/
$yellow-01: #fffcf0;
$yellow-02: #ffffb4;
$yellow-03: #ffff78;
$yellow-04: #ffff3c;
$yellow-05: #ffff00;
$yellow-06: $orange-01;
$yellow-07: #fcf8e3;
$yellow-08: #faebcc;
$yellow-09: #8a6d3b;

// color-Klassen (.color-yellow-x / .bgColor-yellow-x) erzeugen. Bei Bedarf die Liste erweitern
$yellow-collection: ('',  $yellow-01, $yellow-02, $yellow-03, $yellow-04, $yellow-05, $yellow-06);
@include colorCollection($yellow-collection, 'yellow');




/*
	Rot
*/
$red-01: #ffdfdd;
$red-02: #ffc0c0;
$red-03: #ff9a9a;
$red-04: #ff6f6f;
$red-05: #ff0000;
$red-06: #fc212f;
$red-07: #f80000;
$red-08: #e00000;
$red-09: #d30000;
$red-10: #cc0000;
$red-11: #c50000;
$red-12: #a80000;
$red-13: #a10000;
$red-14: #990000;
$red-15: #660000;
$red-16: #f2dede;
$red-17: #ebccd1;
$red-18: #a94442;
$red-19: #ffefef;

// color-Klassen (.color-red-x / .bgColor-red-x) erzeugen. Bei Bedarf die Liste erweitern
$red-collection: ('',$red-01, $red-02, $red-03, $red-04, $red-05, $red-06, $red-07, $red-08, $red-09, $red-10,
					 $red-11, $red-12, $red-13, $red-14, $red-15, $red-16, $red-17, $red-18, $red-19);
@include colorCollection($red-collection, 'red');


/*
	Rosa
*/
$rose-01: #f7bdde;

// color-Klassen (.color-rose-x / .bgColor-rose-x) erzeugen. Bei Bedarf die Liste erweitern
$rose-collection: ('',$rose-01);
@include colorCollection($rose-collection, 'rose');

/*
	Grün
*/
$green-01: #00c300;
$green-02: #00b900;
$green-03: #00af00;
$green-04: #00a500;
$green-05: #009b00;
$green-06: #009100;
$green-07: #008700;
$green-08: #007d00;
$green-09: #007300;
$green-10: #006900;
$green-11: #DEFFDF;
$green-12: #a0ffa0;
$green-13: #a4c157;
$green-14: #dff0d8;
$green-15: #d6e9c6;
$green-16: #3c763d;
$green-17: #445555;
$green-18: #2d3939;

// color-Klassen (.color-green-x / .bgColor-green-x) erzeugen. Bei Bedarf die Liste erweitern
$green-collection: ('', $green-01, $green-02, $green-03, $green-04, $green-05, $green-06, $green-07, $green-08, $green-09, $green-10,
$green-11, $green-12, $green-13, $green-14, $green-15, $green-16, $green-17, $green-18);
@include colorCollection($green-collection, 'green');


/*
	Allgemeine Definition der Site-Colors
*/
$default-background-color:  $grey-01;
$default-font-color:        #455;
$default-headline-color:    $grey-21;

$orange-gradient-top:       $orange-02;
$orange-gradient-bottom:    $orange-09;

$header-border: $blue-17;
$header-light:  $blue-07;

$link-color:        $blue-09;
$link-color-light:  $blue-05;
$link-color-hover:  $blue-14;

.link-color { color: $link-color; }
.link-color-light { color: $link-color-light; }
.link-color-hover { color: $link-color-hover; }