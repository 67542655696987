@charset "UTF-8";
/* CSS Document */

/* VARIABLEN ################################################### */
$control_pos_top: 40%;
$control_pos_prev: 10px;
$control_pos_next: 10px;

$control_img_path: '../images/fractionslider/control/';

.fraction-slider {
	position:relative;
	width:100%;
	height:100%;
	overflow:visible;

	.slide {
		position:absolute;
		display:none;
		width:100%;
		height:100%;
		z-index:50;
	}

	.active-slide {
		z-index:59;
	}

	.fs_obj {
		position:absolute;
		display:none;
		top:0px;
		left:100%;
		z-index:57;
	}

	.fs_fixed_obj {
		left:0;
		z-index:56;
	}

	.fs_obj *{
		position:relative;
		display:inline-block;
		top:0px;
		left:0px;
	}

	/** CONTROLS **/

	.prev,
	.next {
		position:absolute;
		display:none;
		width:45px;
		height:45px;
		z-index:59;
	}

	.prev {
		top:$control_pos_top;
		left:$control_pos_prev;
		background: #ffffff url('#{$control_img_path}fs.prevnext.png') 11px 10px no-repeat;
		opacity:0.5;
	}
	.prev:hover {
		background: #ffffff url('#{$control_img_path}fs.prevnext.png') 11px -35px no-repeat;
		opacity:0.8;
	}

	.next {
		top:$control_pos_top;
		right:$control_pos_next;
		background: #ffffff url('#{$control_img_path}fs.prevnext.png') -25px 10px no-repeat;
		opacity:0.5;
	}
	.next:hover {
		background: #ffffff url('#{$control_img_path}fs.prevnext.png') -25px -35px no-repeat;
		opacity:0.8;
	}

	&:hover .prev,
	&:hover .next {
		display:block;
	}
}

.fs_loader {
	background:url('#{$control_img_path}fs.spinner.gif') center center no-repeat;
	width:100%;
	height:400px;
}

/** PAGER **/

.fs-pager-wrapper {
	position:absolute;
	left:10px;
	bottom:10px;
	z-index:59;
}

.fs-pager-wrapper a,
.fs-custom-pager-wrapper a {
	background:url('#{$control_img_path}fs.pager.png') 0px -14px no-repeat;
	display:inline-block;
	width:14px;
	height:14px;
	margin:0 5px 0 0;
}
.fs-pager-wrapper .active,
.fs-custom-pager-wrapper .active {
	background:url('#{$control_img_path}fs.pager.png') 0px 0px no-repeat;
}