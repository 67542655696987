/**/
$fontPath: unquote('../fonts/AlegreyaSans/');


@font-face {
    font-family: 'alegreya_sansbold';
    src: url($fontPath + 'alegreyasans-bold-webfont.eot');
    src: url($fontPath + 'alegreyasans-bold-webfont.eot?#iefix') format('embedded-opentype'),
    url($fontPath + 'alegreyasans-bold-webfont.woff2') format('woff2'),
    url($fontPath + 'alegreyasans-bold-webfont.woff') format('woff'),
    url($fontPath + 'alegreyasans-bold-webfont.ttf') format('truetype'),
    url($fontPath + 'alegreyasans-bold-webfont.svg#alegreya_sansbold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'alegreya_sansitalic';
    src: url($fontPath + 'alegreyasans-italic-webfont.eot');
    src: url($fontPath + 'alegreyasans-italic-webfont.eot?#iefix') format('embedded-opentype'),
    url($fontPath + 'alegreyasans-italic-webfont.woff2') format('woff2'),
    url($fontPath + 'alegreyasans-italic-webfont.woff') format('woff'),
    url($fontPath + 'alegreyasans-italic-webfont.ttf') format('truetype'),
    url($fontPath + 'alegreyasans-italic-webfont.svg#alegreya_sansitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'alegreya_sansregular';
    src: url($fontPath + 'alegreyasans-regular-webfont.eot');
    src: url($fontPath + 'alegreyasans-regular-webfont.eot?#iefix') format('embedded-opentype'),
    url($fontPath + 'alegreyasans-regular-webfont.woff2') format('woff2'),
    url($fontPath + 'alegreyasans-regular-webfont.woff') format('woff'),
    url($fontPath + 'alegreyasans-regular-webfont.ttf') format('truetype'),
    url($fontPath + 'alegreyasans-regular-webfont.svg#alegreya_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;
}