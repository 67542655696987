/* IMAGE ZOOM EFFECT */
/* Hierfür habe ich einen img-wrapper DIV in das Modul geschrieben.
Den margin-bottom:10px; in der .fancybox img.content unter _fancybox.scss
habe ich dafür dort auf 0 eingestellt und hier in .img-wrapper ergänzt. */
.img-wrapper {
    display: inline-block;
    overflow: hidden;
    margin-bottom:0px;

    a { overflow:hidden; }

    img {
        -webkit-transition: all 3.0s ease;
        -moz-transition: all 3.0s ease;
        -ms-transition: all 3.0s ease;
        -o-transition: all 3.0s ease;
        transition: all 3.0s ease;

        vertical-align: middle;

        &:hover {
            -webkit-transform:scale(1.5); /* Safari and Chrome */
            -moz-transform:scale(1.5); /* Firefox */
            -ms-transform:scale(1.5); /* IE 9 */
            -o-transform:scale(1.5); /* Opera */
            transform:scale(1.5);
        }
    }
}
/* IMAGE ZOOM EFFECT end */

.img-no-wrapper { margin-bottom:11px !important; }