/*
	@Created on : 07.07.2014, 16:54:19
	@Author     : Vedat Özdemir

	@Description: Helper-Funktionen

	@see        : http://css-tricks.com/places-its-tempting-to-use-display-none-but-dont/
	@see        : http://calculator.tobani.com/convert/px-to-em
*/

.is-hidden-s {
	display: none;
}

.is-hidden {
	@extend .d-none;
	visibility: hidden !important;
}

.is-visible {
	@extend .d-block;
	visibility: visible !important;
}

.is-visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	@extend .p-abs;
	@include height(1px);
	@include width(1px);
	@include margin(-1px);
	@include padding(0);
	overflow: hidden;
}

.borderbox {
	@extend %border-box;
}

.clearfix {
	@extend %clearfix;
}

.clear {
	clear: both;
}

.no-clear {
	clear: none !important;
}

.is-blurred {
	@extend %is-blurred;
}

.no-border {
	border: 0;
}

.user-select-none {
	-webkit-user-select:none;
	-moz-user-select:none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}


/*
	Textformatierungen
*/
.no-deco,
.no-deco:hover,
.no-deco:focus {
	text-decoration: none;
}

.deco,
.deco:hover,
.deco:focus {
	text-decoration: underline;
}

.bold {
	font-weight: bold;
}

.no-bold,
.no-bold:hover,
.no-bold:focus {
	font-weight: normal;
}

.ta-left {
	text-align: left;
}

.ta-right {
	text-align: right;
}

.ta-center {
	text-align: center;
}

.ta-justify {
	text-align: justify;
}

.d-none {
	display: none;
}

.d-block {
	display: block;
}

.d-table {
	display: table;
}

.d-table-cell {
	display: table-cell;
}

.d-inline {
	display: inline;
}

.d-iblock {
	display: inline-block;
}

.nowrap {
	white-space: nowrap;
}


/*
	Positionen
*/
.p-abs {
	position: absolute;
}

.p-rel {
	position: relative;
}

.p-fix {
	position: fixed;
}

.f-left,
.left{
	float: left;
}

.f-right,
.right{
	float: right;
}

.f-none,
.none {
	float: none;
}

.vBaseline {
	vertical-align: baseline;
}

.vBottom {
	vertical-align: bottom;
}

.vMiddle {
	vertical-align: middle;
}

.vTop {
	vertical-align: top;
}


/*
	Für die Standard-Emoticons
*/
.icon[class^="emo-"],
.icon[class*=" emo-"] {
	line-height: inherit;
	vertical-align: middle;
	@include font-size(2.2rem);
}

.icon {
	&.is-changing {
		@extend .d-iblock;
		// @include iteration(infinite);
		// @include pulse(0.5s, 0s, ease-in, both, visible);
	}
}


/*
	Icon-Loader

.is-loading-icon {
	background: transparent url($path-images + '/loader.png') no-repeat;
	font-style: normal;
	text-shadow: none;
	@include width(32px);
	@include height(32px);

	@extend .d-iblock;
	// @include anim-rotate(3s, 0s, linear, infinite);

	&.is-hidden,
	.is-hidden & {
		@extend .is-hidden-s;
		@include animation-name(none);
	}

	.no-svg &,
	.no-cssanimations & {
		@include width(32px);
		@include height(32px);
		max-width: 100%;
		max-height: 100%;
		background: transparent url($path-images + '/loader.svg') no-repeat;
		@include background-size(contain);

		&:before {
			@extend .is-hidden-s;
		}
	}
}
*/

/*
	Abgerundete Bilder (in Kreis)
*/
.rounded-pic {
	display: inline-block;
	@include border-radius(50%);
	@extend %img-inner-shadow;
	max-width: 100%;
	max-height: 100%;
	vertical-align: bottom;

	&:after {
		border-radius: 50%;
	}

	img {
		max-width: 100%;
		max-height: 100%;
		overflow: hidden;
		@include border-radius(50%);
	}

	&.is-fixed {
		img {
			max-height: none;
		}
	}
}


/*
	Leicht abgerundete Bilder
*/
.squared-pic {
	@extend .rounded-pic;
	@include rem-border-radius($default-border-radius);
	overflow: hidden;

	&:after {
		@include rem-border-radius($default-border-radius);
	}

	img,
	> .icon {
		@include rem-border-radius($default-border-radius);
	}
}


/*
	jQUERY UI
*/
.ui-widget {
	@include font-size(14);
}

.ui-datepicker {
	.ui-datepicker-title select {
		@include padding(2);
		height: auto;
	}

	select {
		&.ui-datepicker-month {
			@extend .f-left;
		}

		&.ui-datepicker-year {
			@extend .f-right;
		}
	}
}

/*
	Box: Success, Warning, Error
*/
.box-info {
	@extend .d-iblock;
	@include normalize(border, 1);
	@include box-shadow($shadow-01);
	border-style: solid;
	border-color: $color-01;
	overflow: hidden;

	&.success,
	&.warning,
	&.info {
		@include font-size(16);

		p {
			@extend .f-left;
			@include normalize(line-height, 38);
			@include padding(10);
			margin: 0;
		}
	}

	.icon-alert,
	.icon-alert-2,
	.icon-check,
	.icon-info {
		@extend .f-left;
		@include margin(10, 5, 10, 15);

		&:before {
			@include height(40);
			@include width(40);
		}
	}

	.icon-check {
		@include normalize(margin-left, 15);

		&:before {
			@include height(32);
			@include width(32);
		}
	}

	ul {
		@extend .f-left;
		@extend .list-unstyled !optional;
		@include padding(10);
		overflow: hidden;

		li {
			@extend .icon-arrow-right-2 !optional;
			@include normalize(line-height, 25);

			&:before {
				@include normalize(padding-right, 6);
				@include height(18);
				@include width(8);
				background-position: left;
			}

			&:last-child {
				padding-bottom: 0;
			}
		}
	}
} // .box-info


/*
	Flash Message
*/
#flashMessage {
	@extend .p-fix;
	@include box-shadow($shadow-01);
	@include top(10);
	$ml: $box-info-min-width / 2 + 0.2rem;  //+2px oder 0.2rem wegen border-left-width
	@include normalize(margin-left, -$ml);

	left: 50%;
	overflow: hidden;
	z-index: 99999;

	.box-info{
		@extend .d-block;
		@include normalize(border, 2);
		@include box-shadow(none);
		width: $box-info-min-width;

		.flash-close {
			@extend .f-right;
			@include margin(10, 10, 0, 0);
			cursor: pointer;

			&:before {
				@extend .icon-close-2 !optional;
				@include height(20);
				@include width(20);
			}
		}
	}
} // #flashMessage