/* VARIABLEN ################################################### */
/* FORM */
$form-width:100%;
$form-field-border-color: #bbb;
$label-width: 105px;
$input-text-width: 450px;
$input-text-width_s: 93px;
$input-text-width_l: 350px;
$input-text-distance: 3px;
$input-border: 1px;
$input-bottom-distance: 10px;

$input-text-field_s: $input-text-width_s + $input-text-distance + $label-width + $input-border*4;
$input-text-field_l: $input-text-width_l + $input-text-distance + $label-width + $input-border*4;

$input-text-height: 31px;
$input-textarea-height: 150px;
$input-text-padding: 7px 6px 6px 8px;
/* VARIABLEN end ############################################### */

/* ### BUTTON ############################################################ */
%button-basics {
    position:relative;
    display: inline-block;
    font-size:1em;
    letter-spacing: 0.05em;
    color: #ffffff;
    text-decoration:none;
    border-radius: 5px;
    padding: 7px 5%;
    margin: 0 0 $input-bottom-distance $label-width+3;
    outline:none;
}

button#standardbutton {
    @extend %button-basics;
    @extend %button-landflair;
    padding: 7px 5%;
    margin: 0;

    &.bu-gewinner {
        margin: -10px 0 35px 0 !important;
    }
}

button#bildupload {
    @extend %button-basics;
    @extend %button-landflair;
    /*
    // https://github.com/daneden/animate.css
    @extend .animated;
    @extend .infinite;
    @extend .bounce;
    // animation-duration: 3s;
    // animation-delay: 2s;
    */
    padding: 7px 5%;
    margin: 20px 0 10px 0 !important;
}


/* KONTAKTFORMULAR */
.yform {
    margin: 10px 0 20px 0;

    form {
        padding: 0 0 15px 0;

        .alert {
            margin: 0 0 25px 0;

            li {
                background: rgba(0, 0, 0, 0) url($path-images + 'website/list_square_red.gif') no-repeat scroll 0 8px;
                color: #cd071e;
                list-style-type: none;
                margin: 0px 0px 5px 0px;
                padding-left: 18px;
            }
        }

        #form_fotowettbewerb,
        #form_bastelwettbewerb {
            width:$form-width;
            padding: 0;
            margin: 0;
            background:transparent;

            input { @extend .input_shadow; }

            div {
                &.form-group { margin: 0 0 $input-bottom-distance 0; }
            }

            em {
                font-weight: bold;
                font-style: normal;
                color: #cc0011;
                margin: 0 0 0 2px;
            }

            width: 100%;
            background:transparent;

            /* Für alle id, die mit yform-formular- beginnen (Schreibweise geht auch mit class) */
            [id^='yform-formular-'],
            [id*='yform-formular-'] {
                border-radius: 5px;
            }

            label {
                float:left;
                background:transparent;
                display: inline-block;
                width: $label-width;
                line-height: 1.8em;
                vertical-align: top;
            }

            #yform-formular-name,
            #yform-formular-email,
            #yform-formular-raiffeisenmarkt {
                input {
                    width: $input-text-width;
                    height: $input-text-height;
                    border: 1px solid $form-field-border-color;
                    padding: $input-text-padding;
                }
            }

            #yform-formular-raiffeisenmarkt {
                label { width: 100%; }
                input { margin: 0 0 15px $label-width; }
            }

            #yform-formular-teilnahmebedingungen {
                label {
                    width: 100%;
                    padding-left:27px;
                    margin-bottom: 25px;

                    input {
                        width: 20px;
                        height: 20px;
                        text-align:left;
                        border: 1px solid $form-field-border-color;
                        line-height: normal;
                        margin-left:-30px;
                    }
                }
            }

            #yform-formular-image {
                margin-bottom:0px;
                width:100%;

                .help-block { display:none; }

                .form-control {
                    background-color: unset;
                    background-image: unset;
                    border: unset;
                    border-radius: unset;
                    box-shadow: unset;
                    color: unset;
                    display: unset;
                    font-size: unset;
                    height: unset;
                    line-height: unset;
                    padding: unset;
                    transition: unset;
                    width: unset;
                }

                /*
                input[type="file"] {
                    display: none;
                }
                */

                /* ### NICEFILEINPUT JQuery ######################################### */
                // define a class on your HTML file input element
                // <input type="file" class="nice oder form-control" />
                // so you can now write your CSS code as follows:
                .form-control {
                    /*
                    -webkit-box-shadow: 0px 1px 0px #fff, 0px -1px 0px rgba(0,0,0,.1);
                    -moz-box-shadow: 0px 1px 0px #fff, 0px -1px 0px rgba(0,0,0,.1);
                    box-shadow: 0px 1px 0px #fff, 0px -1px 0px rgba(0,0,0,.1);
                    */
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    border-radius: 5px;
                }

                .form-control .NFI-button {
                    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#6ab023+0,004520+100 */
                    background: #6ab023; /* Old browsers */
                    background: -moz-linear-gradient(top,  #6ab023 0%, #004520 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(top,  #6ab023 0%,#004520 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom,  #6ab023 0%,#004520 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6ab023', endColorstr='#004520',GradientType=0 ); /* IE6-9 */

                    border: 0px solid #004520;

                    width: 120px;
                    height: 30px;
                    color: #fff;
                    letter-spacing:0.02em;
                    line-height: 28px;

                    -moz-border-radius-topleft: 5px;
                    -moz-border-radius-bottomleft: 5px;
                    -webkit-border-top-left-radius: 5px;
                    -webkit-border-bottom-left-radius: 5px;
                    border-top-left-radius: 5px;
                    border-bottom-left-radius: 5px;

                    text-shadow: 1px 1px 2px #004520;

                    -webkit-box-shadow: inset 0px 1px 0px rgba(255,255,255,.2);
                    -moz-box-shadow: inset 0px 1px 0px rgba(255,255,255,.2);
                    box-shadow: inset 0px 1px 0px rgba(255,255,255,.2);

                    &:hover {
                        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#5a961e+0,002b14+100 */
                        background: #5a961e; /* Old browsers */
                        background: -moz-linear-gradient(top,  #5a961e 0%, #002b14 100%); /* FF3.6-15 */
                        background: -webkit-linear-gradient(top,  #5a961e 0%,#002b14 100%); /* Chrome10-25,Safari5.1-6 */
                        background: linear-gradient(to bottom,  #5a961e 0%,#002b14 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5a961e', endColorstr='#002b14',GradientType=0 ); /* IE6-9 */

                        border: 0px solid #002b14;
                    }
                }

                .form-control .NFI-filename {
                    -moz-border-radius-topright: 5px;
                    -moz-border-radius-bottomright: 5px;
                    -webkit-border-top-right-radius: 5px;
                    -webkit-border-bottom-right-radius: 5px;
                    border-top-right-radius: 5px;
                    border-bottom-right-radius: 5px;

                    width: $input-text-width - 120px;
                    border: 1px solid $form-field-border-color;
                    border-left: none;
                    height: 31px;
                    line-height: 30px;
                    padding-left:10px !important;

                    background: #fff;
                    /*
                    -webkit-box-shadow: inset 0px 2px 0px rgba(0,0,0,.05);
                    -moz-box-shadow: inset 0px 2px 0px rgba(0,0,0,.05);
                    box-shadow: inset 0px 2px 0px rgba(0,0,0,.05);
                    */
                    color: #777;
                    text-shadow: 0px 1px 0px #fff;

                    @extend .input_shadow;
                }
                /* ### NICEFILEINPUT JQuery end ##################################### */

                span.input-group-btn { display:none; }
            }

            #yform-formular-image-checkbox {
                display:none;

                /*width:100%;
                display:inline-block;
                padding:0;
                margin:0;

                input {
                    float:left;
                    width: 23px;
                    height: 20px;
                    border: 1px solid $form-field-border-color;
                    line-height: normal;
                    margin: 4px 25px 0 -30px;
                }

                label {
                    float:left;
                    width:80%;
                    font-weight:normal;
                    padding-left:27px;
                    margin:0;
                }*/
            }

            .upload-hinweis {
                ul { margin: 13px 0 13px -30px; }
            }

            button {
                @extend %button-basics;
                @extend %button-landflair;
                /*
                // https://github.com/daneden/animate.css
                @extend .animated;
                @extend .infinite;
                @extend .bounce;
                // animation-duration: 3s;
                // animation-delay: 2s;
                */
                padding: 7px 5%;
                margin: 20px 0 10px 0 !important;
            }

            :root form.kontakt fieldset li { margin: 0; }
        }

        .small { margin: 0 0 0 0; width:50%}

        .form-captcha {
            span.input-group-addon {
                display:inline-block;
                margin-right: 15px;
                padding:2px;
                background:#888;
                float:left;
                border: none;
                border-radius: 0;
                width: auto;
            }

            // Captcha
            input {
                width:$input-captcha-width + 13px;
                // width: calc(100% - 150px);
                padding: $input-text-padding;
                margin:0;
                display: inline-block;
                border-color: $form-field-border-color;
            }

            .input-group-btn {
                display: inline-block;
                padding-left:15px;
            }

            a.btn {
                background: none;
                border: 0;
                font-size: 0;
                padding: 0;
                margin: 0;
                outline: none;

                &::after {
                    content: ' Neu laden';
                    font-size: 18px;
                    padding-left: 5px;
                    color: #455;
                }

                &::before {
                    padding: 2px;
                    -webkit-font-smoothing: antialiased;
                    font: normal normal normal 14px/1 FontAwesome;
                    content: "\f021";
                    font-size: 18px;
                    display: inline-block;
                    -webkit-transition: all 0.3s 0s ease-in-out;
                    -moz-transition: all 0.3s 0s ease-in-out;
                    -o-transition: all 0.3s 0s ease-in-out;
                    transition: all 0.3s 0s ease-in-out;
                    color: #7d7d7d;
                }

                &:hover {
                    &::before {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 0px) and (max-width: 364px) {
    $verkuerzung:190px;
    .yform {
        form {
            #form_fotowettbewerb,
            #form_bastelwettbewerb {
                #yform-formular-name,
                #yform-formular-email,
                #yform-formular-raiffeisenmarkt {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-raiffeisenmarkt {
                    input { margin: 0 0 15px 0; }
                }

                #yform-formular-image {
                    .form-control .NFI-filename {
                        width: $input-text-width - 120px - $verkuerzung;
                    }

                    .input-group { width: 100% !important; }
                }
            }

            .form-captcha {
                label { width:100% !important; }

                .input-group {
                    input { width:88px; }
                }

                a.btn::after { display:none; }
            }
        }
    }
}



@media screen and (min-width: 365px) and (max-width: 459px) {
    $verkuerzung:140px;
    .yform {
        form {
            #form_fotowettbewerb,
            #form_bastelwettbewerb {
                #yform-formular-name,
                #yform-formular-email,
                #yform-formular-raiffeisenmarkt {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-raiffeisenmarkt {
                    input { margin: 0 0 15px 0; }
                }

                #yform-formular-image {
                    .form-control .NFI-filename {
                        width: $input-text-width - 260px;
                    }

                    .input-group { width: 100% !important; }
                }
            }

            .form-captcha {
                label { width:100% !important; }

                .input-group {
                    input { width:138px; }
                }

                a.btn::after { display:none; }
            }
        }
    }
}



@media screen and (min-width: 460px) and (max-width: 519px) {
    $verkuerzung: 150px;
    .yform {
        form {
            #form_fotowettbewerb,
            #form_bastelwettbewerb {
                #yform-formular-name,
                #yform-formular-email,
                #yform-formular-raiffeisenmarkt {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-image {
                    .form-control .NFI-filename {
                        width: $input-text-width - 165px;
                    }

                    .input-group { width: 100% !important; }
                }
            }

            .form-captcha {
                label { width:100% !important; }

                .input-group {
                    input { width:230px; }
                }

                a.btn::after { display:none; }
            }
        }
    }
}



@media screen and (min-width: 520px) and (max-width: 619px) {
    $verkuerzung: 90px;
    .yform {
        form {
            #form_fotowettbewerb,
            #form_bastelwettbewerb {
                #yform-formular-name,
                #yform-formular-email,
                #yform-formular-raiffeisenmarkt {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-image {
                    .form-control .NFI-filename {
                        width: $input-text-width - 105px;
                    }
                }

                .input-group { width: 100% !important; }
            }

            .form-captcha {
                .input-group {
                    input { width:187px; }
                }

                a.btn::after { display:none; }
            }
        }
    }
}



@media screen and (min-width: 620px) and (max-width: 767px) {
    $verkuerzung: 0px;
    .yform {
        form {
            #form_fotowettbewerb,
            #form_bastelwettbewerb {
                #yform-formular-name,
                #yform-formular-email,
                #yform-formular-raiffeisenmarkt {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }
            }

            .form-captcha {
                .input-group {
                    input { width:195px; }
                }
            }
        }
    }
}



@media screen and (min-width: 768px) and (max-width: 919px) {
    $verkuerzung:220px;
    .yform {
        form {
            #form_fotowettbewerb,
            #form_bastelwettbewerb {
                #yform-formular-name,
                #yform-formular-email,
                #yform-formular-raiffeisenmarkt {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-image {
                    .form-control .NFI-filename {
                        width: $input-text-width - 234px;
                    }

                    .input-group { width: 100% !important; }
                }
            }

            .form-captcha {
                label { width:100% !important; }

                .input-group {
                    input { width:165px; }
                }

                a.btn::after { display:none; }
            }
        }
    }
}

@media screen and (min-width: 920px) and (max-width: 1019px) {
    $verkuerzung:150px;
    .yform {
        form {
            #form_fotowettbewerb,
            #form_bastelwettbewerb {
                #yform-formular-name,
                #yform-formular-email,
                #yform-formular-raiffeisenmarkt {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-image {
                    .form-control .NFI-filename {
                        width: $input-text-width - 164px;
                    }

                    .input-group { width: 100% !important; }
                }
            }

            .form-captcha {
                label { width:100% !important; }

                .input-group {
                    input { width:150px; }
                }
            }
        }
    }
}

@media screen and (min-width: 1020px) and (max-width: 1200px) {
    $verkuerzung: 90px;
    .yform {
        form {
            #form_fotowettbewerb,
            #form_bastelwettbewerb {
                #yform-formular-name,
                #yform-formular-email,
                #yform-formular-raiffeisenmarkt {
                    input {
                        width: $input-text-width - $verkuerzung;
                    }
                }

                #yform-formular-image {
                    .form-control .NFI-filename {
                        width: $input-text-width - 120px - $verkuerzung;
                    }

                    .input-group { width: 100% !important; }
                }
            }

            .form-captcha {
                a.btn::after { display:none; }
            }
        }
    }
}