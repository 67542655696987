/* ### INTERVIEW GALERIE ######################################################### */
.e-interview {
    display:inline-block;
    padding: 0 !important;
    margin: 0 0 25px 0 !important;

    .e-interview-bild {
        img {
            -webkit-border-radius: 15px;
            -moz-border-radius: 15px;
            border-radius: 15px;
            overflow:hidden;

            -webkit-box-shadow: 4px 4px 10px 0px rgba(50, 50, 50, 0.35);
            -moz-box-shadow:    4px 4px 10px 0px rgba(50, 50, 50, 0.35);
            box-shadow:         4px 4px 10px 0px rgba(50, 50, 50, 0.35);
        }
    }

    div {
        &:first-child { padding-left:0; }
    }

    .e-interview-text {
        h2 {
            a { font-weight:bold; }
        }

        h3 {
            font-family: alegreya_sansregular, Verdana, sans-serif;
            font-size: 1em;	/* 16px (1em) */
            font-weight:bold;
            margin: 0 0 10px 0;
        }
    }
}

@media screen and (min-width: 0px) and (max-width: 756px) {
    .e-interview {
        div {
            padding:0;
        }
    }
}
/* ### INTERVIEW GALERIE end ##################################################### */


/* ### PFERDEFREUNDE LECKERLIS ################################################### */
.e-interview-leckerlis {
    mark {
        display:inline-block;
        background:#facad6;
        width:100%;
        text-align:center;
        padding:20px;
        margin: 15px 0 15px 0;
    }

    purple {
        display:inline-block;
        font-size:1.3em;
        font-style:italic;
        font-weight:bold;
        line-height:1.5em;
        color: #b53258;
        margin: 10px 0 10px 0;
    }
}
/* ### PFERDEFREUNDE LECKERLIS end ############################################### */


/* ### JUNGIMKER ################################################################# */
.e-interview-jungimker-bild1 {
    display:inline-block;
    width:100%;
    text-align:center;
    margin: 0 0 35px 0 !important;

    img { width:75% !important; }
}

.e-interview-jungimker-bild2 {
    margin: -35px 0 0 0 !important;
}

@media screen and (min-width: 0px) and (max-width: 756px) {
    .e-interview-jungimker-bild1 { margin: -50px 0 35px 0 !important; }
}
/* ### JUNGIMKER end ############################################################# */


/* ### KATZENBABYS ############################################################### */
.e-interview-katzenbabys-bild1 {
    display:inline-block;
    width:100%;
    text-align:center;
    margin: 0 0 35px 0 !important;

    img { width:75% !important; }
}

.e-interview-katzenbabys-bild2 { margin-top:10px; }

@media screen and (min-width: 0px) and (max-width: 756px) {
    .e-interview-katzenbabys-bild1 { margin: -50px 0 0 0 !important; }
}
/* ### KATZENBABYS end ########################################################### */


/* ### GARTENERDE ################################################################ */
.e-interview-gartenerde-bild1 {
    display:inline-block;
    width:100%;
    text-align:center;
    margin: 0 0 35px 0 !important;

    img { width:75% !important; }
}

.e-interview-gartenerde-bild2 { margin-top:10px; }

@media screen and (min-width: 0px) and (max-width: 756px) {
    .e-interview-gartenerde-bild1 { margin: -50px 0 0 0 !important; }
}
/* ### GARTENERDE end ############################################################ */


/* ### GESUNDE FÜTTERUNG ######################################################### */
.e-interview-gesundefuetterung {
    .buz {
        width:100% !important;
        margin: 0 0 33px 0 !important;
    }
}

@media screen and (min-width: 0px) and (max-width: 756px) {}
/* ### GESUNDE FÜTTERUNG end ##################################################### */



/* ### PFERDERÜCKEN ############################################################## */
.e-interview-pferderuecken,
.e-interview-erstehilfehund {
    .sitzuebungen {
        display:inline-block;
        background:#ddd;
        width:100%;
        padding:10px 35px 20px 35px;
        margin: 20px 0 0 0;

        h3 {
            font-family: alegreya_sansregular, Verdana, sans-serif;
            font-weight:bold;
        }
    }

    .gruenerkasten {
        display:inline-block;
        background:#004526;
        width:100%;
        padding:10px 35px 20px 35px;
        margin: 20px 0 0 0;
        color:#fff;

        h3 {
            font-family: alegreya_sansregular, Verdana, sans-serif;
            font-weight:bold;
        }

        .weisserkasten {
            display:inline-block;
            background:#fff;
            width:100%;
            padding:10px 35px 20px 35px;
            margin: 20px 0 20px 0;
            color:#000;
        }
    }

    .drvenema {
        display:inline-block;
        width:100%;
        margin:-20px -20px 25px -20px;

        p {
            padding-top:20px;
        }
    }
}

@media screen and (min-width: 0px) and (max-width: 756px) {}
/* ### PFERDERÜCKEN end ########################################################## */