/*
	@Created on : 07.07.2014, 11:18:46
	@Author     : voezdemir

	@Description: List von Platzhalter
*/

%clearfix {
	*zoom: 1;

	&:before,
	&:after {
		@extend .d-table !optional;
		@include normalize(line-height, 0);
		content: "";
	}

	&:after {
		@extend .clear !optional;
	}
}

%is-blurred {
	filter: grayscale(0.5) blur(6px);
	-webkit-filter: blur(6px);
}

%border-box {
	vertical-align: top;
	word-wrap: break-word;
	box-sizing: border-box;
}

%inputs {
	background-color: $color-01;
	border: 1px solid $grey-06;
	overflow: auto;
	outline: none;
	margin: 0;
	resize: none;

	@extend %border-box !optional;
	@include box-shadow(1px 1px 2px rgba(0, 0, 0, 0.16) inset);
	@include font-size(14);
	@include height(35);
	@include padding(4,8);
	@include rem-border-radius(5);
	@include transition(border-color 0.2s ease-in);

	.boxsizing & {
		width: 100%;
	}

	&[disabled="disabled"],
	&[disabled="disabled"]:focus,
	&.is-disabled,
	&.is-disabled:focus {
		background: $grey-02;
		border-color: $grey-06;
		color: $grey-14;
		cursor: default;
		@include box-shadow(none);
	}
}

/*
	Image inner shadow
	Erzeugt einen inneren Schatten auf dem Bild. Gleiche Größe wie das Eltern-Element. Ohne abgerundeten Ecken.
*/
%img-inner-shadow {
	@extend .p-rel !optional;

	&:after {
		content: "";
		height: 100%;
		overflow: hidden;
		width: 100%;

		@extend .p-abs !optional;

		@include top(0);
		@include left(0);
		@include box-shadow(0 0 1px 0 rgba(0, 0, 0, 0.2) inset,
			0 1px 1px 0 rgba(0, 0, 0, 0.2) inset,
			0 1px 1px 1px rgba(0, 0, 0, 0.1) inset);
	}

	img {
		@extend .d-block !optional;
		max-width: 100%;
		max-height: 100%;
	}
}