.fancybox-gallery {
    clear:both;
    display:inline-block;
    width:100%;

    .row-gal {
        clear:both;

        .col-gal {
            float:left;
            width:24%;
            margin-right:1.3333333%;

            &:nth-child(4n+0) { margin-right:0;}
            &:nth-child(5n+0) { clear:both; }
        }
    }
}

/* IMAGE ZOOM EFFECT */
/* Hierfür habe ich einen img-wrapper DIV in das Modul geschrieben.
Den margin-bottom:10px; in der .fancybox img.content unter _fancybox.scss
habe ich dafür dort auf 0 eingestellt und hier in .img-wrapper ergänzt. */
.gallery-img-wrapper {
    display: inline-block;
    overflow: hidden;
    margin-bottom:10px;

    a { overflow:hidden; }

    img {
        -webkit-transition: all .4s ease;
        -moz-transition: all .4s ease;
        -ms-transition: all .4s ease;
        -o-transition: all .4s ease;
        transition: all .4s ease;

        vertical-align: middle;

        &:hover {
            -webkit-transform:scale(1.5); /* Safari and Chrome */
            -moz-transform:scale(1.5); /* Firefox */
            -ms-transform:scale(1.5); /* IE 9 */
            -o-transform:scale(1.5); /* Opera */
            transform:scale(1.5);
        }
    }
}
/* IMAGE ZOOM EFFECT end */

@media screen and (min-width: 0) and (max-width: 969px) {
    .fancybox-gallery {
        .row-gal {
            .col-gal {
                width:49%;
                margin-right:2%;

                &:nth-child(2n) { margin-right:0; }
                &:nth-child(2n+1) { clear:both; }
            }
        }
    }
}