#pagination_basteltipps_wrapper {
    widht:100%;
    text-align:center;

    .pagination_basteltipps {
        display: inline-block;
        padding-left: 0 !important;
        margin: 20px 0 0 0;

        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;

        li {
            display: inline;
            padding-left:0 !important;

            a, span {
                float: left;
                background-color: #fff;
                font-size: 18px;
                color: #1f7300;
                text-decoration: none;
                line-height: 1.33333;
                border: 1px solid #d2e3cc;
                padding: 10px 20px 10px 20px;
                margin-left: -1px;
            }

            &:first-child  a,
            &:first-child  span {
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                border-radius: 4px;
            }

            a:hover,
            a:focus,
            span:hover,
            span:focus {
                background-color: #f4f8f2;
                border-color: #d2e3cc;
                color: #1f7300;
            }

            &.pLink_selected span {
                background:#1f7300;
                color:#fff;
            }
        }
    }
}

@media screen and (min-width: 0px) and (max-width: 470px) {
    #pagination_basteltipps_wrapper {
        .pagination_basteltipps {
            margin: 0;
            li {
                a, span {
                    padding: 7px 15px 7px 15px;
                }
            }
        }
    }
}